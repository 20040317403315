import React from "react";
import { User, UserActivity } from "types/user-schema";
import Icon from "@mui/material/Icon";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDNoData from "components/MDNoData";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { formatDateTime, isNotNil } from "helpers/utils";
import { useTranslation } from "react-i18next";

interface Cells {
    columns: { Header: string; accessor: string; disable?: boolean }[];
    rows: {
        id: string;
        email: string;
        ipAddress: string;
        lastLogin: string;
        name: string;
        role: string;
    }[];
}

interface Props {
    list: UserActivity[];
}

const Table: React.FC<Props> = ({ list = [] }) => {
    const { t: translate } = useTranslation(["common"]);

    if (list?.length === 0) {
        return (
            <MDNoData
                content={translate("general.notification.noData", {
                    type: `${translate("general.type.account")}`,
                })}
            />
        );
    }

    const cells: Cells = {
        columns: translate("account.activityTable", { returnObjects: true }),
        rows: [] as any[],
    };

    cells.rows = list?.map((user) => {
        return {
            id: user._id,
            name: user.name,
            email: user.email,
            ipAddress: user.ipAddress,
            role: translate(`account.role.${user.role}`),
            lastLogin: formatDateTime(user.lastLogin),
            lastActivity: formatDateTime(user.lastActivity),
        };
    });

    return <DataTable table={cells} />;
};

export const AccountTable: React.FC<Props> = (props) => {
    return (
        <MDBox>
            <Table {...props} />
        </MDBox>
    );
};

AccountTable.whyDidYouRender = true;
export default AccountTable;
