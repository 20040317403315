// Custom styles for MDSkeleton
import MDBox from "components/MDBox";
import Skeleton from "@mui/material/Skeleton";
import FormLayout from "examples/LayoutContainers/FormLayout";

// Declare props types for MDSkeleton
interface Props {
    animation?: false | "wave" | "pulse";
    [key: string]: any;
}

const MDSkeleton: React.FC<Props> = ({ animation }) => (
    <FormLayout header="">
        <Skeleton animation={animation} height={50} width="35%" sx={{ mb: 1 }} />
        <MDBox display="flex" justifyContent="space-between">
            <Skeleton animation={animation} width="45%" />
            <Skeleton animation={animation} width="45%" />
        </MDBox>
        <Skeleton animation={animation} />
        <Skeleton animation={animation} />
        <Skeleton animation={animation} />
        <Skeleton animation={animation} />
        <Skeleton animation={animation} />
    </FormLayout>
);

// Declaring default props for MDSkeleton
MDSkeleton.defaultProps = {
    animation: "wave",
};

export default MDSkeleton;
