// Enum to contact these roles, so we don't have to use strings, 
export type report = 'VS-dailyReport' | 'VS-threeDaysReport' | 'VS-severalReport' | 'VS-fourteenDaysReport' | 'Care-fourteenDaysReport';
// Define enum for roles
export enum Reports {
	vsDaily = 'VS-dailyReport',
	vsThreeDays = 'VS-threeDaysReport',
	vsSeveral = 'VS-severalReport',
	vsFourteenDays = 'VS-fourteenDaysReport',
	careFourteenDays = 'Care-fourteenDaysReport',
	
};

export enum Summary {
	Today = 'today',
	Yesterday = 'yesterday',
	LastSeveralDays = 'lastSevenDays',
	LastFourteenDays = 'lastFourteenDays',
	LastThirtyDays = 'lastThirtyDays',
}

export const REPORT_ARRAY = Object.values(Reports);