// Setting layout components
import MDModal from "components/MDModal";
import { UnitForm } from "layouts/settings/components/Forms/UnitForm";

// Type
import { FormOperation } from "types/enums";
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    businessId: string;
    callback: (updated: boolean) => void;
}

export const NewUnit: React.FC<Props> = ({ setToggleModal, businessId, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <UnitForm operation={FormOperation.CREATE} businessId={businessId} callback={callback} />
        </MDModal>
    );
};

export default NewUnit;
