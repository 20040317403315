import { CMSResponse, CMSinstance as http } from "http-common";
import { HealthPreScription } from "types/healthprescription-schema";

class HealthPre {
    async getHealthPrescription(id: string): Promise<CMSResponse<HealthPreScription>> {
      try {
        const response = await http.get<CMSResponse<HealthPreScription>>(`health-prescriptions/${id}?populate=health_prescription_type,Media`);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }

export default new HealthPre();
