export const validateEmail = (email: string) => {
    const re = /([a-zA-Z0-9._-]+@([a-zA-Z0-9_-]+\.)+[a-zA-Z0-9_-]+)/;
    return re.test(String(email).toLowerCase());
};

export const validatePassword = (password: string) => {
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return re.test(String(password).toLowerCase());
};

export const containsEightChars = (password: string) => {
    const re = /.{8,}$/;
    return re.test(String(password).toLowerCase());
};

export const containsNumAndLetter = (password: string) => {
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/;
    return re.test(String(password).toLowerCase());
};

export const validatePhones = (phone: string) => {
    const phoneRegex = /^\d{3}?[\s.-]\d{3}[\s.-]\d{4}$/;
    const phoneRegex2 = /^\d{4}?[\s.-]\d{3}[\s.-]\d{3}$/;

    if (!phoneRegex.test(phone) && !phoneRegex2.test(phone)) {
        return false;
    }
    return true;
};
