import { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthService from "services/auth.service";
import { User } from "types/user-schema";

export const AuthContext = createContext(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            let user = await AuthService.getUser();
            setCurrentUser(user);
        };
        if (!AuthService.isAuthenticated()) {
            // Regex rule to determine this /change-password/91651b3ec36b13c159b14e0a7cd03f41ef7b4df7 or /activate/049964947b6e614b41da251f76ef68f04e53fc83
            if (location.pathname.match(/\/(change-password|activate)\/[a-z0-9]{40}/) !== null) {
                let matches = location.pathname.match(/\/(change-password|activate)\/([a-z0-9]{40})/);
                let token = matches[2];
                // save toke in reset-auth
                AuthService.setResetToken(token);
                if (matches[1] === "activate") {
                    navigate("/activate");
                } else {
                    navigate("/change-password");
                }
            } else if (location.pathname !== "/sign-in" && location.pathname !== "/forgot-password" &&location.pathname !== "/education") {
                navigate("/sign-in");
            }
            // It will open dashboard
        } else {
            fetchData();
        }
    }, []);

    return <AuthContext.Provider value={{ currentUser, setCurrentUser }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
