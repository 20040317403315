import { Response, default as http } from "http-common";
import { User, UserActivity } from "types/user-schema";

class UserService {
    async getAllUsers(): Promise<Response<User[]>> {
        const response = await http.get<Response<User[]>>("/api/v1/users");
        return response.data;
    }

    async getAllUsersByBusiness(businessId: string): Promise<Response<User[]>> {
        const response = await http.get<Response<User[]>>(`/api/v1/businesses/${businessId}/users`);
        return response.data;
    }

    async getSingleUser(userId: string): Promise<Response<User>> {
        const response = await http.get<Response<User>>(`/api/v1/users/${userId}`);
        return response.data;
    }

    async updateSingleUserBusiness(userId: string, userInfo: User): Promise<Response<User>> {
        const response = await http.put<Response<User>>(`/api/v1/users/${userId}`, userInfo);
        return response.data;
    }

    async createUser(userInfo: User, lang: string): Promise<Response<User>> {
        const response = await http.post<Response<User>>(`/api/v1/users?lang=${lang}`, userInfo);
        return response.data;
    }

    async deleteUser(userId: string): Promise<Response<User>> {
        const response = await http.delete<Response<User>>(`/api/v1/users/${userId}`);
        return response.data;
    }

    async reactivate(userId: string, lang: string): Promise<Response<User>> {
        const response = await http.get<Response<User>>(`/api/v1/users/${userId}/reactivate?lang=${lang}`);
        return response.data;
    }
    
    async getUserActivity(businessId: string): Promise<Response<UserActivity[]>> {
        const response = await http.get<Response<UserActivity[]>>(`/api/v1/users/${businessId}/activity`);
        return response.data;
    }
}

export default new UserService();
