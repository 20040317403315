import { Response, default as http } from "http-common";
import { TodayAlertsHistory } from "types/alert-schema";

class DeviceService {
    async getDeviceData(): Promise<Response<any>>{
        const response = await http.get<Response<any>>(`/api/v1/devices/`);
        return response.data;
    } 
    async getDisconnectDeviceData(): Promise<Response<any>>{
        const response = await http.get<Response<any>>(`/api/v1/devices/disconnectRecord`);
        return response.data;
    } 
}

export default new DeviceService();
