// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_sliderWrapper__7xrM3 .swiper-pagination-bullet-active {\n  background-color: #7D0FB1;\n}", "",{"version":3,"sources":["webpack://./src/layouts/overview/resident-page/style.module.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ","sourcesContent":[".sliderWrapper {  \n  :global(.swiper-pagination-bullet-active) {\n    background-color: #7D0FB1;\n   \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderWrapper": "style_sliderWrapper__7xrM3"
};
export default ___CSS_LOADER_EXPORT___;
