// Setting layout components
import MDModal from "components/MDModal";
import { ResidentForm } from "layouts/settings/components/Forms/ResidentForm";

// Type
import { FormOperation } from "types/enums";
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    businessId: string;
    callback: (updated: boolean) => void;
}

export const NewResident: React.FC<Props> = ({ setToggleModal, businessId, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <ResidentForm operation={FormOperation.CREATE} businessId={businessId} callback={callback} />
        </MDModal>
    );
};

export default NewResident;
