const form = {
    formId: "account-form",
    formField: {
        account: {
            name: {
                name: "name",
                type: "text",
                errorMsg: "Account name is required",
            },
            avatarUrl: {
                name: "name",
                type: "file",
            },
            role: {
                name: "role",
                type: "text",
                errorMsg: "Role is required",
            },
            center: {
                name: {
                    name: "center.name",
                    type: "text",
                    errorMsg: "Center is required",
                },
            },
            email: {
                name: "email",
                type: "text",
                errorMsg: "Email is required",
                invalidMsg: "Invalid email format",
            },
            phone: {
                number: {
                    name: "phone.number",
                    type: "tel",
                    errorMsg: "Mobile is required",
                    invalidMsg: "Must be 10 digits",
                },
                countryCode: {
                    name: "phone.countryCode",
                    type: "text",
                },
            },
        },
    },
};

export default form;
