// @mui material components
import Grid from "@mui/material/Grid";
import { Business, Center } from "types/setting-schema";

// formik components
import { useFormikContext } from "formik";
import { FormField, FormSelect } from "components/MDFormField";
import { phoneFormat, getFormKeys } from "helpers/formUtil";

interface Props {
    phoneValue: Business["phone"] | Center["phone"];
    errors?: any;
    touched?: any;
    field: any;
    handleChange: (e: any) => void;
    label: string;
    nameIndex?: any;
    isEditDisabled: boolean;
}

export const PhoneField: React.FC<Props> = ({
    phoneValue,
    errors,
    touched,
    field,
    handleChange,
    label,
    isEditDisabled,
}) => {
    const { setFieldValue } = useFormikContext();

    return !isEditDisabled ? (
        <>
            <Grid item xs={2.5} sm={1.5}>
                <FormSelect
                    {...getFormKeys(field.countryCode, " ")}
                    value={phoneValue.countryCode}
                    options={[{ code: "+1" }, { code: "+886" }]}
                    onChange={(e) => {
                        handleChange(e);
                        setFieldValue("phone.number", "");
                    }}
                    type="image"
                />
            </Grid>
            <Grid item xs={9.5} sm={4.5}>
                <FormField
                    {...getFormKeys(field.number, label)}
                    value={phoneValue.number}
                    onChange={(e: any) => {
                        setFieldValue("phone.number", phoneFormat(e.target.value, phoneValue.countryCode));
                    }}
                    error={errors.phone && touched.phone}
                    success={phoneValue.number.length > 0 && !errors.phone?.number}
                />
            </Grid>
        </>
    ) : (
        <Grid item xs={12} sm={6}>
            <FormField
                {...getFormKeys(field.number, label)}
                value={`${phoneValue.countryCode} ${phoneValue.number}`}
                isDisplayMode={true}
            />
        </Grid>
    );
};

// For phone in nested array (Resident from - emergency)
// name should be "emergency[index].phone", but this is not allows in the form schema. So name property is necessary
export const PhoneArrayField: React.FC<Props> = ({
    phoneValue,
    field,
    handleChange,
    label,
    nameIndex,
    isEditDisabled,
}) => {
    const { setFieldValue } = useFormikContext();

    return !isEditDisabled ? (
        <>
            <Grid item xs={2.5} sm={1.5}>
                <FormSelect
                    value={phoneValue.countryCode}
                    options={[{ code: "+1" }, { code: "+886" }]}
                    onChange={(e) => {
                        handleChange(e);
                        setFieldValue(`${nameIndex}.number`, "");
                    }}
                    name={`${nameIndex}.countryCode`}
                    type="image"
                    label=" "
                />
            </Grid>
            <Grid item xs={9.5} sm={4.5}>
                <FormField
                    {...getFormKeys(field.number, label)}
                    name={`${nameIndex}.number`}
                    value={phoneValue.number}
                    onChange={(e: any) => {
                        setFieldValue(`${nameIndex}.number`, phoneFormat(e.target.value, phoneValue.countryCode));
                    }}
                    success={phoneValue.number.length > 0}
                />
            </Grid>
        </>
    ) : (
        <Grid item xs={12} sm={6}>
            <FormField
                {...getFormKeys(field.number, label)}
                name={nameIndex.number}
                value={`${phoneValue.countryCode} ${phoneValue.number}`}
                isDisplayMode={true}
            />
        </Grid>
    );
};
