import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { isNotNil } from "helpers/utils";

interface SubmitProps {
    content: string;
    disable: boolean;
    handleClick?: () => void;
}

export const SubmitButton: React.FC<SubmitProps> = ({ content, disable, handleClick }) => {
    return (
        <>
            <MDButton
                type={isNotNil(handleClick) ? "button" : "submit"}
                color="primary"
                variant="gradient"
                circular={false}
                fullWidth
                disabled={disable}
                onClick={handleClick}>
                <MDTypography variant="h2" color="white" fontWeight="regular">
                    {content}
                </MDTypography>
            </MDButton>
        </>
    );
};

export default SubmitButton;
