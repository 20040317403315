// @mui material components
import { styled, Theme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

export default styled(CircularProgress)(({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
    const { color } = ownerState;

    return {
        color: color.main,
    };
});
