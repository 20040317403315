import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/userContext";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDCircularProgress from "components/MDCircularProgress";
import MDNotification from "components/MDNotification";
import AddButton from "components/MDButton/addButton";

// Components
import BasicLayout from "examples/LayoutContainers/BasicLayout";
import BusinessCard from "layouts/settings/components/BusinessCard";
import NewBusiness from "layouts/settings/business-page/newBusiness";

// Business service
import businessService from "services/business.service";

// Types
import { Business } from "types/setting-schema";
import { FormOperation, FormType } from "types/enums";
import { isRelAdmin } from "types/user-schema";

// Helpers
import { isNotNil, isNil } from "helpers/utils";

// i18n
import { useTranslation } from "react-i18next";
import MDTab from "components/MDTab";
import TodayReport from "./TodayReport";
import DownloadReport from "./DownloadReport";
import Report from "./Report";

export const ReportPage: React.FC = () => {
    const { t: translate } = useTranslation(["common"]);
    const [tab, setTab] = useState<string>("todayReport");

    const fetchTodayReport = async () => {
        try {
            // const response = await businessService.getBusinesses();
            // setBusinesses(response.data);
        } catch (error) {
            console.error("fetchTodayReport -> error", error);
        }
    };

    return (
        <BasicLayout>
            <MDBox mb={3}>
                <MDTab
                    list={[
                        { key: translate("report.todayReport"), value: "todayReport" },
                        { key: translate("report.historyReport"), value: "historyReport" },
                        // { key: translate("report.report"), value: "report" },
                    ]}
                    active={tab}
                    scrollable={false}
                    setActive={setTab}
                />
            </MDBox>
            <MDBox>
                <MDBox
                    sx={{
                        background: "#FFFFFF",
                        minHeight: "500px",
                        borderRadius: "12px",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                        position: "relative",
                    }}>
                    {tab === "todayReport" && <TodayReport />}
                    {tab === "historyReport" && <DownloadReport />}
                    {/* {tab === "report" && <Report />} */}
                </MDBox>
            </MDBox>
        </BasicLayout>
    );
};

export default ReportPage;
