// Setting layout components
import MDModal from "components/MDModal";
import MeasurementForm from "layouts/info/components/Form/Measurement";

// Type
import { FormOperation } from "types/enums";
import { HealthData } from "types/measurement-schema";
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    residentId: string;
    defaultData: HealthData;
    callback: (updated: boolean) => void;
}

export const NewMeasurement: React.FC<Props> = ({ setToggleModal, residentId, defaultData, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <MeasurementForm
                operation={FormOperation.CREATE}
                residentId={residentId}
                defaultData={defaultData}
                callback={callback}
            />
        </MDModal>
    );
};

export default NewMeasurement;
