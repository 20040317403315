import { Response, default as http } from "http-common";
import { NotificationSettings } from "types/setting-schema";

class UnitAlarmService {
    async getUnitAlarm(): Promise<Response<NotificationSettings[]>> {
        const response = await http.get<Response<NotificationSettings[]>>(`/api/v1/unitAlarm`);
        return response.data;
    }

    async createUnitAlarm(unitAlarmInfo: NotificationSettings): Promise<Response<NotificationSettings>> {
        const response = await http.post<Response<NotificationSettings>>(`/api/v1/unitAlarm`, unitAlarmInfo);
        return response.data;
    }

    async updateUnitAlarm(unitAlarmInfo: NotificationSettings): Promise<Response<NotificationSettings>> {
        const response = await http.put<Response<NotificationSettings>>(`/api/v1/unitAlarm`, unitAlarmInfo);
        return response.data;
    }

    async getWistronSetting(): Promise<Response<any>> {
        const response = await http.get<Response<any>>(`/api/v1/unitAlarm/wistron`);
        return response.data;
    }
}

export default new UnitAlarmService();
