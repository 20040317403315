import { Response, default as http } from "http-common";
import { ResidentialAlarmSetting } from "types/setting-schema";

class ResidentialAlarmService {
    async getResidentialAlarm(): Promise<Response<ResidentialAlarmSetting[]>> {
        const response = await http.get<Response<ResidentialAlarmSetting[]>>(`/api/v1/residentialAlarm`);
        return response.data;
    }

    async createResidentialAlarm(residentialAlarmInfo: ResidentialAlarmSetting): Promise<Response<ResidentialAlarmSetting>> {
        const response = await http.post<Response<ResidentialAlarmSetting>>(`/api/v1/residentialAlarm`, residentialAlarmInfo);
        return response.data;
    }

    async updateResidentialAlarm(residentialAlarmInfo: ResidentialAlarmSetting): Promise<Response<ResidentialAlarmSetting>> {
        const response = await http.put<Response<ResidentialAlarmSetting>>(`/api/v1/residentialAlarm`, residentialAlarmInfo);
        return response.data;
    }
}

export default new ResidentialAlarmService();
