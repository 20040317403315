// Setting layout components
import MDModal from "components/MDModal";
import QRCodeForm from "./components/Form/QRCode";

// Type
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    url: string;
}

export const ViewQRCode: React.FC<Props> = ({ setToggleModal, url }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <QRCodeForm url={url} />
        </MDModal>
    );
};

export default ViewQRCode;
