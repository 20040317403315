// Setting layout components
import MDModal from "components/MDModal";
import SurveyForm from "layouts/info/components/Form/Survey";
import { SurveyData } from "types/measurement-schema";

// Type
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    residentId: string;
    uncompletedSurveys: string[];
    callback: (updated: boolean,data:SurveyData) => void;
}

export const NewSurvey: React.FC<Props> = ({ setToggleModal, residentId, uncompletedSurveys, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <SurveyForm residentId={residentId} uncompletedSurveys={uncompletedSurveys} callback={callback} />
        </MDModal>
    );
};

export default NewSurvey;
