import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { FormOperation } from "types/enums";
import { SetOperationProp } from "types/state";
import { useTranslation } from "react-i18next";

interface Props extends SetOperationProp {}

export const EditButton: React.FC<Props> = ({ setOperation }) => {
    const { t: translate } = useTranslation(["common"]);
    return (
        <MDBox mx={5} my={1} sx={{ position: "absolute", right: 0 }}>
            <MDButton circular={false} onClick={() => setOperation(FormOperation.UPDATE)}>
                <Icon fontSize="medium" sx={{ cursor: "pointer", marginRight: "5px" }}>
                    create
                </Icon>
                {translate(`general.button.edit`)}
            </MDButton>
        </MDBox>
    );
};

export default EditButton;
