import React from "react";
import { User } from "types/user-schema";
import Icon from "@mui/material/Icon";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDNoData from "components/MDNoData";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { isNotNil } from "helpers/utils";
import { useTranslation } from "react-i18next";
import ResidentService from "services/resident.service";
import MDDailyScore from "components/MDDailyScore";
interface Cells {
    columns: { Header: string; accessor: string; disable?: boolean }[];
    rows: {
        id: string;
        name: string;
        score: JSX.Element;
        rest: JSX.Element;
        leavingBed: JSX.Element;
        vitalValue: JSX.Element;
        vitalAlarm: JSX.Element;
        toilet: JSX.Element;
    }[];
}

interface Props {
    list: any[];
    // count: number;
    // page: number;
    // setPage: (page: number) => void;
    loading?: boolean;
}
const convertTimestampToTime = (timestamp: number) => {
    const timezoneOffset = -6; // UTC+8
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const hours = (date.getUTCHours() + timezoneOffset) % 24;
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
};

const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getUTCDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
};

const Table: React.FC<Props> = ({ list, loading }) => {
    const { t: translate } = useTranslation(["common"]);
    if (list?.length === 0) {
        return (
            <MDNoData
                content={translate("general.notification.noData", {
                    type: `${translate("general.type.report")}`,
                })}
            />
        );
    }
    const cells: Cells = {
        columns: translate("report.reportTable", { returnObjects: true }),
        rows: [] as any[],
    };

    cells.rows = list?.map((data) => {
        return {
            id: data.id,
            bed: data.bed,
            name: data.name,
            score: <MDDailyScore sevenDaysData={data.CompositeScore} title={"score"} />,
            rest: (
                <MDDailyScore
                    sevenDaysData={data.sleepQualityLight}
                    title={"rest"}
                    circle={data.sleepCircle}
                    tip={{ sleep: `${data.sleepPercentage}%` }}
                />
            ),
            leavingBed: (
                <MDDailyScore
                    sevenDaysData={data.leaveBedLight}
                    title={"leavingBed"}
                    circle={data.leaveBedCircle}
                    tip={{
                        lb1: `${data.leaveBedTimes} ${translate(`report.tooltip.time`)}`,
                        lb2: `${data.leaveBedCurTime} ${translate(`report.tooltip.minute`)}`,
                    }}
                />
            ),
            vitalValue: (
                <MDDailyScore
                    sevenDaysData={data.physiologicalLight}
                    title={"vitalValue"}
                    circle={data.physiologicalCircle}
                    tip={{ vv1: data.RpmValue, vv2: data.BpmValue }}
                />
            ),
            vitalAlarm: (
                <MDDailyScore
                    sevenDaysData={data.alarmsLight}
                    title={"vitalAlarm"}
                    circle={data.alarmsCircle}
                    tip={{ va: `${data.alarmCount} ${translate(`report.tooltip.time`)}` }}
                />
            ),
            toilet: (
                <MDDailyScore
                    sevenDaysData={data.bathRoomAlarmsLight}
                    title={"toilet"}
                    circle={data.sleepCircle}
                    tip={{
                        t: `${data.toiletTooLongTime} ${translate(`report.tooltip.time`)}`,
                        t2: `${data.toiletTooLongCur} ${translate(`report.tooltip.minute`)}`,
                    }}
                />
            ),
        };
    });

    return <DataTable table={cells}  centerHeader={true} loading={loading} />;
};

export const ReportTable: React.FC<Props> = (props) => {
    return (
        <MDBox>
            <Table {...props} />
        </MDBox>
    );
};

export default ReportTable;
