// Setting layout components
import MDBox from "components/MDBox";
import MDModal from "components/MDModal";
import { AccountForm } from "layouts/account/components/Form";

// Type
import { FormOperation } from "types/enums";
import { ResidentNote } from "types/setting-schema";
import { ToggleProp } from "types/state";
import { renderNoteComment } from "..";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { convertTimestampToReadableDate } from "helpers/utils";
import React from "react";

interface Props extends ToggleProp {
    notes: ResidentNote[];
}

export const NotesList: React.FC<Props> = ({ setToggleModal, notes }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <MDBox sx={{ background: "white", height: "70vh", overflowY: "auto", margin: "16px", borderRadius: "6px" }}>
                <Grid container spacing={2} sx={{ padding: "28px" }}>
                    <Grid item xs={12}>
                        <MDBox sx={{ paddingTop: "20px" }}>
                            <MDTypography variant="h2">Notes</MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ padding: "28px", borderBottom: "1px solid #F0F2F5" }}>
                    <Grid item xs={3}>
                        <MDBox>
                            <MDTypography sx={{ fontSize: "18px" }}>Time</MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={9}>
                        <MDBox>
                            <MDTypography sx={{ fontSize: "18px" }}>Note</MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
                <Grid container spacing={2} rowGap={1} sx={{ padding: "28px" }}>
                    {notes.map((note, index) => (
                        <React.Fragment>
                            <Grid item xs={3}>
                                <MDBox>
                                    <MDTypography sx={{ fontSize: "18px", whiteSpace: "pre-wrap" }}>
                                        {convertTimestampToReadableDate(note.createdAt, true, true)}
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xs={9}>
                                <MDBox>
                                    <MDTypography sx={{ fontSize: "18px" }}>{note.content}</MDTypography>
                                </MDBox>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </MDBox>
        </MDModal>
    );
};

export default NotesList;
