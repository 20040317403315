import React, { useState, useEffect, Fragment, useRef } from "react";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDAvatar from "components/MDAvatar";
import BasicLayout from "examples/LayoutContainers/BasicLayout";
// Helpers
import { isNotNil, isNil, calculateAge, convertTimestampToReadableDate } from "helpers/utils";

// i18n
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getBirthday } from "helpers/formUtil";
import defaultAvatar from "assets/images/defaultAvatar.svg";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ResidentService from "services/resident.service";
import { ResidentCardProps } from "layouts/overview/schemas/card-schema";
import { ResidentNote, UnitRecord } from "types/setting-schema";
import MDCircularProgress from "components/MDCircularProgress";
import NotesList from "./component/notesList";
import { Reports, Summary } from "types/report";
import { CircularProgress, FormControl, MenuItem, Select, styled } from "@mui/material";
// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
import { Instance } from "flatpickr/dist/types/instance";
import residentService from "services/resident.service";
import AuthService from "services/auth.service";
import axios from "axios";
import { min, set } from "date-fns";
import MDErrorMsg from "components/MDErrorMsg";
import TodayReportList from "./component/todayReportList";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ReportService from "services/report.service";
import Cookies from "universal-cookie";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
//icon
import diabetesIcon from "assets/images/icons/risks/diabetes.svg";
import alertIcon from "assets/images/icons/summary/alert.png";
import positionIcon from "assets/images/icons/summary/position.png";
import bathIcon from "assets/images/icons/summary/bath.png";
import bedIcon from "assets/images/icons/summary/bed.png";
import kitchenIcon from "assets/images/icons/summary/kitchen.png";
import outdoorIcon from "assets/images/icons/summary/outdoor.png";
import roomIcon from "assets/images/icons/summary/room.png";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import _ from "lodash";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// import { autoPlay } from "react-swipeable-views-utils";
import { Autoplay } from "swiper/modules";
import { de } from "date-fns/locale";
const reportTypeOptions = [
    Reports.vsDaily,
    Reports.vsThreeDays,
    Reports.vsSeveral,
    Reports.vsFourteenDays,
    Reports.careFourteenDays,
];

const summaryOptions = [
    Summary.Today,
    // Summary.Yesterday,
    Summary.LastSeveralDays,
    Summary.LastFourteenDays,
    Summary.LastThirtyDays,
];

export const renderNoteComment = (note: ResidentNote, index: number) => {
    return (
        <MDBox sx={{ paddingTop: 2 }} key={`note_${index}`}>
            <MDTypography variant="h6" fontWeight="regular" color="lightBlue">
                {convertTimestampToReadableDate(note.createdAt, true)}
            </MDTypography>
            <MDTypography
                variant="h5"
                fontWeight="regular"
                color="lightBlue"
                sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                {note.content}
            </MDTypography>
        </MDBox>
    );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFFFF",
        color: "#5D5A88",
        maxWidth: 220,
        minWidth: 220,
        // fontSize: theme.typography.pxToRem(12),
        border: "1px solid #9B9BFF",
    },
}));

export const Profile: React.FC = () => {
    let state = useLocation().state as { residentId: string } | null;
    const { t: translate } = useTranslation(["common"]);
    const [resident, setResident] = useState<ResidentCardProps | null>(null);
    const [notes, setNotes] = useState<ResidentNote[] | null>([]);
    const [error, setError] = useState<string | null>(null);
    const [content, setContent] = useState<string>("");
    const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null });
    const [reportType, setReportType] = useState<Reports>(reportTypeOptions[0]);
    const [summaryType, setSummaryType] = useState<Summary>(summaryOptions[0]);

    const navigate = useNavigate();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [toggleReportModal, setToggleReportModal] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [todayReport, setTodayReport] = useState<any>(null);
    const fp = useRef(null);
    const cookies = new Cookies();
    useEffect(() => {
        if (isNotNil(state) || cookies.get("overview-residentId")) {
            fetchResident();
            fetchNotes();
        }
    }, [cookies.get("overview-residentId"), state]);
    useEffect(() => {
        if (!fp?.current?.flatpickr) return;
        fp.current.flatpickr.clear();
    }, [reportType]);
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 10000);
        }
    }, [error]);
    useEffect(() => {
        if (isNotNil(resident)) {
            fetchSummary();
        }
    }, [summaryType, resident]);
    const handleFlip = () => {
        setToggleModal(!toggleModal);
    };
    const fetchSummary = async () => {
        //get the timezone
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const res = await ReportService.getSummary(resident.wistron_id, timeZone, summaryType);
        if (res.success) {
            console.log(res.data);
            setTodayReport(res.data);
        }
    };
    const fetchResident = async () => {
        const residentId = isNotNil(state) ? state.residentId : cookies.get("overview-residentId");
        const res = await ResidentService.getSingleResident(residentId);
        if (res.success) {
            const {
                _id,
                avatarUrl,
                name,
                dateOfBirth,
                gender,
                address,
                center,
                risks,
                diabetes,
                preferredLanguage,
                unitRecords,
                phone,
                emergency,
                number,
                wistron_id,
            } = res.data;

            const data = {
                id: _id,
                avatarUrl: isNotNil(avatarUrl) ? avatarUrl : null,
                name: name,
                gender: gender,
                dateOfBirth: dateOfBirth,
                center: isNotNil(center) ? center.name : null,
                address: address,
                risks: risks,
                diabetes: diabetes,
                preferredLanguage: preferredLanguage,
                unitRecords: unitRecords,
                phone: phone,
                emergency: emergency,
                number: number,
                wistron_id: wistron_id,
            };
            setResident(data);
        }
    };
    const fetchNotes = async () => {
        const residentId = isNotNil(state) ? state.residentId : cookies.get("residentId");
        const res = await ResidentService.getResidentNotes(residentId);
        if (res.success) {
            setNotes(res.data);
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setError(null);

        // If a type of survey remains uncompleted, user can't create it
        if (content === "") {
            setError(translate("setting.resident.emptyContentError"));
            return;
        }

        const res = await handleCreateNote();
        handleResult(res);
    };

    const handleCreateNote = async () => {
        return ResidentService.createResidentNote(state.residentId, content);
    };

    const handleResult = (res: any) => {
        if (res.success) {
            setContent("");
            fetchNotes();
        } else {
            setError(res.message);
        }
    };
    const handleClick = () => {
        let residentId = isNotNil(state) ? state.residentId : cookies.get("overview-residentId");
        window.history.pushState({ prevUrl: "/overview/overview-unit" }, null);
        cookies.set("overview-residentId", residentId, { path: "/" });
        navigate("/daily-measurement-and-survey/physiological-data", {
            state: { residentId: residentId, tag: "chart" },
        });
    };

    const renderResidentCard = () => {
        const birthday = getBirthday(resident.dateOfBirth);
        const { year, month, day } = birthday;
        const avatarComponent = () => {
            if (isNotNil(resident.avatarUrl))
                return <MDAvatar src={resident.avatarUrl} alt="avatar" size="xxl" shadow="sm" />;
            return <MDAvatar src={defaultAvatar} alt="Default Svg" size="xxl" shadow="sm" />;
        };
        return (
            <Card id="infoCard-container">
                <MDBox>
                    <Grid container p={2} rowSpacing={2}>
                        <Grid item xs={12} sm={3}>
                            <Grid container p={2} rowSpacing={2} alignItems="center">
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                    {avatarComponent()}
                                </Grid>
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                    <MDTypography variant="h2" textAlign="center">
                                        {resident.name}
                                    </MDTypography>
                                </Grid>
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                    <MDTypography variant="h4" fontWeight="regular" color="lightBlue">
                                        {calculateAge(year, month, day)} {translate("setting.resident.years")}
                                    </MDTypography>
                                </Grid>
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                    <MDTypography variant="h4" fontWeight="regular" color="lightBlue">
                                        {translate(`setting.resident.${resident.gender}`)}
                                    </MDTypography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <HtmlTooltip
                                        placement="right"
                                        title={
                                            <React.Fragment>
                                                <MDBox
                                                    onClick={() => {
                                                        handleTodayReport("ander");
                                                    }}
                                                    sx={{ paddingLeft: "8px", cursor: "pointer" }}>
                                                    <MDTypography variant="h5" textAlign="left">
                                                        {`Ander ${translate(`setting.resident.report`)}`}
                                                    </MDTypography>
                                                </MDBox>
                                                <MDBox
                                                    onClick={() => {
                                                        handleTodayReport("anderx");
                                                    }}
                                                    sx={{ paddingLeft: "8px", cursor: "pointer" }}>
                                                    <MDTypography variant="h5" textAlign="left">
                                                        {`AnderX ${translate(`setting.resident.report`)}`}
                                                    </MDTypography>
                                                </MDBox>
                                            </React.Fragment>
                                        }>
                                        <MDBox
                                            sx={{
                                                background: "#BDBDFF",
                                                padding: "10px 10px",
                                                textAlign: "center",
                                                cursor: "pointer",
                                            }}>
                                            <MDTypography variant="h5">
                                                {translate(`setting.resident.exportReport`)}
                                            </MDTypography>
                                        </MDBox>
                                    </HtmlTooltip>
                                    <MDBox
                                        onClick={() => {
                                            handleClick();
                                        }}
                                        sx={{
                                            border: "1px solid #BDBDFF",
                                            padding: "10px 10px",
                                            textAlign: "center",
                                            marginTop: "12px",
                                            cursor: "pointer",
                                        }}>
                                        <MDTypography variant="h5">
                                            {translate(`setting.resident.physiologicalData`)}
                                        </MDTypography>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Grid container p={2} rowSpacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <MDTypography variant="h5" fontWeight="regular" color="lightBlue">
                                        {`${translate(`setting.resident.room`)}/ ${translate(`setting.resident.unit`)}`}
                                    </MDTypography>
                                    <MDTypography variant="h2" fontWeight="regular" color="lightBlue">
                                        {resident.unitRecords?.[0].unit.unitNumber}
                                    </MDTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <MDTypography variant="h5" fontWeight="regular" color="lightBlue">
                                        {translate(`setting.resident.number`)}
                                    </MDTypography>
                                    <MDTypography variant="h2" fontWeight="regular" color="lightBlue">
                                        {resident.number}
                                    </MDTypography>
                                </Grid>
                                <Grid item xs={12}>
                                    <MDTypography variant="h5" fontWeight="regular" color="lightBlue">
                                        {translate(`setting.resident.address`)}
                                    </MDTypography>
                                    <MDTypography variant="h2" fontWeight="regular" color="lightBlue">
                                        {resident.address}
                                    </MDTypography>
                                </Grid>
                                <Grid item xs={12}>
                                    <hr />
                                </Grid>
                                <Grid item xs={6}>
                                    <MDTypography variant="h5" fontWeight="regular" color="lightBlue">
                                        {translate(`setting.resident.dateOfBirth`)}
                                    </MDTypography>
                                    <MDTypography variant="h2" fontWeight="regular" color="lightBlue">
                                        {`${year}-${month}-${day}`}
                                    </MDTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <MDTypography variant="h5" fontWeight="regular" color="lightBlue">
                                        {translate(`setting.resident.email`)}
                                    </MDTypography>
                                    <MDTypography variant="h2" fontWeight="regular" color="lightBlue">
                                        {resident.email ? resident.email : "Null"}
                                    </MDTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <MDTypography variant="h5" fontWeight="regular" color="lightBlue">
                                        {translate(`setting.resident.homePhone`)}
                                    </MDTypography>
                                    <MDTypography variant="h2" fontWeight="regular" color="lightBlue">
                                        {resident.phone?.number ? resident.phone.number : "Null"}
                                    </MDTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <MDTypography variant="h5" fontWeight="regular" color="lightBlue">
                                        {translate(`setting.resident.phone`)}
                                    </MDTypography>
                                    <MDTypography variant="h2" fontWeight="regular" color="lightBlue">
                                        {resident.phone?.number ? resident.phone.number : "Null"}
                                    </MDTypography>
                                </Grid>

                                {resident.emergency &&
                                    resident.emergency.map((item, index) => (
                                        <Fragment key={`emergency_${index}`}>
                                            <Grid item xs={6}>
                                                <MDTypography variant="h5" fontWeight="regular" color="lightBlue">
                                                    {translate(`setting.resident.emergencyContact`)}
                                                </MDTypography>
                                                <MDTypography variant="h2" fontWeight="regular" color="lightBlue">
                                                    {`${item.name} / ${
                                                        item.relation.charAt(0).toUpperCase() + item.relation.slice(1)
                                                    }`}
                                                </MDTypography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MDTypography variant="h5" fontWeight="regular" color="lightBlue">
                                                    {translate(`setting.resident.emergencyContactPhone`)}
                                                </MDTypography>
                                                <MDTypography variant="h2" fontWeight="regular" color="lightBlue">
                                                    {item.phone.number}
                                                </MDTypography>
                                            </Grid>
                                        </Fragment>
                                    ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
            </Card>
        );
    };

    const renderNote = () => {
        return (
            <Card sx={{ p: 2, height: "100%" }}>
                <MDBox sx={{ overflow: "hidden" }}>
                    <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                        <MDTypography variant="h3" color="lightBlue">
                            {translate(`setting.resident.note`)}
                        </MDTypography>
                        <MDTypography
                            sx={{ cursor: "pointer" }}
                            variant="h5"
                            fontWeight="light"
                            color="lightBlue"
                            onClick={handleFlip}>
                            {translate(`setting.resident.more`)}
                        </MDTypography>
                    </MDBox>
                    <MDBox>
                        <MDBox sx={{ paddingTop: 2, position: "relative" }}>
                            <MDInput
                                multiline
                                rows={10}
                                circular={false}
                                sx={{ width: "100%", background: "#F4F4FF" }}
                                value={content}
                                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                                    setContent(e.target.value)
                                }
                            />
                            <MDButton
                                sx={{
                                    position: "absolute",
                                    right: 10,
                                    bottom: 10,
                                    background: "#BDBDFF",
                                    color: "black !important",
                                    borderRadius: 0,
                                }}
                                circular={false}
                                onClick={(e) => handleSubmit(e)}>
                                {translate(`setting.resident.save`)}
                            </MDButton>
                        </MDBox>

                        {notes.slice(0, 2).map((item, index) => renderNoteComment(item, index))}
                    </MDBox>
                </MDBox>
            </Card>
        );
    };

    const renderHistoryRecord = (item: UnitRecord, index: number) => {
        return (
            <MDBox
                key={`history_records_${index}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                pt={2}>
                <MDBox display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="regular" color="lightBlue" pr={1}>
                        {`${index + 1}.`}
                    </MDTypography>
                    <Icon fontSize="medium" sx={{ mt: -0.2, mr: 1 }}>
                        home
                    </Icon>
                    <MDTypography variant="h4" fontWeight="regular" color="lightBlue">
                        {`${item.unit.unitNumber}, ${item.unit?.address}`}
                    </MDTypography>
                </MDBox>
                <MDBox display="flex" sx={{ marginLeft: { xs: "auto" } }}>
                    <MDTypography
                        variant="subtitle2"
                        color="lightBlue"
                        sx={{ background: "#BDBDFF", p: "10px 15px", borderRadius: "10px" }}>
                        {convertTimestampToReadableDate(item.moveInDate)}
                    </MDTypography>
                    <MDTypography
                        variant="subtitle2"
                        color="lightBlue"
                        sx={{ background: "#D9D9D9", p: "10px 15px", borderRadius: "10px", ml: "20px" }}>
                        {item.moveOutDate
                            ? convertTimestampToReadableDate(item.moveOutDate)
                            : translate(`setting.resident.persent`)}
                    </MDTypography>
                </MDBox>
            </MDBox>
        );
    };

    const renderHistory = () => {
        return (
            <>
                <MDBox>
                    <MDTypography variant="h1" color="lightBlue">
                        {translate(`setting.resident.roomHistory`)}
                    </MDTypography>
                </MDBox>
                <Card sx={{ p: 2, mt: 1 }}>
                    {resident.unitRecords &&
                        resident.unitRecords.map((item, index) => renderHistoryRecord(item, index))}
                </Card>
            </>
        );
    };

    const submitForm = async (event: any) => {
        event.preventDefault();
        setError(null);
        if (selectedDates.endDate === null && selectedDates.startDate === null) {
            setError(translate("setting.resident.emptyDateError"));
            return;
        }
        try {
            const instance = axios.create({
                baseURL:
                    process.env.REACT_APP_ENV === "production"
                        ? "https://api.wecaredynamics.com/"
                        : process.env.REACT_APP_ENV === "development"
                        ? "https://devapi.wecaredynamics.com/"
                        : "http://localhost:6000/",
            });
            instance.interceptors.request.use(function (config) {
                const token = AuthService.getAuthToken();
                if (token !== null) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                config.headers["Content-Type"] = "application/json";
                return config;
            });
            setIsFetching(true);
            const wistronResident = await ResidentService.getWistronResidentByID(resident.wistron_id);
            if (wistronResident.data) {
                const { mom, floor, roomName, bed } = wistronResident.data;
                let dateRange;
                if (selectedDates.startDate === selectedDates.endDate) {
                    dateRange = selectedDates.startDate;
                } else {
                    dateRange = `${selectedDates.startDate}~${selectedDates.endDate}`;
                }
                const response = await instance.get(
                    `/api/v1/report/getReport?lang=${translate(
                        "lang",
                    )}&reportType=${reportType}&bed=${floor}-${roomName}-${bed}&residentName=${mom}&dateRange=${dateRange}`,
                    { responseType: "blob" },
                );

                if (response.data) {
                    let reportFileName;
                    switch (reportType) {
                        case Reports.vsDaily:
                            reportFileName = "VS-Daily";
                            break;
                        case Reports.vsThreeDays:
                            reportFileName = "VS-ThreeDays";
                            break;
                        case Reports.vsSeveral:
                            reportFileName = "VS-Several";
                            break;
                        case Reports.vsFourteenDays:
                            reportFileName = "VS-Biweekly";
                            break;
                        case Reports.careFourteenDays:
                            reportFileName = "Care-Biweekly";
                            break;
                        default:
                            break;
                    }
                    const contentDisposition = response.headers["content-disposition"];
                    let filename = `${reportFileName}-${floor}-${roomName}-${bed}-${mom}-${selectedDates.startDate}~${selectedDates.endDate}.pdf`; // Default filename
                    if (contentDisposition) {
                        const filenameRegex = /filename\*=UTF-8''([^;]*)/;
                        const matches = filenameRegex.exec(contentDisposition);
                        if (matches != null && matches[1]) {
                            filename = decodeURIComponent(matches[1]);
                        }
                    }
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    setIsFetching(false);
                }
            }
        } catch (error) {
            setError(translate("setting.resident.fetchReportError"));
            setIsFetching(false);
        }
    };

    const handleReportTypeChange = (e: any) => {
        setReportType(e.target.value);
    };
    const handleSummaryTypeChange = (e: any) => {
        setSummaryType(e.target.value);
    };
    const formatDate = (date: Date) => {
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const handleSetDate = (date: Date, range: number, self: Instance) => {
        let startDate, endDate;
        if (range === 1) {
            startDate = formatDate(date);
            endDate = formatDate(date);
            self.clear();
            self.setDate([startDate], false, "Y/m/d");
        } else {
            // if (new Date(date.getTime() + 86400000 * range) > new Date()) {
            //     console.log("adjustedTimeAsMs > new Date().getTime()");
            //     startDate = formatDate(new Date(new Date(date).getTime() - 86400000 * (range - 1)));
            //     endDate = formatDate(date);
            // } else {
            //     endDate = formatDate(new Date(new Date(date).getTime() + 86400000 * (range - 1)));
            //     startDate = formatDate(date);
            // }
            endDate = formatDate(new Date(new Date(date).getTime() - 86400000 * (range - 1)));
            startDate = formatDate(date);
            //update the Flatpickr
            self.clear();
            self.setDate([startDate, endDate], false, "Y/m/d");
        }
        setSelectedDates({ startDate, endDate });
    };

    const renderReport = () => {
        return (
            <MDBox>
                <MDBox>
                    <MDTypography variant="h1" color="lightBlue">
                        {translate(`setting.resident.report`)}
                    </MDTypography>
                </MDBox>
                <Card sx={{ p: 2, mt: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={2.5}>
                            <MDBox>
                                <MDTypography variant="h5" color="lightBlue">
                                    {translate(`report.reportType.title`)}
                                    <span style={{ color: "red", verticalAlign: "super" }}>*</span>
                                </MDTypography>
                                <FormControl sx={{ width: "100%", position: "relative" }}>
                                    <Select
                                        disabled={isFetching}
                                        defaultValue="All"
                                        sx={{
                                            py: 1,
                                            background: "#E7E7E7",
                                            // border: "1px solid #344767",
                                            borderRadius: "0px",
                                            position: "relative",
                                            zIndex: 5,
                                        }}
                                        value={reportType}
                                        onChange={handleReportTypeChange}>
                                        {reportTypeOptions.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <MDTypography variant="h5" pl={1} pr={5}>
                                                    {translate(`report.reportType.${item}`)}
                                                </MDTypography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Icon
                                        sx={{
                                            position: "absolute",
                                            right: "20px",
                                            transform: "translateY(50%)",
                                            display: "inline-block !important",
                                            zIndex: "6",
                                        }}>
                                        expand_more
                                    </Icon>
                                </FormControl>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={2.5}>
                            <MDBox>
                                <MDTypography variant="h5" color="lightBlue">
                                    {translate(`report.period`)}
                                    <span style={{ color: "red", verticalAlign: "super" }}>*</span>
                                </MDTypography>
                                <FormControl sx={{ width: "100%", position: "relative" }}>
                                    <MDBox
                                        sx={{
                                            py: 1,
                                            background: "#E7E7E7",
                                            borderRadius: "0px",
                                            position: "relative",
                                        }}>
                                        <Flatpickr
                                            ref={fp}
                                            disabled={isFetching}
                                            options={{
                                                mode: reportType !== reportTypeOptions[0] ? "range" : "single",
                                                locale: {
                                                    rangeSeparator: " - ",
                                                },
                                                maxDate: new Date(),
                                                onChange: (
                                                    date: Date[],
                                                    currentDateString: string,
                                                    self: Instance,
                                                    data: any,
                                                ) => {
                                                    if (date.length === 1) {
                                                        switch (reportType) {
                                                            case Reports.vsDaily:
                                                                handleSetDate(date[0], 1, self);
                                                                break;
                                                            case Reports.vsThreeDays:
                                                                handleSetDate(date[0], 3, self);
                                                                break;
                                                            case Reports.vsSeveral:
                                                                handleSetDate(date[0], 7, self);
                                                                break;
                                                            case Reports.vsFourteenDays:
                                                                handleSetDate(date[0], 14, self);
                                                                break;
                                                            case Reports.careFourteenDays:
                                                                handleSetDate(date[0], 14, self);
                                                                break;
                                                        }
                                                    } else {
                                                    }
                                                },
                                            }}
                                            render={({ defaultValue }: any, ref: any) => (
                                                <MDInput
                                                    sx={{
                                                        height: "22px",
                                                        width: "100%",
                                                        padding: "0 8px",
                                                        textAlign: "center",
                                                        "& input": {
                                                            fontSize: "1rem",
                                                            fontWeight: "700",
                                                            padding: "0",
                                                            "&:before": { border: "0 !important" },
                                                        },
                                                    }}
                                                    defaultValue={defaultValue}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                    </MDBox>
                                </FormControl>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <MDBox sx={{ textAlign: "right" }}>
                                <MDTypography variant="h5" color="lightBlue">
                                    <span>&nbsp;</span>
                                </MDTypography>
                                <MDButton
                                    disabled={isFetching}
                                    sx={{
                                        background: "#BDBDFF",
                                        color: "black",
                                        borderRadius: 0,
                                        height: "38px",
                                        minWidth: "179px",
                                    }}
                                    circular={isFetching}
                                    onClick={submitForm}>
                                    {isFetching ? (
                                        <CircularProgress sx={{ color: "white !important" }} size={20} />
                                    ) : (
                                        translate(`report.download`)
                                    )}
                                </MDButton>
                            </MDBox>
                        </Grid>
                    </Grid>
                </Card>
            </MDBox>
        );
    };

    const renderSummary = () => {
        const getFormattedDate = (daysOffset: number) => {
            const date = new Date();
            date.setDate(date.getDate() + daysOffset);
            return date.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit" });
        };

        return (
            <MDBox>
                <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                    <MDTypography variant="h1" color="lightBlue">
                        {translate(`setting.resident.summary`)}
                    </MDTypography>
                    <Select
                        disabled={isFetching}
                        defaultValue={Summary.Today}
                        sx={{
                            py: 1,
                            // background: "#E7E7E7",
                            // border: "1px solid #344767",
                            // borderRadius: "0px",
                            width: "165px",
                            position: "relative",
                            zIndex: 5,
                        }}
                        value={summaryType}
                        onChange={handleSummaryTypeChange}>
                        {summaryOptions.map((item) => (
                            <MenuItem key={item} value={item}>
                                <MDTypography variant="h5" pl={1} pr={5}>
                                    {translate(`report.summaryType.${item}`)}
                                    {item === Summary.Today && ` (${getFormattedDate(-1)})`}
                                </MDTypography>
                            </MenuItem>
                        ))}
                    </Select>
                </MDBox>
                <Grid container spacing={2} paddingLeft={3} paddingRight={3} paddingBottom={6}>
                    <Grid item xs={12} md={4}>
                        {renderAlertSummaryCard()}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {renderPositionSummaryCard()}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {renderHeartSummaryCard()}
                    </Grid>
                </Grid>
                <MDBox paddingLeft={2}>
                    <MDTypography fontSize={"20px"} fontWeight="600" color="lightBlue">
                        {translate(`setting.alarms.roomDataAnalysis`)}
                    </MDTypography>
                </MDBox>
                <Grid container spacing={2} paddingLeft={3} paddingRight={3}>
                    <Grid item xs={12} md={4}>
                        {renderToiletSummaryCard()}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {renderSleepSummaryCard()}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {renderNightToiletSummaryCard()}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {renderKitchenSummaryCard()}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {renderOutofRoomSummaryCard()}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {renderLivingRoomSummaryCard()}
                    </Grid>
                </Grid>
            </MDBox>
        );
    };

    const renderAlertSummaryCard = () => {
        //loop the todayReport.alert array to count the alert with the alert.status to be the key
        const result: {
            [key: string]: {
                count: number;
                oldCount?: number;
            };
        } = {};
        todayReport.alert?.forEach((item: any) => {
            if (result[item.status]) {
                result[item.status].count += 1;
            } else {
                result[item.status] = {
                    count: 1,
                    oldCount: 0, // Initialize oldCount to 0
                };
            }
        });

        todayReport.oldAlert?.forEach((item: any) => {
            if (result[item.status]) {
                result[item.status].oldCount += 1;
            } else {
                result[item.status] = {
                    count: 0,
                    oldCount: 1,
                };
            }
        });
        const isEmpty = _.isEmpty(result);
        return (
            <Card sx={{ p: 2, mt: 1, minHeight: "167px", height: "100%" }}>
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                    <MDBox width="45px" sx={{ marginRight: "11px" }}>
                        <img src={alertIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.alertevent`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        paddingTop: "22px",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}>
                    {!isEmpty ? (
                        Object.keys(result)?.map((key, index) => {
                            const upOrDownBoolean = result[key].count > (result[key].oldCount || 0);
                            const diff = Math.abs(result[key].count - (result[key].oldCount || 0));
                            console.log(upOrDownBoolean, diff);
                            return (
                                <MDBox
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        color: "#303E4A",
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        paddingTop: "8px",
                                        alignItems: "center",
                                    }}>
                                    <MDBox>{translate(`info.realTimeInfo.alertType.${key}`)}:</MDBox>
                                    <MDBox sx={{ paddingLeft: "5px" }}>{result[key].count}</MDBox>
                                    {result[key].oldCount && diff !== 0 && (
                                        <MDBox
                                            sx={{
                                                paddingLeft: "5px",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "24px",
                                            }}>
                                            {upOrDownBoolean ? (
                                                <ArrowUpwardIcon
                                                    sx={{
                                                        paddingLeft: "5px",
                                                        color: upOrDownBoolean ? "#BFBFFF" : "#FFD8BF",
                                                    }}
                                                />
                                            ) : (
                                                <ArrowDownwardIcon
                                                    sx={{
                                                        paddingLeft: "5px",
                                                        color: upOrDownBoolean ? "#BFBFFF" : "#FFD8BF",
                                                    }}
                                                />
                                            )}
                                            <MDBox sx={{ fontSize: "14px" }}>{diff}</MDBox>
                                        </MDBox>
                                    )}
                                </MDBox>
                            );
                        })
                    ) : (
                        <MDBox sx={{ color: "#303E4A", fontSize: "20px", fontWeight: "700" }}>
                            {translate(`general.state.noData`)}
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        );
    };

    const renderHeartSummaryCard = () => {
        //loop the todayReport.alert array to count the alert with the alert.status to be the key
        const isEmpty = _.isEmpty(todayReport.hr);
        return (
            <Card sx={{ p: 2, mt: 1, minHeight: "167px", height: "100%" }}>
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                    <MDBox width="45px" sx={{ marginRight: "11px" }}>
                        <img src={diabetesIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.hr`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        paddingTop: "22px",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {!isEmpty ? (
                        <MDBox>
                            <MDBox
                                sx={{
                                    display: "flex",
                                    color: "#303E4A",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    paddingTop: "8px",
                                }}>
                                <MDBox>{translate(`general.state.low`)}:</MDBox>
                                <MDBox sx={{ paddingLeft: "5px" }}>{Math.round(todayReport.hr.max)} bpm</MDBox>
                            </MDBox>
                            <MDBox
                                sx={{
                                    display: "flex",
                                    color: "#303E4A",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    paddingTop: "8px",
                                }}>
                                <MDBox>{translate(`general.state.high`)}:</MDBox>
                                <MDBox sx={{ paddingLeft: "5px" }}>{Math.round(todayReport.hr.min)} bpm</MDBox>
                            </MDBox>
                            <MDBox
                                sx={{
                                    display: "flex",
                                    color: "#303E4A",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    paddingTop: "8px",
                                }}>
                                <MDBox>{translate(`general.state.average`)}:</MDBox>
                                <MDBox sx={{ paddingLeft: "5px" }}>{Math.round(todayReport.hr.avg)} bpm</MDBox>
                            </MDBox>
                        </MDBox>
                    ) : (
                        <MDBox sx={{ color: "#303E4A", fontSize: "20px", fontWeight: "700" }}>
                            {translate(`general.state.noData`)}
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        );
    };

    const renderPositionSummaryCard = () => {
        //loop the todayReport.alert array to count the alert with the alert.status to be the key
        const isEmpty = _.isEmpty(todayReport.lastPosition);
        return (
            <Card sx={{ p: 2, mt: 1, minHeight: "167px", height: "100%" }}>
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                    <MDBox width="45px" sx={{ marginRight: "11px" }}>
                        <img src={positionIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.lastPosition`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        paddingTop: "22px",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {!isEmpty ? (
                        <MDBox
                            sx={{
                                display: "flex",
                                color: "#303E4A",
                                fontSize: "20px",
                                fontWeight: "700",
                                paddingTop: "8px",
                            }}>
                            <MDBox sx={{ paddingLeft: "5px" }}>
                                {translate(`device.roomType.${todayReport.lastPosition}`)}
                            </MDBox>
                        </MDBox>
                    ) : (
                        <MDBox sx={{ color: "#303E4A", fontSize: "20px", fontWeight: "700" }}>
                            {translate(`general.state.noData`)}
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        );
    };

    const renderToiletSummaryCard = () => {
        //loop the todayReport.alert array to count the alert with the alert.status to be the key
        const isEmptyNew = todayReport.toilet?.new;
        const isEmptyOld = todayReport.toilet?.old;
        const diff = Math.abs(isEmptyNew - (isEmptyOld || 0));
        const upOrDownBoolean = isEmptyNew > (isEmptyOld || 0);
        const normalTimes = 8;
        return (
            <Card sx={{ p: 2, mt: 1, minHeight: "167px", height: "100%" }}>
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                    <MDBox width="45px" sx={{ marginRight: "11px" }}>
                        <img src={bathIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.toilet`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        paddingTop: "22px",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {isEmptyNew ? (
                        <MDBox
                            sx={{
                                // display: "flex",
                                color: "#303E4A",
                                fontSize: "20px",
                                fontWeight: "700",
                                paddingTop: "8px",
                            }}>
                            <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <MDBox sx={{ position: "relative" }}>
                                    {todayReport.toilet?.new}
                                    <MDBox
                                        sx={{
                                            paddingLeft: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "24px",
                                            position: "absolute",
                                            top: "-3px",
                                            right: "-55px",
                                        }}>
                                        {todayReport.toilet?.old && diff !== 0 && (
                                            <MDBox sx={{ display: "flex", alignItems: "center" }}>
                                                {upOrDownBoolean ? (
                                                    <ArrowUpwardIcon
                                                        sx={{
                                                            paddingLeft: "5px",
                                                            color: "#BFBFFF",
                                                        }}
                                                    />
                                                ) : (
                                                    <ArrowDownwardIcon
                                                        sx={{
                                                            paddingLeft: "5px",
                                                            color: "#FFD8BF",
                                                        }}
                                                    />
                                                )}
                                                <MDBox sx={{ fontSize: "14px", paddingLeft: "5px" }}>{diff}</MDBox>
                                            </MDBox>
                                        )}
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                            <MDBox sx={{ paddingTop: "8px" }}>
                                {translate(`general.state.${isEmptyNew > normalTimes ? "abnormal" : "normal"}`)}
                            </MDBox>
                        </MDBox>
                    ) : (
                        <MDBox sx={{ color: "#303E4A", fontSize: "20px", fontWeight: "700" }}>
                            {translate(`general.state.noData`)}
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        );
    };

    const renderLivingRoomSummaryCard = () => {
        //get the room key and percentage at todayReport.RoomStats
        return (
            <Card sx={{ p: 2, mt: 1, minHeight: "167px", height: "100%", width: "100%" }}>
                <MDBox sx={{ display: "flex", alignItems: "center", paddingBottom: "22px" }}>
                    <MDBox width="45px" sx={{ marginRight: "11px" }}>
                        <img src={roomIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.roomPercentage`)}
                    </MDTypography>
                </MDBox>
                {/* 6s */}
                <Swiper
                    modules={[Autoplay]}
                    autoplay={{ delay: 6000 }}
                    spaceBetween={50}
                    slidesPerView={1}
                    style={{ width: "100%" }}>
                    {Object.keys(todayReport.roomStats).map((key, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <MDBox
                                    sx={{
                                        display: "flex",
                                        color: "#303E4A",
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        paddingTop: "8px",
                                        flexDirection: "column",
                                        cursor: "pointer",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                    <MDBox>{translate(`setting.unit.roomType.${key}`)}</MDBox>
                                    <MDBox sx={{ paddingTop: "11px" }}>
                                        {translate(`setting.alarms.hourPercent`, {
                                            hr: Number(todayReport.roomStats[key].totalTime / 60 / 60).toFixed(1),
                                            percent: todayReport.roomStats[key].percentage.toFixed(1),
                                        })}
                                    </MDBox>
                                </MDBox>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Card>
        );
    };

    const renderOutofRoomSummaryCard = () => {
        const total = todayReport.notInRoom.total;
        const oldTotal = todayReport.notInRoom.oldTotal;
        const totalDiff = Math.abs(total - (oldTotal || 0));
        const totalUpOrDownBoolean = total > (oldTotal || 0);
        const night = todayReport.notInRoom.night;
        const oldNight = todayReport.notInRoom.oldNight;
        const nightDiff = Math.abs(night - (oldNight || 0));
        const nightUpOrDownBoolean = night > (oldNight || 0);
        return (
            <Card sx={{ p: 2, mt: 1, minHeight: "167px", height: "100%", width: "100%" }}>
                <MDBox sx={{ display: "flex", alignItems: "center", paddingBottom: "22px" }}>
                    <MDBox width="45px" sx={{ marginRight: "11px" }}>
                        <img src={outdoorIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.notInRoom`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        color: "#303E4A",
                        fontSize: "20px",
                        fontWeight: "700",
                        paddingTop: "8px",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                    <MDBox sx={{ paddingTop: "11px", position: "relative" }}>
                        {translate(`setting.alarms.subtitle.notInRoomCount`, { value: todayReport.notInRoom.total })}
                        <MDBox
                            sx={{
                                paddingLeft: "5px",
                                display: "flex",
                                alignItems: "center",
                                fontSize: "24px",
                                position: "absolute",
                                top: "8px",
                                right: "-35px",
                            }}>
                            {oldTotal &&
                                totalDiff !== 0 &&
                                (totalUpOrDownBoolean ? (
                                    <ArrowUpwardIcon
                                        sx={{
                                            paddingLeft: "5px",
                                            color: totalUpOrDownBoolean ? "#BFBFFF" : "#FFD8BF",
                                        }}
                                    />
                                ) : (
                                    <ArrowDownwardIcon
                                        sx={{
                                            paddingLeft: "5px",
                                            color: totalUpOrDownBoolean ? "#BFBFFF" : "#FFD8BF",
                                        }}
                                    />
                                ))}
                            {oldTotal && totalDiff !== 0 && <MDBox sx={{ fontSize: "14px" }}>{totalDiff}</MDBox>}
                        </MDBox>
                    </MDBox>
                    <MDBox sx={{ paddingTop: "11px", position: "relative" }}>
                        {translate(`setting.alarms.subtitle.notInRoomNightCount`, {
                            value: todayReport.notInRoom.night,
                        })}
                        <MDBox
                            sx={{
                                paddingLeft: "5px",
                                display: "flex",
                                alignItems: "center",
                                fontSize: "24px",
                                position: "absolute",
                                top: "8px",
                                right: "-35px",
                            }}>
                            {oldNight &&
                                nightDiff !== 0 &&
                                (nightUpOrDownBoolean ? (
                                    <ArrowUpwardIcon
                                        sx={{
                                            paddingLeft: "5px",
                                            color: nightUpOrDownBoolean ? "#BFBFFF" : "#FFD8BF",
                                        }}
                                    />
                                ) : (
                                    <ArrowDownwardIcon
                                        sx={{
                                            paddingLeft: "5px",
                                            color: nightUpOrDownBoolean ? "#BFBFFF" : "#FFD8BF",
                                        }}
                                    />
                                ))}
                            {oldTotal && totalDiff !== 0 && <MDBox sx={{ fontSize: "14px" }}>{nightDiff}</MDBox>}
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        );
    };

    const renderSleepSummaryCard = () => {
        //cal the sleep time is it > 6h
        const sleepenought = todayReport.sleepStatus?.percentage > 6 * 60 * 60;
        return (
            <Card sx={{ p: 2, mt: 1, minHeight: "167px", height: "100%", width: "100%" }}>
                <MDBox sx={{ display: "flex", alignItems: "center", paddingBottom: "22px" }}>
                    <MDBox width="45px" sx={{ marginRight: "11px" }}>
                        <img src={bedIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.bedInfo`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        color: "#303E4A",
                        fontSize: "20px",
                        fontWeight: "700",
                        paddingTop: "8px",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                    {/* <MDBox sx={{ paddingTop: "11px" }}>
                        {translate(`setting.alarms.sleepCount`, { value: totalCount })}
                    </MDBox> */}
                    <MDBox sx={{ paddingTop: "11px" }}>
                        {translate(`setting.alarms.restTime`, {
                            value: Number(todayReport.sleepStatus?.percentage / 60 / 60).toFixed(1),
                        })}
                    </MDBox>
                    <MDBox sx={{ paddingTop: "11px", fontSize: "14px" }}>
                        {translate(`setting.alarms.dailyRestTIme`, {
                            value: Number(
                                todayReport.sleepStatus?.percentage / 60 / 60 -
                                    todayReport.sleepStatus?.nightSleep / 60 / 60,
                            ).toFixed(1),
                        })}
                    </MDBox>
                    <MDBox sx={{ paddingTop: "4px", fontSize: "14px" }}>
                        {translate(`setting.alarms.nightRestTime`, {
                            value: Number(todayReport.sleepStatus?.nightSleep / 60 / 60).toFixed(1),
                        })}
                    </MDBox>
                    <MDBox sx={{ paddingTop: "4px", fontSize: "16px" }}>
                        {translate(`setting.alarms.${sleepenought ? "enough" : "insufficient"}`)}
                    </MDBox>
                </MDBox>
            </Card>
        );
    };

    const renderNightToiletSummaryCard = () => {
        //loop the todayReport.alert array to count the alert with the alert.status to be the key
        const isEmptyNew = todayReport.toilet?.night;
        const isEmptyOld = todayReport.toilet?.oldNight;
        const diff = Math.abs(isEmptyNew - (isEmptyOld || 0));
        const upOrDownBoolean = isEmptyNew > (isEmptyOld || 0);
        const normalTimes = 2;
        return (
            <Card sx={{ p: 2, mt: 1, minHeight: "167px", height: "100%" }}>
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                    <MDBox width="45px" sx={{ marginRight: "11px" }}>
                        <img src={bathIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.nightToilet`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        paddingTop: "22px",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {isEmptyNew ? (
                        <MDBox
                            sx={{
                                // display: "flex",
                                color: "#303E4A",
                                fontSize: "20px",
                                fontWeight: "700",
                                paddingTop: "8px",
                            }}>
                            <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <MDBox sx={{ position: "relative" }}>
                                    {todayReport.toilet?.night}
                                    <MDBox
                                        sx={{
                                            paddingLeft: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "24px",
                                            position: "absolute",
                                            top: "-3px",
                                            right: "-55px",
                                        }}>
                                        {todayReport.toilet?.oldNight && diff !== 0 && (
                                            <MDBox sx={{ display: "flex", alignItems: "center" }}>
                                                {upOrDownBoolean ? (
                                                    <ArrowUpwardIcon
                                                        sx={{
                                                            paddingLeft: "5px",
                                                            color: "#BFBFFF",
                                                        }}
                                                    />
                                                ) : (
                                                    <ArrowDownwardIcon
                                                        sx={{
                                                            paddingLeft: "5px",
                                                            color: "#FFD8BF",
                                                        }}
                                                    />
                                                )}
                                                <MDBox sx={{ fontSize: "14px", paddingLeft: "5px" }}>{diff}</MDBox>
                                            </MDBox>
                                        )}
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                            <MDBox sx={{ paddingTop: "8px" }}>
                                {translate(`general.state.${isEmptyNew > normalTimes ? "abnormal" : "normal"}`)}
                            </MDBox>
                        </MDBox>
                    ) : (
                        <MDBox sx={{ color: "#303E4A", fontSize: "20px", fontWeight: "700" }}>
                            {translate(`general.state.noData`)}
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        );
    };

    const renderKitchenSummaryCard = () => {
        //loop the todayReport.alert array to count the alert with the alert.status to be the key
        const isEmptyNew = todayReport.kitchen?.new;
        const isEmptyOld = todayReport.kitchen?.old;
        const diff = Math.abs(isEmptyNew - (isEmptyOld || 0));
        const upOrDownBoolean = isEmptyNew > (isEmptyOld || 0);
        const normalTimes = 8;
        return (
            <Card sx={{ p: 2, mt: 1, minHeight: "167px", height: "100%" }}>
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                    <MDBox width="45px" sx={{ marginRight: "11px" }}>
                        <img src={kitchenIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.kitchenInfo`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        paddingTop: "22px",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {isEmptyNew ? (
                        <MDBox
                            sx={{
                                // display: "flex",
                                color: "#303E4A",
                                fontSize: "20px",
                                fontWeight: "700",
                                paddingTop: "8px",
                            }}>
                            <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <MDBox sx={{ position: "relative" }}>
                                    {todayReport.toilet?.new}
                                    <MDBox
                                        sx={{
                                            paddingLeft: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "24px",
                                            position: "absolute",
                                            top: "-3px",
                                            right: "-55px",
                                        }}>
                                        {todayReport.kitchen?.old && diff !== 0 && (
                                            <MDBox sx={{ display: "flex", alignItems: "center" }}>
                                                {upOrDownBoolean ? (
                                                    <ArrowUpwardIcon
                                                        sx={{
                                                            paddingLeft: "5px",
                                                            color: upOrDownBoolean ? "#BFBFFF" : "#FFD8BF",
                                                        }}
                                                    />
                                                ) : (
                                                    <ArrowDownwardIcon
                                                        sx={{
                                                            paddingLeft: "5px",
                                                            color: upOrDownBoolean ? "#BFBFFF" : "#FFD8BF",
                                                        }}
                                                    />
                                                )}
                                                <MDBox sx={{ fontSize: "14px", paddingLeft: "5px" }}>{diff}</MDBox>
                                            </MDBox>
                                        )}
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                            <MDBox sx={{ paddingTop: "8px" }}>
                                {translate(`general.state.${isEmptyNew < normalTimes ? "abnormal" : "normal"}`)}
                            </MDBox>
                        </MDBox>
                    ) : (
                        <MDBox sx={{ color: "#303E4A", fontSize: "20px", fontWeight: "700" }}>
                            {translate(`general.state.noData`)}
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        );
    };

    const handleTodayReport = async (type: "ander" | "anderx") => {
        if (type === "ander") {
            const res = await ResidentService.getTodayAnderReport(resident.wistron_id);
            if (res.success) {
                setTodayReport(res.data);
                setToggleReportModal(!toggleReportModal);
            }
        } else {
            const res = await ResidentService.getTodayAnderXReport(resident.wistron_id);
            if (res.success) {
                setTodayReport(res.data);
                setToggleReportModal(!toggleReportModal);
            }
        }
    };

    return (
        <BasicLayout>
            {toggleModal && <NotesList setToggleModal={setToggleModal} notes={notes} />}
            {toggleReportModal && <TodayReportList setToggleModal={setToggleReportModal} report={todayReport} />}
            <Grid container columnSpacing={2} rowSpacing={5} justifyContent="center" alignItems="stretch">
                {isNotNil(error) && (
                    <Grid item xs={12}>
                        <MDErrorMsg errorMsg={error} />
                    </Grid>
                )}
                <Grid item xs={12} md={8}>
                    {resident ? renderResidentCard() : <MDCircularProgress />}
                </Grid>
                <Grid item xs={12} md={4} alignItems="stretch">
                    {resident ? renderNote() : <MDCircularProgress />}
                </Grid>
                <Grid item xs={12} md={12}>
                    {renderReport()}
                </Grid>
                <Grid item xs={12} md={12}>
                    {todayReport && renderSummary()}
                </Grid>
                <Grid item xs={12} md={12}>
                    {resident ? renderHistory() : <MDCircularProgress />}
                </Grid>
            </Grid>
        </BasicLayout>
    );
};

export default Profile;
