// Setting layout components
import MDModal from "components/MDModal";
import { UnitForm } from "layouts/settings/components/Forms/UnitForm";

// Type
import { FormOperation } from "types/enums";
import { Unit } from "types/setting-schema";
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    businessId: string;
    unit: Unit;
    callback: (updated: boolean, isDelete?: boolean) => void;
}

export const ViewUnit: React.FC<Props> = ({ setToggleModal, businessId, unit, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <UnitForm operation={FormOperation.VIEW} businessId={businessId} unit={unit} callback={callback} />
        </MDModal>
    );
};

export default ViewUnit;
