import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

export const MDNotification: React.FC<{ content: string }> = ({ content }) => {
    return (
        <MDAlert color="secondary" dismissible>
            <MDTypography variant="body2" color="white">
                {content}
            </MDTypography>
        </MDAlert>
    );
};

export default MDNotification;
