import * as Yup from "yup";
import checkout from "layouts/account/schemas/form";
import { validateEmail, validatePhones } from "helpers/validations";
import { ROLES_ARRAY } from "types/roles";
import { useTranslation } from "react-i18next";



const {
    formField: {
        account: { name, role, center, email, phone },
    },
} = checkout;
//can i chose to use "common" for the translation key?


const useValidationSchema = () => {
    const { t } = useTranslation(["common"]);
  
    return {
      account: [
        Yup.object().shape({
          name: Yup.string().required(t(`error.${name.errorMsg}`)),
          role: Yup.string().oneOf(ROLES_ARRAY, t(`error.${role.errorMsg}`)).required(),
          center: Yup.object().shape({
            name: Yup.string().notOneOf(["Not Selected"], t(`error.${center.name.errorMsg}`)).required(),
          }),
          phone: Yup.object().shape({
            number: Yup.string()
              .required(t(`error.${phone.number.errorMsg}`))
              .test("phone-validation", t(`error.${phone.number.invalidMsg}`), function (value) {
                return validatePhones(value);
              }),
            countryCode: Yup.string().oneOf(["+1", "+886"], ""),
          }),
          email: Yup.string()
            .required(t(`error.${email.errorMsg}`))
            .test("email-validation", t(`error.${email.invalidMsg}`), function (value) {
              return validateEmail(value);
            }),
        }),
      ],
    };
  };


export default useValidationSchema;
