import { Response, default as http } from "http-common";
import { CenterWithAlerts } from "layouts/overview/center-page";
import { Center } from "types/setting-schema";

class CenterService {
    async getAllCenters(businessId: string): Promise<Response<Center[]>> {
        const response = await http.get<Response<Center[]>>(`/api/v1/businesses/${businessId}/centers`);
        return response.data;
    }

    async getAllCentersWithOnlineStatus(businessId: string): Promise<Response<CenterWithAlerts[]>> {
        const response = await http.post<Response<CenterWithAlerts[]>>(`/api/v1/centers//withOnlineResidents/${businessId}`);
        return response.data;
    }

    async getSingleCenter(centerId: string): Promise<Response<Center>> {
        const response = await http.get<Response<Center>>(`/api/v1/centers/${centerId}`);
        return response.data;
    }

    async updateSingleCenter(centerId: string, centerInfo: Center): Promise<Response<Center>> {
        const response = await http.put<Response<Center>>(`/api/v1/centers/${centerId}`, centerInfo);
        return response.data;
    }

    async createCenter(businessId: string, centerInfo: Center): Promise<Response<Center>> {
        const response = await http.post<Response<Center>>(`/api/v1/businesses/${businessId}/centers`, centerInfo);
        return response.data;
    }

    async deleteSingleCenter(centerId: string): Promise<Response<Center>> {
        const response = await http.delete<Response<Center>>(`/api/v1/centers/${centerId}`);
        return response.data;
    }
}

export default new CenterService();
