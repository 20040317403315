import { warn } from "console";
import { id } from "date-fns/locale";
import { duration } from "moment";

const form = {
    formId: "account-form",
    formField: {
        room: {
            resident:{
                name: "resident",
                type: "text",
                errorMsg: "Resident is required",
            },
            floor: {
                name: "floor",
                type: "text",
                errorMsg: "Floor is required",
            },
            number: {
                name: "number",
                type: "text",
                errorMsg: "Floor is required",
            },
            roomType: {
                name: "roomType",
                type: "text",
                errorMsg: "Room Type is required",
            },
        },
        alert: {
            vital: {
                alarm: {
                    name: "vitalAlarm",
                    type: "text",
                    errorMsg: "Vital Alarm is required",
                },
                notification: {
                    name: "vitalNotification",
                    type: "text",
                    errorMsg: "Vital Notification is required",
                },
                duration: {
                    name: "vitalDuration",
                    type: "text",
                    errorMsg: "Vital Duration is required",
                },
            },
            fall: {
                alarm: {
                    name: "fallAlarm",
                    type: "text",
                    errorMsg: "Vital Alarm is required",
                },
                notification: {
                    name: "fallNotification",
                    type: "text",
                    errorMsg: "Vital Notification is required",
                },
                delay: {
                    name: "fallDelay",
                    type: "text",
                    errorMsg: "Vital Duration is required",
                },
            },
            notInRoom: {
                alarm: {
                    name: "notInRoomAlarm",
                    type: "text",
                    errorMsg: "Vital Duration is required",
                },
                notification: {
                    name: "notInRoomNotification",
                    type: "text",
                    errorMsg: "Vital Duration is required",
                },
                timeRange: {
                    name: "notInRoom.timeRange",
                    type: "string",
                },
                st: {
                    name: "notInRoom.st",
                    type: "text",
                },
                et: {
                    name: "notInRoom.et",
                    type: "text",
                },
                sm: {
                    name: "notInRoom.sm",
                    type: "text",
                },
                em: {
                    name: "notInRoom.em",
                    type: "text",
                },
            },
            stayInRoom: {
                alarm: {
                    name: "stayInRoomAlarm",
                    type: "text",
                    errorMsg: "Vital Duration is required",
                },
                notification: {
                    name: "stayInRoomNotification",
                    type: "text",
                    errorMsg: "Vital Duration is required",
                },
                rooms: {
                    room: {
                        name: "room",
                        type: "text",
                    },
                    timeRange: {
                        name: "timeRange",
                        type: "string",
                    },
                },
            },
        },
        anderXDevice: {
            deviceNumber: {
                name: "deviceNumber",
                type: "text",
                errorMsg: "Device Number is required",
            },
            location: {
                name: "location",
                type: "text",
                errorMsg: "Location is required",
            },
            installLocation: {
                name: "installLocation",
                type: "text",
            },
        },
        residentInfo: {
            mom: {
                name: "mon",
                type: "text",
                errorMsg: "Name is required",
            },
            identityNumber: {
                name: "identityNumber",
                type: "text",
                errorMsg: "Identity Number is required",
            },
            sex: {
                name: "sex",
                type: "text",
            },
            birthday: {
                name: "birthday",
                type: "text",
            },
            height: {
                name: "height",
                type: "text",
            },
            weight: {
                name: "weight",
                type: "text",
            },
            checkInDate: {
                name: "checkInDate",
                type: "text",
                errorMsg: "Check In Date is required",
            },
            checkOutDate: {
                name: "checkOutDate",
                type: "text",
            },
            actionLevel: {
                name: "actionLevel",
                type: "text",
            },
            isFall: {
                name: "isFall",
                type: "text",
            },
            notice: {
                name: "notice",
                type: "text",
            },
            additionInfo: {
                name: "additionInfo",
                type: "text",
            },
            id: {
                name: "id",
                type: "text",
                errorMsg: "id is required",
            },
        },
    },
};

export default form;
