import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { BiometricsType } from "types/enums";
import {
    BLOOD_SUGAR_HIGH,
    BLOOD_SUGAR_LOW,
    DIABETES_BLOOD_SUGAR_HIGH,
    SYS_BLOOD_PRESSURE_HIGH,
    SYS_BLOOD_PRESSURE_LOW,
    DIA_BLOOD_PRESSURE_HIGH,
    DIA_BLOOD_PRESSURE_LOW,
    BLOOD_OXYGEN_LOW,
    PULSE_HIGH,
    PULSE_LOW,
    TEMPERATURE_HIGH,
    convertNumber,
} from "helpers/infoUtil";
// i18n
import { useTranslation } from "react-i18next";

interface Props {
    type: BiometricsType;
    data?: number;
    dataList?: number[];
    isToday?: boolean;
    diabetes?: boolean;
}

export const DataText: React.FC<Props> = ({ type, data, dataList, isToday, diabetes }) => {
    const dataSize = isToday ? "h1" : "subtitle2";
    const unitSize = isToday ? "h2" : "subtitle2";
    const lng = localStorage.getItem("i18nextLng");
    const { t: translate } = useTranslation(["common"]);

    if (data === 0 && dataList[0] === 0)
        return (
            <MDTypography variant={dataSize} color="lightBlue">
                N/A
            </MDTypography>
        );

    if (type === BiometricsType.BLOOD_PRESSURE) {
        return (
            <MDBox display="flex" alignItems="end">
                <MDTypography
                    variant={dataSize}
                    color={
                        dataList[0] >= SYS_BLOOD_PRESSURE_LOW && dataList[0] <= SYS_BLOOD_PRESSURE_HIGH
                            ? "text"
                            : "error"
                    }>
                    {dataList[0]}
                </MDTypography>
                <MDTypography variant={dataSize}>/</MDTypography>
                <MDTypography
                    variant={dataSize}
                    color={
                        dataList[1] >= DIA_BLOOD_PRESSURE_LOW && dataList[1] <= DIA_BLOOD_PRESSURE_HIGH
                            ? "text"
                            : "error"
                    }>
                    {dataList[1]}
                </MDTypography>
                <MDTypography variant={unitSize} fontWeight="regular">
                    {translate(`info.measurement.units.${type}`)}
                </MDTypography>
            </MDBox>
        );
    }

    let isNormal = false;
    switch (type) {
        case BiometricsType.BLOOD_SUGAR:
            const HIGH = diabetes ? DIABETES_BLOOD_SUGAR_HIGH : BLOOD_SUGAR_HIGH;
            if (lng === "tw") {
                if (data >= BLOOD_SUGAR_LOW * 14 && data <= HIGH * 14) isNormal = true;
            } else {
                if (data >= BLOOD_SUGAR_LOW && data <= HIGH) isNormal = true;
            }
            break;
        case BiometricsType.PULSE:
            if (data >= PULSE_LOW && data <= PULSE_HIGH) isNormal = true;
            break;
        case BiometricsType.TEMPERATURE:
            if (data < TEMPERATURE_HIGH) isNormal = true;
            break;
        case BiometricsType.BLOOD_OXYGEN:
            if (data > BLOOD_OXYGEN_LOW) isNormal = true;
            break;
        default:
            break;
    }

    return (
        <MDBox display="flex" alignItems="end">
            <MDTypography variant={dataSize} color={isNormal ? "text" : "error"}>
                {convertNumber(data, type)}
            </MDTypography>
            <MDTypography variant={unitSize} fontWeight="regular">
                {translate(`info.measurement.units.${type}`)}
            </MDTypography>
        </MDBox>
    );
};

DataText.defaultProps = {
    data: 0,
    dataList: [0],
    isToday: false,
};

export default DataText;
