import { Response, default as http } from "http-common";
import { SurveyData, SurveyTemplate } from "types/measurement-schema";

class SurveyService {
    async getAllSurveys(residentId: string): Promise<Response<SurveyData[]>> {
        const response = await http.get<Response<SurveyData[]>>(`/api/v1/residents/${residentId}/surveys`);
        return response.data;
    }

    async getSurveyTemplates(lang:string): Promise<Response<SurveyTemplate[]>> {
        const response = await http.get<Response<SurveyTemplate[]>>(`/api/v1/survey/template?lang=${lang}`);
        return response.data;
    }

    async createSurvey(surveyId: string, residentId: string): Promise<Response<SurveyData>> {
        const response = await http.post<Response<SurveyData>>(`/api/v1/survey/${surveyId}/resident/${residentId}`);
        return response.data;
    }
    async downloadSurvey(sid: string, id: string): Promise<Response<any>> {
        const response = await http.get<Response<SurveyData>>(`/api/v1/survey/download/${id}/${sid}`);
        return response.data;
    }

    async deleteSurvey(id: string): Promise<Response<any>> {
        const response = await http.delete<Response<any>>(`/api/v1/survey/${id}`);
        return response.data;
    }
}

export default new SurveyService();
