import React, { forwardRef, useCallback, useState, useEffect } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDModal from "components/MDModal";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isNotNil } from "helpers/utils";
import { Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { ToggleProp } from "types/state";
import { fileRead } from "helpers/formUtil";

interface ContainerProps {
    isDisplayMode: boolean;
    bgColor: string;
    children: JSX.Element;
}

const AvatarContainer: React.FC<ContainerProps> = ({ isDisplayMode, bgColor, children }) => {
    return isDisplayMode ? (
        <>
            <MDBox
                width="80px"
                height="80px"
                bgColor={bgColor}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: "50%", overflow: "hidden" }}>
                {children}
            </MDBox>
        </>
    ) : (
        <>
            <MDBox
                width="80px"
                height="80px"
                bgColor={bgColor}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: "50%", overflow: "hidden" }}>
                {children}
            </MDBox>
            <MDBox
                display="flex"
                alignItems="center"
                p={0.5}
                bottom={0}
                right={0}
                sx={{ backgroundColor: "#E4E9F3", borderRadius: "50%", position: "absolute" }}>
                <Icon>create</Icon>
            </MDBox>
        </>
    );
};

interface ImageProps {
    imageUrl?: any;
    defaultElement?: JSX.Element;
    bgColor: string;
    name: string;
    onChange?: (e: any) => void;
    isDisplayMode: boolean;
}

export const UploadableImage: React.FC<ImageProps> = ({
    imageUrl,
    defaultElement,
    bgColor,
    name,
    isDisplayMode,
    ...rest
}) => {
    const renderImage = () => {
        return isNotNil(imageUrl) && imageUrl.length > 0 ? (
            <MDAvatar src={imageUrl} alt="Uploaded File" size="xl" shadow="sm" />
        ) : (
            <>{defaultElement}</>
        );
    };

    return isDisplayMode ? (
        <>
            <AvatarContainer isDisplayMode={isDisplayMode} bgColor={bgColor}>
                {renderImage()}
            </AvatarContainer>
        </>
    ) : (
        <MDButton
            component="label"
            color="white"
            sx={{ borderRadius: "50%", padding: 0, cursor: "pointer", position: "relative" }}>
            <input name={name} accept="image/*" id="contained-button-file" type="file" hidden {...rest} />
            <AvatarContainer isDisplayMode={isDisplayMode} bgColor={bgColor}>
                {renderImage()}
            </AvatarContainer>
        </MDButton>
    );
};

interface DropzoneProps extends ToggleProp {
    imageUrl?: string[];
    uploadableFiles?: object[];
    name: string;
    handleOnChange?: (URLs: string[], files: object[]) => void;
    [key: string]: any;
}

export const UploadableDropzone: React.FC<DropzoneProps> = forwardRef(
    ({ imageUrl, uploadableFiles, name, control, setImageModal, handleOnChange, ...rest },ref) => {
        const [images, setImages] = useState<{ uploadableFiles: object[]; urls: string[] }>({
            uploadableFiles: uploadableFiles,
            urls: imageUrl,
        });
        const [error, seError] = useState<string | null>(null);

        useEffect(() => {
            if (images.uploadableFiles?.length + images.urls?.length < 4) seError(null);
            handleOnChange(images.urls, images.uploadableFiles);
        }, [images]);

        const onDrop = useCallback(
            (acceptedFiles: any) => {
                const length = images.uploadableFiles?.length + images.urls?.length;

                if (length < 4) {
                    setImages({
                        ...images,
                        uploadableFiles: [...images.uploadableFiles, ...acceptedFiles.slice(0, 4 - length)],
                    });
                }
                if (length + acceptedFiles.length > 4) {
                    seError("Each room with maximum 4pics");
                }
            },
            [images],
        );

        const { getRootProps, getInputProps } = useDropzone({
            multiple: true,
            accept: {
                "image/*": [],
            },
            onDrop,
            ...rest,
        });

        const handleRemoveFile = (file: any) => {
            if (typeof file === "string") {
                const newFiles = [...images.urls];
                newFiles.splice(newFiles.indexOf(file), 1);
                setImages({ ...images, urls: newFiles });
            } else {
                const newFiles = [...images.uploadableFiles];
                newFiles.splice(newFiles.indexOf(file), 1);
                setImages({ ...images, uploadableFiles: newFiles });
            }
        };

        const renderPreview = (files: string[] | object[]) => {
            return (
                files.length > 0 &&
                files.map((item: any, idx: number) => (
                    <Grid item key={idx} xs={2}>
                        <MDBox
                            sx={{
                                cursor: "pointer",
                                position: "relative",
                                "&:hover .MuiBox-root": { display: "flex" },
                            }}
                            onClick={() => handleRemoveFile(item)}>
                            <MDAvatar src={fileRead(item)} alt="Uploaded File" size="xl" shadow="sm" />
                            <MDBox
                                shadow="md"
                                display="none"
                                alignItems="center"
                                p={0.5}
                                sx={{
                                    position: "absolute",
                                    top: -10,
                                    right: -10,
                                    backgroundColor: "#E4E9F3",
                                    borderRadius: "50%",
                                }}>
                                <Icon sx={{}}>close</Icon>
                            </MDBox>
                        </MDBox>
                    </Grid>
                ))
            );
        };

        return (
            <MDModal type="image" setImageModal={setImageModal}>
                <MDBox bgColor="white" borderRadius="8px" px={2} pt={10} pb={2}>
                    <Controller
                        render={({ field: { onChange, ...field } }) => (
                            <>
                                <MDBox
                                    {...getRootProps()}
                                    bgColor="text"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius="8px"
                                    mb={1}
                                    sx={{ height: "200px", width: "100%", cursor: "pointer" }}>
                                    <input {...getInputProps({ onChange })} type="file" {...field} />
                                    <MDBox textAlign="center">
                                        <MDTypography color="white">
                                            Drag 'n' drop some files here, or click to select files
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            </>
                        )}
                        name={name}
                        control={control}
                    />
                    <Grid container columnSpacing={2} mt={3}>
                        {renderPreview(images.urls)}
                        {renderPreview(images.uploadableFiles)}
                    </Grid>
                    {isNotNil(error) && (
                        <MDBox>
                            <MDTypography color="error" textAlign="center">
                                {error}
                            </MDTypography>
                        </MDBox>
                    )}
                </MDBox>
            </MDModal>
        );
    },
);
