import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

interface Props {
    content: string;
    handleToggleModal: () => void;
    isDisabled?: boolean;
}

export const AddButton: React.FC<Props> = ({ content, handleToggleModal, isDisabled }) => {
    return (
        <MDButton onClick={handleToggleModal} circular={false} variant="gradient" disabled={isDisabled}>
            <MDTypography variant="subtitle1" color="white">
                {content}
            </MDTypography>
        </MDButton>
    );
};

export default AddButton;
