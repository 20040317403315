/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled, Theme } from "@mui/material/styles";
import zIndex from "@mui/material/styles/zIndex";

export default styled(Drawer)(({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
    const { palette, boxShadows, transitions, breakpoints, functions } = theme;
    const { miniSidenav } = ownerState;

    const sidebarWidth = 250;
    const { white } = palette;
    const { xxl } = boxShadows;
    const { pxToRem } = functions;

    // styles for the sidenav when miniSidenav={false}
    const drawerOpenStyles = () => ({
        background: white.main,
        transform: "translateX(0)",
        transition: transitions.create("transform", {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shorter,
        }),

        [breakpoints.up("lg")]: {
            boxShadow: xxl,
            left: "0",
            width: sidebarWidth,
            transform: "translateX(0)",
            transition: transitions.create(["width", "background-color"], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.enteringScreen,
            }),
        },
        [breakpoints.down("lg")]: {
            height:"100%",
            margin:"0",
            zIndex:"9999"
        },
    });

    // styles for the sidenav when miniSidenav={true}
    const drawerCloseStyles = () => ({
        background: white.main,
        transform: `translateX(${pxToRem(-320)})`,
        transition: transitions.create("transform", {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shorter,
        }),

        [breakpoints.up("lg")]: {
            boxShadow: xxl,
            left: "0",
            width: pxToRem(96),
            overflowX: "hidden",
            transform: "translateX(0)",
            transition: transitions.create(["width", "background-color"], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.shorter,
            }),
        },
        // [breakpoints.up("xs")]: {
        //     boxShadow: xxl,
        //     left: "0",
        //     width: pxToRem(96),
        //     overflowX: "hidden",
        //     transform: "translateX(0)",
        //     transition: transitions.create(["width", "background-color"], {
        //         easing: transitions.easing.sharp,
        //         duration: transitions.duration.shorter,
        //     }),
        //     // height:"100%"
        // },
    });

    return {
        "& .MuiDrawer-paper": {
            boxShadow: xxl,
            border: "none",
            zIndex: 30, // Set to 10 so that it won't overlay form (business/center) modal
            ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
        },
    };
});
