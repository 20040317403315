import { TimeRange } from "types/enums";
import { BiometricsType } from "types/enums";
import { isNotNil } from "./utils";
import { date } from "yup";

export const LAST_WEEK_DATE = `${new Date(Date.now() - 86400000 * 6).toJSON().slice(0, 10)}`;
export const LAST_TWO_WEEK_DATE = `${new Date(Date.now() - 86400000 * 13).toJSON().slice(0, 10)}`;
export const LAST_MONTH_DATE = `${new Date(Date.now() - 86400000 * 29).toJSON().slice(0, 10)}`;
export const LAST_YEAR_DATE = `${new Date(Date.now() - 86400000 * 364).toJSON().slice(0, 10)}`;
export const BLOOD_SUGAR_HIGH = 10;
export const BLOOD_SUGAR_LOW = 5;
export const DIABETES_BLOOD_SUGAR_HIGH = 12;
export const SYS_BLOOD_PRESSURE_HIGH = 140;
export const SYS_BLOOD_PRESSURE_LOW = 90;
export const DIA_BLOOD_PRESSURE_HIGH = 90;
export const DIA_BLOOD_PRESSURE_LOW = 60;
export const BLOOD_OXYGEN_LOW = 95;
export const PULSE_HIGH = 100;
export const PULSE_LOW = 50;
export const TEMPERATURE_HIGH = 37.8;
export const VALUE_COLORS = ["#E91F63", "#16C0E8", "#7D0FB1"]; // Sys blood pressure, dia blood pressure, others
export const RANGE_COLORS = ["#FF8484", "#3BAA4D", "#FF8484", "#398338"]; // High1, Low1, High2, Low2
export const NORMAL_RANGE = ["normalRangeHigh", "normalRangeLow"];
export const BLOOD_PRESSURE_NORMAL_RANGE = [
    "normalRangeHighSBP",
    "normalRangeHighDBP",
    "normalRangeLowSBP",
    "normalRangeLowDBP",
];

export const category = [
    BiometricsType.BLOOD_PRESSURE,
    BiometricsType.BLOOD_SUGAR,
    BiometricsType.PULSE,
    BiometricsType.BLOOD_OXYGEN,
    BiometricsType.TEMPERATURE,
];

export const timeRange = [TimeRange.WEEK, TimeRange.TWO_WEEK, TimeRange.MONTH, TimeRange.YEAR];

export const convertNumber = (number: number, type: BiometricsType) => {
    switch (type) {
        case BiometricsType.BLOOD_SUGAR:
        case BiometricsType.TEMPERATURE:
            return Math.round(number * 10) / 10;
        case BiometricsType.PULSE:
        case BiometricsType.BLOOD_PRESSURE:
        case BiometricsType.BLOOD_OXYGEN:
            return Math.round(number);
        default:
            return number;
    }
};

export const convertBloodSugar = (data: number) => {
    return localStorage.getItem("i18nextLng") === "tw" ? data * 14 : data;
};

export const dateStartBy = (dateType: string) => {
    let endDate: Date = new Date();
    switch (dateType) {
        case "week":
            return new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 6);

        case "twoWeek":
            return new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 13);

        case "month":
            return new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate() + 1);

        case "year":
            return new Date(endDate.getFullYear() - 1, endDate.getMonth(), endDate.getDate() + 1);

        default:
            return new Date();

    }
};

export const fillUpArray = (dateType: string, dataList: any[]) => {
    interface DataList {
        [key: string]: { total: number, count: number };
    }
    let startDate: Date = dateStartBy(dateType);
    let endDate: Date = new Date();
    let _dataList: DataList = {}
    switch (dateType) {
        case "week":
            startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 6);
            while (startDate <= endDate) {
                _dataList[startDate.toISOString().substring(0, 10)] = { total: 0, count: 0 };
                startDate.setDate(startDate.getDate() + 1);
            }
            break;

        case "twoWeek":
            startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 13);
            while (startDate <= endDate) {
                _dataList[startDate.toISOString().substring(0, 10)] = { total: 0, count: 0 };
                startDate.setDate(startDate.getDate() + 1);
            }
            break;

        case "month":
            startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate() + 1);
            while (startDate <= endDate) {
                _dataList[startDate.toISOString().substring(0, 10)] = { total: 0, count: 0 };
                startDate.setDate(startDate.getDate() + 1);
            }
            break;

        case "year":
            let i = 11;
            while (i >= 0) {
                _dataList[endDate.toISOString().substring(0, 7)] = { total: 0, count: 0 };
                endDate.setMonth(endDate.getMonth() - 1);
                i--;
            }
            break;

        default:
            startDate = new Date();
    }
    for (const data of dataList) {
        let { date, data: value } = data;
        console.log("dataList",dataList)
        if(dateType === "year"){
            date = date.slice(0, 7)
        }else{
            date = date.slice(0, 10)
        }
        console.log("date",_dataList,date)
        if (!_dataList[date]) {
            _dataList[date] = { total: 0, count: 0 };
        }
        if (value && value !== 0) {
            _dataList[date].total += value;
            _dataList[date].count++;
        }
    }
    const AvgArray = [];
    for (const key in _dataList) {
        const { total, count } = _dataList[key];
        const ratio = count !== 0 ? total / count : null;
        AvgArray.push(ratio);
    }
    if(dateType === "year"){
        return AvgArray.reverse()
    }
    console.log(AvgArray,AvgArray)
    return AvgArray
};


export const getRangeValue = (type: BiometricsType, lng?: string, diabetes?: boolean) => {
    switch (type) {
        case BiometricsType.BLOOD_SUGAR:
            const HIGH = diabetes ? DIABETES_BLOOD_SUGAR_HIGH : BLOOD_SUGAR_HIGH;
            if (lng === "tw") return [HIGH * 14, BLOOD_SUGAR_LOW * 14];
            return [HIGH, BLOOD_SUGAR_LOW];
        case BiometricsType.TEMPERATURE:
            return [TEMPERATURE_HIGH];
        case BiometricsType.PULSE:
            return [PULSE_HIGH, PULSE_LOW];
        case BiometricsType.BLOOD_PRESSURE:
            return [SYS_BLOOD_PRESSURE_HIGH, SYS_BLOOD_PRESSURE_LOW, DIA_BLOOD_PRESSURE_HIGH, DIA_BLOOD_PRESSURE_LOW];
        case BiometricsType.BLOOD_OXYGEN:
            return [0, BLOOD_OXYGEN_LOW];
        default:
            return [];
    }
};

export const getWeekdayRange = () => {
    const currentDay = new Date().getDay();
    let array = [...Array(7).keys()];

    // Generate the array starting from the next weekday and ending with the current weekday
    const nextWeekdayIndex = currentDay === 0 ? 7 : currentDay + 1;
    const daysArray = array.slice(nextWeekdayIndex).concat(array.slice(0, currentDay + 1));
    return daysArray;
};

export const getMonthRange = () => {
    const currentDay = new Date().getDate();
    const array = [...Array(30).keys()];

    // Generate the array starting from the next day of the month and ending with the current day
    const nextDayIndex = currentDay === 0 ? 30 : currentDay + 1;
    const daysArray = array.slice(nextDayIndex).concat(array.slice(0, currentDay + 1));
    return daysArray;
};

export const getYearRange = () => {
    const currentMonth = new Date().getMonth();
    let array = [...Array(12).keys()];

    // Generate the array starting from the next month and ending with the current month
    const nextMonthIndex = currentMonth === 0 ? 11 : currentMonth + 1;
    const monthsArray = array.slice(nextMonthIndex).concat(array.slice(0, currentMonth + 1));
    return monthsArray;
};

interface ListProps {
    inputDate: string;
    data: number;
}

const getDateFormat = (date: Date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()))
        .toJSON()
        .slice(0, 10);
};

export const getAverageByDate = (list: ListProps[], timeRange: TimeRange, type: BiometricsType): number[] => {
    const result: Record<string, { sum: number; count: number }> = {};
    const currentDate = new Date();

    switch (timeRange) {
        case TimeRange.WEEK:
            const aWeekAgo = new Date();
            aWeekAgo.setDate(currentDate.getDate() - 6);
            // Initialize the result object with 0 sum and count for each date in the week
            for (let date = aWeekAgo; date <= currentDate; date.setDate(date.getDate() + 1)) {
                result[getDateFormat(date)] = { sum: 0, count: 0 };
            }
            break;
        case TimeRange.TWO_WEEK:
            const twoWeeksAgo = new Date();
            twoWeeksAgo.setDate(currentDate.getDate() - 13);
            // Initialize the result object with 0 sum and count for each date in the 2 weeks
            for (let date = twoWeeksAgo; date <= currentDate; date.setDate(date.getDate() + 1)) {
                result[getDateFormat(date)] = { sum: 0, count: 0 };
            }
            break;
        case TimeRange.MONTH:
            const aMonthAgo = new Date();
            aMonthAgo.setDate(currentDate.getDate() - 29);
            // Initialize the result object with 0 sum and count for each date in the month
            for (let date = aMonthAgo; date <= currentDate; date.setDate(date.getDate() + 1)) {
                result[getDateFormat(date)] = { sum: 0, count: 0 };
            }
            break;
        case TimeRange.YEAR:
            const startDate = new Date();
            // Initialize the result object with 0 sum and count for each date in the year
            for (let i = 11; i >= 0; i--) {
                const date = new Date(startDate.getFullYear(), startDate.getMonth() + i).toJSON().slice(0, 7);
                result[date] = { sum: 0, count: 0 };
            }
            break;
        default:
            break;
    }

    // Calculate the sum and count of data values for each date
    for (const item of list) {
        const { inputDate, data: value } = item;
        const date = timeRange === TimeRange.YEAR ? inputDate.slice(0, 7) : inputDate.slice(0, 10);
        if (!result[date]) {
            result[date] = { sum: 0, count: 0 };
        }
        if (isNotNil(value) && value !== 0) {
            result[date].sum += value;
            result[date].count++;
        }
    }

    // Calculate the average for each date and format the result as an array of average data
    const averages: number[] = Object.values(result).map(({ sum, count }) =>
        count > 0 ? convertNumber(sum / count, type) : NaN,
    );
    return averages;
};

export const getAverageValue = (list: ListProps[][], type: BiometricsType) => {
    let filteredList = list.map((item) => item.filter((o) => o.data !== 0 && isNotNil(o.data)));
    return filteredList.map((item) => convertNumber(item.reduce((p, c) => p + c.data, 0) / item.length, type));
};

export const getHighestValue = (list: ListProps[][], type: BiometricsType) => {
    return list.map((item) => convertNumber(Math.max(...item.map((o) => o.data).filter(Boolean)), type));
};

export const getLowestValue = (list: ListProps[][], type: BiometricsType) => {
    // Get lowest number excluding 0
    return list.map((item) => convertNumber(Math.min(...item.map((o) => o.data).filter(Boolean)), type));
};
