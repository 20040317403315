import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Risk } from "types/setting-schema";
import { useTranslation } from "react-i18next";

interface Prop {
    risk: Risk;
}

export const RiskCard: React.FC<Prop> = ({ risk }) => {
    const { level, type } = risk;
    const { t: translate } = useTranslation(["common"]);
    let color = "secondary";
    if (level !== "N/A") color = "text";
    return (
        <Card sx={{ p: "10px", pb: 2, height: "100%", width: "100%" }}>
            <MDBox width="45px" mb={2}>
                <img src={require(`assets/images/icons/risks/${type}.svg`)} alt={type} />
            </MDBox>
            <MDTypography variant="subtitle2" fontWeight="light" mb={1} sx={{ fontSize: "14px", marginTop: "auto" }}>
                {translate(`info.measurement.risk.${type}`)}
            </MDTypography>
            <MDTypography color={color} fontWeight="medium" sx={{ marginTop: "auto" }}>
                {translate(`info.measurement.risk.level.${level}`)}
            </MDTypography>
        </Card>
    );
};

export default RiskCard;
