import { CMSResponse, CMSinstance as http } from "http-common";
import { HealthEdu } from "types/healthedu-schema";

class HealthEduService {
    async getHealthEdu(id: string): Promise<CMSResponse<HealthEdu>> {
      try {
        const response = await http.get<CMSResponse<HealthEdu>>(`health-educations/${id}?populate=risk_type,health_care_type,Media`);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }

export default new HealthEduService();
