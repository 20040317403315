import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import { handleRemove } from "helpers/formUtil";

// i18n
import { useTranslation } from "react-i18next";

interface Props {
    ary: any[];
    index: number;
}

// Remove Room, Remove Emergency Contact
export const RemoveArrayButton: React.FC<Props> = ({ ary, index }) => {
    const { t: translate } = useTranslation(["common"]);
    return (
        <MDButton
            variant="outlined"
            color="dark"
            type="button"
            sx={{
                px: 1.5,
            }}
            onClick={() => handleRemove(ary, index)}>
            <MDBox display="flex" alignItems="center">
                <Icon>delete</Icon>
                <MDTypography variant="span" lineHeight={1}>
                    {translate("general.button.delete")}
                </MDTypography>
            </MDBox>
        </MDButton>
    );
};

export default RemoveArrayButton;
