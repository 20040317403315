import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { ToggleProp } from "types/state";
import MDModal from "components/MDModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { fileRead } from "helpers/formUtil";

interface GalleryProps extends ToggleProp {
    imageUrl?: string[];
    uploadableFiles?: object[];
    roomType: string;
    status: string;
    index: number;
    [key: string]: any;
}

const SwipeableGallery: React.FC<GalleryProps> = ({
    imageUrl,
    uploadableFiles,
    roomType,
    status,
    index,
    setImageModal,
}) => {
    const [activeStep, setActiveStep] = React.useState(index);
    const maxSteps = imageUrl.length + uploadableFiles.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <MDModal type="image" setImageModal={setImageModal}>
            <MDBox bgColor="white" borderRadius="8px" p={2} sx={{ width: "100%" }}>
                <SwipeableViews axis="x" index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                    {[...imageUrl, ...uploadableFiles].map((item, index) => (
                        <MDBox
                            key={index}
                            height="100%"
                            maxHeight="70vh"
                            display="flex"
                            alignItems="center"
                            justifyContent="center">
                            {Math.abs(activeStep - index) <= 2 ? (
                                <MDBox
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                        height: "auto",
                                        display: "block",
                                        maxWidth: "90%",
                                        overflow: "hidden",
                                        width: "800px",
                                    }}>
                                    <img src={fileRead(item)} alt="Gallery" width="100%" height="100%" />
                                </MDBox>
                            ) : null}
                        </MDBox>
                    ))}
                </SwipeableViews>
                <MDBox px={2} pt={2}>
                    <MDTypography textAlign="center" color="lightBlue">
                        {roomType} / {status}
                    </MDTypography>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                Next
                                <KeyboardArrowRight />
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                <KeyboardArrowLeft />
                                Back
                            </Button>
                        }
                    />
                </MDBox>
            </MDBox>
        </MDModal>
    );
};

export default SwipeableGallery;
