/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, JSXElementConstructor, Key, ReactElement, Suspense } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React TS routes
import { iRoute, useAuthRoutes } from "routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";
import { AuthProvider, useAuth } from "context/userContext";

// Images
import brandLogo from "assets/images/logos/Wecare-logo.svg";
import { isNotNil } from "helpers/utils";

const RoutesComponent = () => {
    const routes = useAuthRoutes();
    const { currentUser } = useAuth();
    const getRoutes = (allRoutes: iRoute[]): any =>
        allRoutes.map((route) => {
            // Recursive routes
            if (isNotNil(route.collapse)) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route path={route.route} element={route.component} key={route.key} />;
            }

            return null;
        });
    const spsRoute = ()=>{
        if(currentUser?.role ==="installer")
            return <Route path="*" element={<Navigate to="/device-installation" />} />;
        else
            return <Route path="*" element={<Navigate to="/dashboard" />} />;
    }
    return (
        <Routes>
            {getRoutes(routes)}
            {spsRoute()}
        </Routes>
    );
};

const NavBar = ({
    handleOnMouseEnter,
    handleOnMouseLeave,
}: {
    handleOnMouseEnter: () => void;
    handleOnMouseLeave: () => void;
}) => {
    const routes = useAuthRoutes();
    return (
        <Sidenav
            brand={brandLogo}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
        />
    );
};

export default function App() {
    const [controller] = useMaterialUIController();
    const { miniSidenav, layout } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const { pathname } = useLocation();

    if (process.env.NODE_ENV === "production") {
        console.log = () => {};
    }

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setOnMouseEnter(false);
        }
    };

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    return (
        <Suspense fallback={null}>
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {layout === "dashboard" && (
                        <NavBar handleOnMouseEnter={handleOnMouseEnter} handleOnMouseLeave={handleOnMouseLeave} />
                    )}
                    <RoutesComponent />
                </ThemeProvider>
            </AuthProvider>
        </Suspense>
    );
}
