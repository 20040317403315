// Enum to contact these roles, so we don't have to use strings, 
export type Role = 'rel-admin' | 'business-admin' | 'center-admin' | 'center-staff' | 'user';
// Define enum for roles
export enum Roles {
	relAdmin = 'rel-admin',
	businessAdmin = 'business-admin',
	centerAdmin = 'center-admin',
	centerStaff = 'center-staff',
	user = 'user',
};

export const ROLES_ARRAY = Object.values(Roles);