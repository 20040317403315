import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { FormType } from "types/enums";
import { Business } from "types/setting-schema";
import NewCenter from "layouts/settings/business-page/newCenter";
import NewUnit from "layouts/settings/business-page/newUnit";
import NewResident from "layouts/settings/resident-page/newResident";

// i18n
import { useTranslation } from "react-i18next";
import { BusinessWithAlerts } from "../..";

export const BusinessCard: React.FC<BusinessWithAlerts> = ({ ...rest }) => {
    const navigate = useNavigate();
    const { name, address, phone, contact, numCenters, _id, todayAlerts, onlineResidents } = { ...rest };
    const { t: translate } = useTranslation(["common"]);

    // Navigate to create center or unit form with business id
    const handleNavigate = (id: string) => {
        navigate(`/overview/overview-center`, {
            state: {
                businessId: id,
            },
        });
    };

    return (
        <>
            <Grid item xs={12} sm={6} md={4} xl={3}>
                <MDBox
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    px={3}
                    py={4}
                    bgColor="white"
                    sx={{
                        position: "relative",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "13px",
                        boxShadow: "0px 2px 6px 0px #00000026",
                    }}>
                    <MDBox
                        width="100%"
                        height="100%"
                        top="0"
                        right="0"
                        sx={{ cursor: "pointer", position: "absolute" }}
                        onClick={() => handleNavigate(_id)}></MDBox>
                    <MDBox pb={2} display="flex" flexDirection="column" height="100%">
                        <MDBox mb={3}>
                            <MDTypography sx={{ color: "#5D5A88", fontWight: "bold", fontSize: "22px" }}>
                                {name.length > 25 ? `${name.substring(0, 25)}...` : name}
                            </MDTypography>
                        </MDBox>
                    </MDBox>

                    <MDBox mt="auto" display="flex" justifyContent="space-around">
                        <MDBox display="flex" flexDirection="column" alignItems="center">
                            <MDTypography sx={{ fontSize: "16px", fontWight: "bold" }} color="text">
                                {translate("info.realTimeInfo.activeAlert")}
                            </MDTypography>
                            <MDTypography sx={{ fontSize: "30px", fontWight: "bold", paddingTop: "10px" }} color="text">
                                {todayAlerts ? todayAlerts.length : 0}
                            </MDTypography>
                        </MDBox>
                        <MDBox display="flex" flexDirection="column" alignItems="center">
                            <MDTypography sx={{ fontSize: "16px", fontWight: "bold" }} color="text">
                                {translate("info.realTimeInfo.resident")}
                            </MDTypography>
                            <MDTypography sx={{ fontSize: "30px", fontWight: "bold", paddingTop: "10px" }} color="text">
                                {onlineResidents}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Grid>
        </>
    );
};

export default BusinessCard;
