// Setting layout components
import MDModal from "components/MDModal";

// Type
import { ToggleProp } from "types/state";
import { splitByComma } from "helpers/utils";
import MDBox from "components/MDBox";
import { Icon } from "@mui/material";
import SurveyService from "services/survey.service";
interface Props extends ToggleProp {
    data: string;
    sid: string;
    id: string;
}

export const ViewSurvey: React.FC<Props> = (props) => {
    const { data, sid, id, setToggleModal } = props;
    const result: string[] = splitByComma(data);
    const handleDownload = async () => {
        const url = `https://www.jotform.com/server.php?action=getSubmissionPDF&sid=${sid}&formID=${id}&apikey=8eb6f052400a048bd224f94d0f34e5be`;
        window.open(url, "_blank");
    };
    return (
        <MDModal isDownload={handleDownload} type="form" setToggleModal={setToggleModal}>
            <MDBox
                sx={{ overflowY: { xs: "auto" }, maxHeight: "90vh" }}
                bgColor="white"
                borderRadius="8px"
                px={2}
                pt={10}
                pb={2}>
                {result.map((data, i) => {
                    let datas = data.split(":");
                    return (
                        <MDBox
                            display={"flex"}
                            sx={{ paddingTop: "10px", paddingBottom: "10px", alignItems: "center" }}>
                            <MDBox sx={{ fontWeight: "bolder", width: "250px" }}>{datas[0]}</MDBox>
                            {i > 4 ? (
                                <MDBox
                                    sx={{
                                        marginLeft: "20px",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        background: "#E3E8F3",
                                    }}>
                                    {datas[1]}
                                </MDBox>
                            ) : (
                                <MDBox sx={{ marginLeft: "20px", padding: "5px", borderRadius: "5px" }}>
                                    {datas[1]}
                                </MDBox>
                            )}
                        </MDBox>
                    );
                })}
            </MDBox>
        </MDModal>
    );
};

export default ViewSurvey;
