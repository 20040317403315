const form = {
    formId: "info-form",
    formField: {
        measurement: {
            systolicBloodPressure: {
                name: "systolicBloodPressure",
                type: "number",
            },
            diastolicBloodPressure: {
                name: "diastolicBloodPressure",
                type: "number",
            },
            pulse: {
                name: "pulse",
                type: "number",
            },
            bloodOxygen: {
                name: "bloodOxygen",
                type: "number",
            },
            bloodSugar: {
                condition: {
                    name: "bloodSugar.condition",
                    type: "text",
                },
                level: {
                    name: "bloodSugar.level",
                    type: "number",
                },
            },
            height: {
                name: "height",
                type: "number",
            },
            weight: {
                name: "weight",
                type: "number",
            },
            bmi: {
                name: "bmi",
                type: "number",
            },
            fat: {
                name: "fat",
                type: "number",
            },
            waistline: {
                name: "waistline",
                type: "number",
            },
            temperature: {
                name: "temperature",
                type: "number",
            },
            inputDate: {
                name: "inputDate",
                type: "date",
            },
        },
    },
};

export default form;
