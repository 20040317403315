import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { FormOperation } from "types/enums";
import { DeleteConfirmProp } from "types/state";

// i18n
import { useTranslation } from "react-i18next";

interface Props extends DeleteConfirmProp {
    operation?: FormOperation;
    disable: boolean;
}

export const SubmitButtons: React.FC<Props> = ({ disable, operation, setDeleteConfirm }) => {
    const { t: translate } = useTranslation(["common"]);
    if (operation === FormOperation.CREATE) {
        return (
            <MDBox display="flex" justifyContent="end" mt={2} width="100%">
                {/* disable button if is submitting, contains errors or is not dirty*/}
                <MDButton disabled={disable} type="submit" circular={false} variant="gradient">
                    {translate(`general.button.${operation}`)}
                </MDButton>
            </MDBox>
        );
    } else if (operation === FormOperation.UPDATE) {
        return (
            <MDBox display="flex" justifyContent="space-between" mt={2} width="100%">
                <MDButton
                    type="button"
                    variant="outlined"
                    circular={false}
                    color="error"
                    onClick={() => setDeleteConfirm(true)}>
                    {translate(`general.button.delete`)}
                </MDButton>
                <MDButton disabled={disable} type="submit" circular={false} variant="gradient">
                    {translate(`general.button.${operation}`)}
                </MDButton>
            </MDBox>
        );
    }
    return <></>;
};

SubmitButtons.defaultProps = {
    operation: FormOperation.CREATE,
};

export default SubmitButtons;
