// Setting layout components
import MDModal from "components/MDModal";
import { BusinessForm } from "layouts/settings/components/Forms/BusinessForm";

// Type
import { FormOperation, FormType } from "types/enums";
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    callback: (updated: boolean, type?: FormType) => void;
}

export const NewBusiness: React.FC<Props> = ({ setToggleModal, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <BusinessForm operation={FormOperation.CREATE} callback={callback} />
        </MDModal>
    );
};

export default NewBusiness;
