import MDBox from "components/MDBox";
import { UserForm } from "./Form";

export const NewRoom = () => {

    return (
        <MDBox>
            <UserForm/>
        </MDBox>
    );
};


export default NewRoom;