import { Theme } from "@mui/material/styles";

export const categoryBar = (theme: Theme, ownerState: any) => {
    const { palette } = theme;
    const { activeCategory, item } = ownerState;

    const { primary } = palette;

    return {
        display: "inline-block",
        color: activeCategory === item ? primary.main : primary.dark,
        fontWeight: activeCategory === item ? "bold" : "regular",
        p: 1,
        mx: 2,
        borderBottom: () => {
            let value;
            if (activeCategory === item) value = `1px solid ${primary.main}`;
            return value;
        },
        cursor: "pointer",
    };
};

export const timeRangeBar = (theme: Theme, ownerState: any) => {
    const { activeTimeRange, item } = ownerState;

    return {
        display: "inline-block",
        fontWeight: "medium",
        opacity: activeTimeRange === item ? "1" : "0.4",
        mx: 2,
        cursor: "pointer",
    };
};
