import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export const FormTitle: React.FC<{ content: string }> = ({ content }) => {
    return (
        <MDBox lineHeight={0} mb={3}>
            <MDBox display="flex" alignItems="center" justifyContent="space-between">
                <MDTypography variant="h2" color="lightBlue">
                    {content}
                </MDTypography>
            </MDBox>
        </MDBox>
    );
};

export default FormTitle;
