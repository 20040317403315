export const globalStyle = {
    flex: {
        horizon: {
            display: "flex",
            justifyContent: "center",
        },
        vertical: {
            display: "flex",
            alignItems: "center",
        },
        center: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    cursorPointer: {
        cursor: "pointer",
    },
    
};
