import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// formik components
import { Formik, Form } from "formik";

// New settings layout schemas for form and form fields
import form from "layouts/settings/schemas/form";
import useValidationSchema from "layouts/settings/schemas/validations/center";

// Components
import MDBox from "components/MDBox";
import MDErrorMsg from "components/MDErrorMsg";
import { FormField, FormRadio } from "components/MDFormField";
import { PhoneField } from "components/MDFormField/phoneField";
import SubmitButtons from "components/MDButton/submitButton";
import EditButton from "components/MDButton/editButton";
import DeleteConfirmPop from "components/MDFormField/deleteConfirm";
import FormLayout from "examples/LayoutContainers/FormLayout";

// Service
import CenterService from "services/center.service";

// Types
import { FormOperation, FormType } from "types/enums";
import { Center } from "types/setting-schema";

// Helpers
import { isEditDisabled, getFormKeys, getCountryCode } from "helpers/formUtil";
import { isNotNil } from "helpers/utils";

// i18n
import { useTranslation } from "react-i18next";

interface Props {
    operation: FormOperation;
    businessId?: string;
    center?: Center;
    callback: (updated: boolean, isDelete?: boolean) => void;
}

export const CenterForm: React.FC<Props> = ({ operation: ops, businessId, center: initCenter, callback }) => {
    const navigate = useNavigate();
    const [operation, setOperation] = useState<FormOperation>(ops);
    const { formId, formField } = form;
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { name, address, contact, phone, description, isCentralized } = formField.center;
    const { t: translate } = useTranslation(["common"]);
    const validations = useValidationSchema();
    const defaultCenter: Center = {
        name: "",
        description: "",
        phone: {
            countryCode: getCountryCode(translate("lang")),
            number: "",
        },
        contact: "",
        address: "",
        isCentralized: false,
    };

    const handleResult = (res: any, isDelete?: boolean) => {
        if (res.success) {
            if (isNotNil(callback)) {
                callback(true, isDelete);
            } else {
                navigate("/dashboard");
            }
        } else {
            setError(res.error);
        }
    };

    const submitForm = async (values: any, actions: any) => {
        setError(null);
        let res;
        if (operation === FormOperation.CREATE) {
            res = await handleCreateCenter(businessId, values);
        } else {
            res = await handleUpdateCenter(values);
        }

        handleResult(res);

        actions.setSubmitting(false);
    };

    const handleCreateCenter = async (businessId: string, center: Center) => {
        return CenterService.createCenter(businessId, center);
    };

    const handleUpdateCenter = async (center: Center) => {
        const { _id } = initCenter;
        return CenterService.updateSingleCenter(_id, center);
    };

    const handleDeleteCenter = async () => {
        const { _id } = initCenter;
        const res = await CenterService.deleteSingleCenter(_id);
        handleResult(res, true);
    };

    return (
        <>
            {deleteConfirm && (
                <DeleteConfirmPop
                    title={`${translate("general.popup.delete")}`}
                    handleDelete={handleDeleteCenter}
                    setDeleteConfirm={setDeleteConfirm}
                />
            )}
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{ ...defaultCenter, ...initCenter }}
                        validationSchema={validations.center[0]}
                        onSubmit={submitForm}>
                        {({ values, errors, touched, dirty, setFieldValue, handleChange, isSubmitting }) => (
                            <Form id={formId} autoComplete="off" noValidate>
                                <FormLayout
                                    header={` ${translate(`general.operation.${operation}`)}${
                                        operation === FormOperation.CREATE ? translate(`general.state.new`) : ""
                                    }${translate(`general.type.${FormType.CENTER}`)}`}
                                    name={operation !== FormOperation.CREATE ? initCenter?.name : ""}>
                                    {operation === FormOperation.VIEW && <EditButton setOperation={setOperation} />}
                                    <MDBox mt={1.625}>
                                        <Grid container spacing={3} mb={3}>
                                            <Grid item xs={12} sm={6}>
                                                <FormField
                                                    {...getFormKeys(name, translate(`setting.center.name`))}
                                                    value={values.name}
                                                    error={errors.name && touched.name}
                                                    success={values.name.length > 0 && !errors.name}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} mb={3}>
                                            <Grid item xs={12}>
                                                <FormRadio
                                                    {...getFormKeys(
                                                        isCentralized,
                                                        translate(`setting.center.centralized`),
                                                    )}
                                                    options={[
                                                        translate(`setting.center.remote`),
                                                        translate(`setting.center.centralized`),
                                                    ]}
                                                    // If is editable, value = value(boolean). Otherwise, display localized value
                                                    value={
                                                        !isEditDisabled(operation)
                                                            ? values.isCentralized
                                                            : values.isCentralized
                                                            ? translate(`setting.center.centralized`)
                                                            : translate(`setting.center.remote`)
                                                    }
                                                    onChange={() => {
                                                        setFieldValue("isCentralized", !values.isCentralized);
                                                        setFieldValue("address", "");
                                                    }}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                        </Grid>
                                        {values.isCentralized && (
                                            <Grid container spacing={3} mb={3}>
                                                <Grid item xs={12}>
                                                    <FormField
                                                        {...getFormKeys(address, translate(`setting.center.address`))}
                                                        value={values.address}
                                                        error={errors.address && touched.address}
                                                        success={values.address.length > 0 && !errors.address}
                                                        isDisplayMode={isEditDisabled(operation)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid container spacing={3} mb={3}>
                                            <Grid item xs={12} sm={6}>
                                                <FormField
                                                    {...getFormKeys(contact, translate(`setting.center.contact`))}
                                                    value={values.contact}
                                                    error={errors.contact && touched.contact}
                                                    success={values.contact.length > 0 && !errors.contact}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                            <PhoneField
                                                {...{
                                                    phoneValue: values.phone,
                                                    errors,
                                                    touched,
                                                    field: phone,
                                                    handleChange,
                                                    label: translate(`setting.center.phone`),
                                                    isEditDisabled: isEditDisabled(operation),
                                                }}
                                            />
                                        </Grid>
                                        <Grid container mb={3}>
                                            <Grid item xs={12}>
                                                <FormField
                                                    {...getFormKeys(
                                                        description,
                                                        translate(`setting.center.description`),
                                                    )}
                                                    value={
                                                        isEditDisabled(operation) && values.description.length === 0
                                                            ? "None"
                                                            : values.description
                                                    }
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                    {isNotNil(error) && <MDErrorMsg errorMsg={error} />}
                                    <SubmitButtons
                                        disable={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                                        operation={operation}
                                        setDeleteConfirm={setDeleteConfirm}
                                    />
                                </FormLayout>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    );
};
