import * as Yup from "yup";
import checkout from "layouts/settings/schemas/form";
import { validatePhones } from "helpers/validations";
import { useTranslation } from "react-i18next";

const {
    formField: {
        center: { name, address, contact, phone },
    },
} = checkout;

const useValidationSchema = () => {
    const { t } = useTranslation(["common"]);

    return {
        center: [
            Yup.object().shape({
                name: Yup.string().required(t(`error.${name.errorMsg}`)),
                // Address is required only when isCentralized is true
                address: Yup.string().when("isCentralized", {
                    is: true,
                    then: Yup.string().required(t(`error.${address.errorMsg}`))
                }),
                contact: Yup.string().required(t(`error.${contact.errorMsg}`)),
                description: Yup.string(),
                // Nested Object
                phone: Yup.object().shape({
                    number: Yup.string()
                        .required(t(`error.${phone.number.errorMsg}`))
                        .test("phone-validation", t(`error.${phone.number.invalidMsg}`), function (value) {
                            return validatePhones(value);
                        }),
                    countryCode: Yup.string().oneOf(["+1", "+886"], ""),
                }),
                isCentralized: Yup.boolean(),
            }),
        ],
    };
};

export default useValidationSchema;
