import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import { ResidentCardProps } from "layouts/info/schemas/card-schema";
import { getBirthday } from "helpers/formUtil";
import { isNotNil, calculateAge } from "helpers/utils";
import defaultAvatar from "assets/images/defaultAvatar.svg";

export const ResidentCard: React.FC<ResidentCardProps> = ({
    avatarUrl,
    name,
    gender,
    dateOfBirth,
    center,
    address,
}) => {
    const birthday = getBirthday(dateOfBirth);
    const { year, month, day } = birthday;

    const avatarComponent = () => {
        if (isNotNil(avatarUrl)) return <MDAvatar src={avatarUrl} alt="avatar" size="xl" shadow="sm" />;
        return <MDAvatar src={defaultAvatar} alt="Default Svg" size="xl" shadow="sm" />;
    };
    return (
        <MDBox sx={{ width: { xs: "100%", sm: "60%", lg: "40%" }, mx: { xs: 0, md: 2 } }}>
            <Grid container p={2} rowSpacing={2}>
                <Grid item xs={4.5}>
                    {avatarComponent()}
                </Grid>
                <Grid item xs={7.5} alignSelf="center">
                    <Grid container alignItems="center">
                        <Grid item xs={12} mb={1}>
                            <MDTypography variant="h2">{name}</MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                            <MDTypography variant="h4" fontWeight="regular" color="lightBlue">
                                {gender.charAt(0).toUpperCase() + gender.slice(1)}
                            </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                            <MDTypography variant="h4" fontWeight="regular" color="lightBlue">
                                {calculateAge(year, month, day)}
                            </MDTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <MDTypography variant="h4" fontWeight="regular" color="lightBlue">
                                {center}
                            </MDTypography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MDBox display="flex">
                        <Icon color="warning" sx={{ mt: -0.5, mr: 1 }}>
                            home
                        </Icon>
                        <MDTypography variant="subtitle2" color="lightBlue">
                            {address}
                        </MDTypography>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
};

export default ResidentCard;
