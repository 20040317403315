import * as Yup from "yup";
import checkout from "./form";
import { validateEmail, validatePhones } from "helpers/validations";
import { ROLES_ARRAY } from "types/roles";
import { useTranslation } from "react-i18next";

const {
    formField: { room, alert, anderXDevice, residentInfo },
} = checkout;
//can i chose to use "common" for the translation key?

const useValidationSchema = () => {
    const { t } = useTranslation(["common"]);

    return {
        account: [
            //step 1
            Yup.object().shape({
              floor: Yup.string().required(t(`error.${room.floor.errorMsg}`)),
              number: Yup.string().required(t(`error.${room.number.errorMsg}`)),
              roomType: Yup.string().required(t(`error.${room.roomType.errorMsg}`)),
              checkInDate: Yup.string().required(t(`error.Check In date is required`)),
              deviceId: Yup.string().required(t(`error.Device number is required`)),
              devices: Yup.array().of(
                Yup.object().shape({
                  deviceNumber: Yup.string().required(t(`error.Device number is required`)),
                  location: Yup.string().required(t(`error.Location is required`)),
                  installLocation: Yup.string(),
                })
              ),
            }),
            //step 2
        ],
    };
};

export default useValidationSchema;
