import { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import FormLayout from "examples/LayoutContainers/FormLayout";
import MDSkeleton from "components/MDSkeleton";
import { isNil } from "helpers/utils";
import QRCode from "react-qr-code";
// i18n
import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

interface Props {
    url: string;
}

export const QRCodeForm: React.FC<Props> = ({ url }) => {
    const [copied, setCopied] = useState(false);
    const { t: translate } = useTranslation(["common"]);

    const copyURL = (url: string) => {
        navigator.clipboard.writeText(url);
        setCopied(true);
    };

    if (isNil(url)) return <MDSkeleton />;

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <Grid item xs={12}>
                <FormLayout header={translate("info.survey.qrcode.title")}>
                    <Grid container justifyContent="space-around">
                        <Grid item textAlign="center" xs={4} md={3}>
                            <QRCode style={{ height: "auto", width: "80%" }} value={url} />
                            <MDTypography mt={1}>{translate("info.survey.qrcode.scan")}</MDTypography>
                        </Grid>
                        <Grid item xs={6} alignSelf="center">
                            <Card
                                sx={{ p: 1, backgroundColor: "#f5f5f5", cursor: "pointer" }}
                                onClick={() => copyURL(url)}>
                                <MDTypography variant="subtitle1">{url}</MDTypography>
                            </Card>
                            <MDBox display="flex" alignItems="center" justifyContent="center" mt={1}>
                                <MDTypography display="inline" sx={{ cursor: "pointer" }} onClick={() => copyURL(url)}>
                                    {translate("info.survey.qrcode.copy")}
                                </MDTypography>
                                {copied && <Icon fontSize="medium">check</Icon>}
                            </MDBox>
                        </Grid>
                    </Grid>
                </FormLayout>
            </Grid>
        </Grid>
    );
};

export default QRCodeForm;
