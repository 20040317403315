/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useCallback, useMemo, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Menu, Icon, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MDBox from "components/MDBox";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import {
    smnavbar,
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import { useMaterialUIController, setMiniSidenav, setTransparentNavbar } from "context";
import { useAuth } from "context/userContext";
import authService from "services/auth.service";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import brandLogo from "assets/images/logos/Wecare-logo.svg";
import { throttle } from "lodash";

// Declaring prop types for DashboardNavbar
interface Props {
    isMini?: boolean;
}

export const DashboardNavbar: React.FC<Props> = React.memo(({ isMini = false }) => {
    const [navbarType, setNavbarType] = useState<"sticky" | "static">("static");
    const { currentUser, setCurrentUser } = useAuth();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, fixedNavbar } = controller;
    const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
    const [isTransparent, setIsTransparent] = useState(true);
    const pathname = useParams();
    const route = useLocation().pathname.split("/").slice(1);
    const navigate = useNavigate();
    const { t: translate } = useTranslation(["common"]);
    const theme = useTheme();
    const onlySmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
    console.log("onlySmallScreen",onlySmallScreen)
    const handleTransparentNavbar = useCallback(() => {
        setIsTransparent((fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }, [fixedNavbar]);

    const throttledHandleTransparentNavbar = useMemo(
        () => throttle(handleTransparentNavbar, 200),
        [handleTransparentNavbar],
    );

    useEffect(() => {
        setNavbarType(fixedNavbar ? "sticky" : "static");

        const handleScroll = () => {
            requestAnimationFrame(throttledHandleTransparentNavbar);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
            throttledHandleTransparentNavbar.cancel();
        };
    }, [fixedNavbar, throttledHandleTransparentNavbar]);

    useEffect(() => {
        setTransparentNavbar(dispatch, isTransparent);
    }, [dispatch, isTransparent]);

    const handleMiniSidenav = useCallback(() => setMiniSidenav(dispatch, !miniSidenav), [dispatch, miniSidenav]);
    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => setOpenMenu(event.currentTarget), []);
    const handleCloseMenu = useCallback(() => setOpenMenu(null), []);
    const handleLogOut = useCallback(() => {
        authService.logout();
        setCurrentUser(null);
        navigate("/sign-in");
    }, [setCurrentUser, navigate]);

    const handleNavigateChangePassword = useCallback(() => {
        navigate("/change-password");
    }, [navigate]);

    const renderMenu = useMemo(
        () => (
            <Menu
                anchorEl={openMenu}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={Boolean(openMenu)}
                onClose={handleCloseMenu}
                sx={{ mt: 2 }}>
                <NotificationItem
                    icon={<Icon>password</Icon>}
                    title={translate("auth.changePassword")}
                    onClick={handleNavigateChangePassword}
                />
                <NotificationItem icon={<Icon>logout</Icon>} title={translate("auth.logout")} onClick={handleLogOut} />
            </Menu>
        ),
        [openMenu, handleCloseMenu, translate, handleNavigateChangePassword, handleLogOut],
    );

    const navbarContent = useMemo(
        () => (
            <Toolbar sx={navbarContainer}>
                {!onlySmallScreen &&(<MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                    <Breadcrumbs
                        title={Object.keys(pathname).length === 0 ? route[route.length - 1] : pathname.name}
                        route={route}
                    />
                </MDBox>)}
                {!isMini && (
                    <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                        <MDBox
                            display="flex"
                            flexDirection="row-reverse"
                            alignItems="center"
                            width="100%"
                            justifyContent="space-between">
                            <MDBox display="flex" alignItems="center">
                                <IconButton sx={navbarIconButton} disableRipple onClick={handleOpenMenu}>
                                    <Icon>account_circle</Icon>
                                </IconButton>
                                {!onlySmallScreen && (
                                    <MDTypography variant="subtitle1">{currentUser?.name}</MDTypography>
                                )}
                            </MDBox>
                            {renderMenu}
                            <MDBox display={{ xs: "flex", lg: "none" }}>
                                <IconButton
                                    size="medium"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarMobileMenu}
                                    onClick={handleMiniSidenav}>
                                    <Icon fontSize="large">{miniSidenav ? "menu" : "menu_open"}</Icon>
                                </IconButton>
                                <MDBox
                                    component="img"
                                    src={brandLogo}
                                    alt="Brand"
                                    width="9rem"
                                    sx={{
                                        transition: "0.2s linear",
                                        paddingLeft: "0.5rem",
                                    }}
                                />
                            </MDBox>
                        </MDBox>
                    </MDBox>
                )}
            </Toolbar>
        ),
        [
            isMini,
            handleOpenMenu,
            renderMenu,
            handleMiniSidenav,
            brandLogo,
            currentUser?.name,
            miniSidenav,
            onlySmallScreen,
        ],
    );

    if (onlySmallScreen) {
        return (
            <Fragment>
                <AppBar position={"fixed"} color="inherit" sx={(theme) => smnavbar(theme, {})}>
                    {navbarContent}
                </AppBar>
                <MDBox
                    color="inherit"
                    mb={{ xs: 1, md: 0 }}
                    sx={(theme) => navbarRow(theme, { isMini })}
                    paddingTop={"88px"}>
                    <Breadcrumbs
                        title={Object.keys(pathname).length === 0 ? route[route.length - 1] : pathname.name}
                        route={route}
                    />
                </MDBox>
            </Fragment>
        );
    }

    return (
        <AppBar
            position={navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, { transparentNavbar: isTransparent })}>
            {navbarContent}
        </AppBar>
    );
});

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
    isMini: false,
};

export default DashboardNavbar;
