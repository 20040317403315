import { FC, forwardRef } from "react";

// Custom styles for MDProgress
import MDBox from "components/MDBox";
import MDCircularProgressRoot from "components/MDCircularProgress/MDCircularProgressRoot";

// Declare props types for MDProgress
interface Props {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
    [key: string]: any;
}

const MDCircularProgress: FC<Props> = forwardRef(({ color }, ref) => (
    <MDBox sx={{ height: "300px", width: "100%" }} display="flex" justifyContent="center" alignItems="center">
        <MDCircularProgressRoot ref={ref} ownerState={{ color }} />
    </MDBox>
));

// Declaring default props for MDCircularProgress
MDCircularProgress.defaultProps = {
    color: "primary",
};

export default MDCircularProgress;
