import { useState, useEffect } from "react";
import JotformEmbed from "react-jotform-embed";
import { HealthData } from "types/measurement-schema";
import FormLayout from "examples/LayoutContainers/FormLayout";
import { isNil, isNotNil, calculateAge } from "helpers/utils";
import { getBirthday } from "helpers/formUtil";
import BiometricsService from "services/biometrics.service";
import MDCircularProgress from "components/MDCircularProgress";
// i18n
import { useTranslation } from "react-i18next";

interface Props {
    formId: string;
    url: string;
    residentInfo: {
        id: string;
        gender: string;
        name: string;
        dateOfBirth: string;
        preferredLanguage: string;
    };
    callback: (updated: boolean) => void;
}

const getTodaysDate = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return { d: day, m: month, y: year };
};

export const Jotform: React.FC<Props> = ({ formId, url, residentInfo, callback }) => {
    const [biometricsList, setBiometricsList] = useState<HealthData[] | null>(null);
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        const fetchBiometrics = async () => {
            const res = await BiometricsService.getAllBiometricsByResident(residentInfo.id);

            if (res.success) {
                setBiometricsList(res.data);
            }
        };
        fetchBiometrics();
    }, []);

    useEffect(() => {
        const handleMessage = (event: any) => {
            // Check if the message is from the iframe
            if (event.origin === "https://submit.jotform.com") {
                if (isNotNil(callback)) {
                    callback(true);
                }
            }
        };

        // Add event listener for message events
        window.addEventListener("message", handleMessage);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    const getURL = () => {
        const { d, m, y } = getTodaysDate();
        const { dateOfBirth, id, name, gender } = residentInfo;
        const birthday = getBirthday(dateOfBirth);
        const { year, month, day } = birthday;
        const rName = name.split(" ");
        const URLWithParams = new URL(url);

        URLWithParams.searchParams.append("todaysDate[day]", d.toString());
        URLWithParams.searchParams.append("todaysDate[month]", m.toString());
        URLWithParams.searchParams.append("todaysDate[year]", y.toString());
        URLWithParams.searchParams.append("firstName", rName[0]);
        URLWithParams.searchParams.append("lastName", rName.length > 1 ? rName[rName.length - 1] : "");
        URLWithParams.searchParams.append("age", calculateAge(year, month, day).toString());
        URLWithParams.searchParams.append(
            "gender",
            translate(`setting.resident.${gender}`, { lng: residentInfo.preferredLanguage }),
        );
        URLWithParams.searchParams.append(
            "height",
            isNotNil(biometricsList) && biometricsList.length > 0 ? biometricsList[0].height.toString() : "",
        );
        URLWithParams.searchParams.append(
            "weight",
            isNotNil(biometricsList) && biometricsList.length > 0 ? biometricsList[0].weight.toString() : "",
        );
        URLWithParams.searchParams.append("resident", id);
        URLWithParams.searchParams.append("formId", formId);
        URLWithParams.searchParams.append("residentId", id);
        return URLWithParams.href;
    };

    return <FormLayout>{isNil(biometricsList) ? <MDCircularProgress /> : <JotformEmbed src={getURL()} />}</FormLayout>;
};

export default Jotform;
