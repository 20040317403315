// Setting layout components
import MDModal from "components/MDModal";
import { CenterForm } from "layouts/settings/components/Forms/CenterForm";

// Type
import { FormOperation } from "types/enums";
import { Center } from "types/setting-schema";
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    center: Center;
    callback: (updated: boolean, isDelete?: boolean) => void;
}

export const ViewCenter: React.FC<Props> = ({ setToggleModal, center, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <CenterForm operation={FormOperation.VIEW} center={center} callback={callback} />
        </MDModal>
    );
};

export default ViewCenter;
