import React from "react";
import { User } from "types/user-schema";
import Icon from "@mui/material/Icon";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDNoData from "components/MDNoData";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { isNotNil } from "helpers/utils";
import { useTranslation } from "react-i18next";

interface Cells {
    columns: { Header: string; accessor: string; disable?: boolean }[];
    rows: {
        id: string;
        name: string;
        role: string;
        center: string;
        email: string;
        contact: string;
        invite: JSX.Element;
        edit: JSX.Element;
    }[];
}

interface Props {
    list: User[];
    handleOnClick: () => void;
    handleReactivate: (id: string) => void;
}

const Table: React.FC<Props> = ({ list = [], handleOnClick, handleReactivate }) => {
    const { t: translate } = useTranslation(["common"]);

    if (list?.length === 0) {
        return (
            <MDNoData
                content={translate("general.notification.noData", {
                    type: `${translate("general.type.account")}`,
                })}
            />
        );
    }

    const cells: Cells = {
        columns: translate("account.table", { returnObjects: true }),
        rows: [] as any[],
    };

    cells.rows = list?.map((user) => {
        const phone = isNotNil(user.phone) ? `${user.phone.countryCode} ${user.phone.number}` : "";
        return {
            id: user._id,
            name: user.name,
            role: translate(`account.role.${user.role}`),
            center: user.center?.name,
            email: user?.email,
            contact: phone,
            invite: (
                <MDButton
                    variant="outlined"
                    color="dark"
                    circular={false}
                    sx={{ position: "relative", zIndex: 5 }}
                    onClick={() => handleReactivate(user._id)}>
                    <MDTypography variant="span">{translate(`account.resend`)}</MDTypography>
                </MDButton>
            ),
            edit: (
                <MDBox display="flex">
                    <Icon>create</Icon>
                    <MDTypography variant="h6">{translate(`account.edit`)}</MDTypography>
                </MDBox>
            ),
        };
    });

    return <DataTable table={cells} handleOnClick={handleOnClick} />;
};

export const AccountTable: React.FC<Props> = (props) => {
    return (
        <MDBox>
            <Table {...props} />
        </MDBox>
    );
};

AccountTable.whyDidYouRender = true;
export default AccountTable;
