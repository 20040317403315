import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { globalStyle } from "styles/global.styles";

interface Props {
    content: string;
    margin?: number;
}

export const MDNoData: React.FC<Props> = ({ content, margin }) => {
    return (
        <MDBox style={globalStyle.flex.horizon} my={margin}>
            <MDBox textAlign="center">
                <Icon sx={{ fontSize: "50px !important" }}>description</Icon>
                <MDTypography>{content}</MDTypography>
            </MDBox>
        </MDBox>
    );
};

MDNoData.defaultProps = {
    content: "",
    margin: 8,
};

export default MDNoData;
