import { useEffect, useState, useCallback } from "react";

// Material Dashboard 2 PRO React TS components
import MDTab from "components/MDTab";
import MDBox from "components/MDBox";

// Setting layout components
import BasicLayout from "examples/LayoutContainers/BasicLayout";
import UnitView from "./unitView";
import { useTranslation } from "react-i18next";
import Report from "layouts/report/Report";
import { useLocation } from "react-router-dom";
import BusinessService from "services/business.service";
import CenterService from "services/center.service";
import { Business, Center } from "types/setting-schema";
import { Room } from "types/websocket-schema";
import { io } from "socket.io-client";
import { convertToObject, updateData } from "helpers/utils";
import MDNewFilter from "components/MDNewFilter";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
import RealTimeInfoDetailsTable from "examples/Tables/RealTimeInfoDetailsTable";

//set the type props for the component
interface Props {
    type?: string;
}
export const UnitOverView = (props: Props) => {
    const {type} = props;
    const state = useLocation().state as { businessId: string; centerId: string } | null;
    const [activeSetting, setActiveSetting] = useState("live");
    const [activeBusiness, setActiveBusiness] = useState<string>("All");
    const [activeCenter, setActiveCenter] = useState<string>("All");
    const [businessData, setBusinessData] = useState<Business[]>([]);
    const [centerData, setCenterData] = useState<Center[]>([]);
    const [data, setData] = useState<{ [key: string]: Room }>({});
    const [filter, setFilter] = useState<string>("");
    const { t: translate } = useTranslation(["common"]);

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    };

    const fetchBusinesses = async () => {
        const res = await BusinessService.getAllBusinesses();
        if (res.success) {
            setBusinessData(res.data);
        }
    };

    const fetchCenter = async () => {
        const res = await CenterService.getAllCenters(activeBusiness);
        if (res.success) {
            setCenterData(res.data);
        }
    };

    const handleBusinessChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setActiveBusiness(e.target.value as string);
    };

    const handleCenterChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setActiveCenter(e.target.value as string);
    };

    useEffect(() => {
        if (activeBusiness !== "All") fetchCenter();
        if (activeSetting !== "live") return;

        setData({});

        const query = activeCenter === "All" ? `business-${activeBusiness}` : `center-${activeCenter}`;
        const socket = io(
            process.env.REACT_APP_ENV === "production"
                ? "https://api.wecaredynamics.com/"
                : process.env.REACT_APP_ENV === "development"
                ? "https://devapi.wecaredynamics.com/"
                : "http://localhost:6000/",
            {
                query: { type_id: query },
            },
        );

        socket.on("connect", () => {
            const socketId = socket.id;
        });

        socket.on("data", (channel, message) => {
            const _data = convertToObject(channel.data);
            setData((prevData) => updateData(prevData, _data));
        });

        return () => {
            socket.disconnect();
        };
    }, [activeBusiness, activeCenter, activeSetting]);

    useEffect(() => {
        setActiveBusiness(state?.businessId || "All");
        setActiveCenter(state?.centerId || "All");
    }, [state, businessData]);

    useEffect(() => {
        fetchBusinesses();
        if(type === "report") {
            setActiveSetting("report");
        }
        if(type === "live") {
            setActiveSetting("live");
        }
    }, []);
    useEffect(() => {
        if(type === "report") {
            setActiveSetting("report");
        }
        if(type === "live") {
            setActiveSetting("live");
        }
    }, [type]);

    const renderReport = useCallback(() => {
        return (
            <MDBox
                sx={{
                    background: "#FFFFFF",
                    minHeight: "500px",
                    borderRadius: "12px",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                    position: "relative",
                    width: "100%",
                }}>
                <Report businessId={activeBusiness} centerId={activeCenter} filter={filter} />
            </MDBox>
        );
    }, [activeBusiness, activeCenter]);

    return (
        <BasicLayout>
            <MDBox mb={3}>
                <MDTab
                    list={[
                        { key: translate("info.realTimeInfo.live"), value: "live" },
                        { key: translate("info.realTimeInfo.report"), value: "report" },
                    ]}
                    active={activeSetting}
                    scrollable={false}
                    setActive={setActiveSetting}
                />
            </MDBox>
            <MDBox>
                <MDBox
                    sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center" }}
                    justifyContent="space-between"
                    mb={5}>
                    <MDBox sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center" }}>
                        <MDNewFilter
                            value={activeBusiness}
                            data={businessData}
                            handleChange={handleBusinessChange}
                            mb={0}
                            mt={1}
                            type={0}
                        />
                        <MDNewFilter
                            value={activeCenter}
                            data={centerData}
                            handleChange={handleCenterChange}
                            mb={0}
                            type={1}
                            mt={1}
                        />
                    </MDBox>
                    {activeSetting === "live" && (
                        <MDBox
                            display="flex"
                            sx={{
                                background: "transparent",
                                border: "1px solid #344767",
                                borderRadius: "25px",
                                alignItems: "center",
                                padding: "5px 10px",
                                margin: "5px 0px",
                                // width:"100%",
                                marginTop: 1,
                            }}>
                            <Icon>search</Icon>
                            <MDInput
                                mb={5}
                                sx={{
                                    paddingLeft: "8px",
                                    "& ::before": { border: "0 !important" },
                                    "& ::after": { border: "0 !important" },
                                }}
                                value={filter}
                                onChange={handleFilterChange}
                            />
                        </MDBox>
                    )}
                </MDBox>
                <MDBox>
                    {activeSetting === "live" && <RealTimeInfoDetailsTable filter={filter} data={data} />}
                    {activeSetting === "report" && renderReport()}
                </MDBox>
            </MDBox>
        </BasicLayout>
    );
};

export default UnitOverView;
