import { Response, default as http } from "http-common";
import { BusinessWithAlerts } from "layouts/overview/business-page";
import { Business } from "types/setting-schema";

class BusinessService {
    async getAllBusinesses(): Promise<Response<Business[]>> {
        const response = await http.get<Response<Business[]>>("/api/v1/businesses");
        return response.data;
    }
    async getAllBusinessesWithOnlineStatus(): Promise<Response<BusinessWithAlerts[]>> {
        const response = await http.post<Response<BusinessWithAlerts[]>>("/api/v1/businesses/withOnlineResidents");
        return response.data;
    }
    
    async getSingleBusiness(businessId: string): Promise<Response<Business>> {
        const response = await http.get<Response<Business>>(`/api/v1/businesses/${businessId}`);
        return response.data;
    }

    async updateSingleBusiness(businessId: string, businessInfo: Business): Promise<Response<Business>> {
        const response = await http.put<Response<Business>>(`/api/v1/businesses/${businessId}`, businessInfo);
        return response.data;
    }

    async createBusiness(businessInfo: Business): Promise<Response<Business>> {
        const response = await http.post<Response<Business>>("/api/v1/businesses", businessInfo);
        return response.data;
    }

    async deleteSingleBusiness(businessId: string): Promise<Response<Business>> {
        const response = await http.delete<Response<Business>>(`/api/v1/businesses/${businessId}`);
        return response.data;
    }
}

export default new BusinessService();
