import { useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDTab from "components/MDTab";
import MDBox from "components/MDBox";

// Setting layout components
import BasicLayout from "examples/LayoutContainers/BasicLayout";
import { UnitAlarmForm } from "../components/Forms/Alarm/unitAlarm";
import { ResidentialAlarmForm } from "../components/Forms/Alarm/residentialAlarm";


export const General: React.FC = () => {
    const [activeSetting, setActiveSetting] = useState("unit alarm");

    return (
        <BasicLayout>
            <MDBox mb={3}>
                <MDTab
                    list={[
                        { key: "Unit Alarm", value: "unit alarm" },
                        { key: "Residential Alarm", value: "residential alarm"},
                    ]}
                    active={activeSetting}
                    scrollable={false}
                    setActive={setActiveSetting}
                />
            </MDBox>
            {activeSetting === "unit alarm"?<UnitAlarmForm />:<ResidentialAlarmForm/>}
        </BasicLayout>
    );
};

export default General;
