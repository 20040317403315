import { User } from "types/user-schema";
import Icon from "@mui/material/Icon";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDNoData from "components/MDNoData";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { isNotNil } from "helpers/utils";
// i18n
import { useTranslation } from "react-i18next";
import MDAvatar from "components/MDAvatar";
import { AlertRecord } from "types/alert-schema";
import HomeIcon from "@mui/icons-material/Home";
import { RoomTable } from "types/enums";
import { Theme } from "@mui/material";
import AlarmService from "services/alarm.service";
import { useEffect, useState } from "react";
import MDNotification from "components/MDNotification";
interface Cells {
    columns: { Header: string; accessor: string; disable?: boolean }[];
    rows: {
        resident: JSX.Element;
        room: JSX.Element;
        hrrr: JSX.Element;
        status: JSX.Element;
        bloodPressure: JSX.Element;
        bloodSugar: JSX.Element;
        textReport: JSX.Element;
        ownerReport: JSX.Element;
        startTime: JSX.Element;
        endTime: JSX.Element;
    }[];
}

interface Props {
    list: AlertRecord[];
    callback: (updated: boolean) => void;
}
const category = [
    RoomTable.RESIDENT,
    RoomTable.ROOM,
    RoomTable.HRRR,
    RoomTable.STATUS,
    RoomTable.BLOODPRESSURE,
    RoomTable.BLOODSUGAR,
    RoomTable.TEXTREPORT,
    RoomTable.OWNERREPORT,
    RoomTable.STARTTIME,
    RoomTable.ENDTIME,
];

export const AccountTable: React.FC<Props> = ({ list, callback }) => {
    const { t: translate } = useTranslation(["common"]);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);
    const renderTime = (time: string): JSX.Element => {
        const timestamp = new Date(time);
    
        // Extract UTC components
        const year = timestamp.getFullYear();
        const month = (timestamp.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        const day = timestamp.getDate().toString().padStart(2, '0');
        const hours = timestamp.getHours().toString().padStart(2, "0");
        const minutes = timestamp.getMinutes().toString().padStart(2, "0");
        const secounds = timestamp.getSeconds().toString().padStart(2, "0");    
        // Format date and time
        const utcDate = `${year}/${month}/${day}`;
        const utcTime = `${hours}:${minutes}:${secounds}`;
    
        return (
            <MDBox>
                <MDTypography fontSize="16px">{utcDate}</MDTypography>
                <MDTypography fontSize="16px">{utcTime}</MDTypography>
            </MDBox>
        );
    };
    const renderTextReport = (textReport: 0 | 1 | 2 | 3) => {
        switch (textReport) {
            case 0:
                return translate("info.realTimeInfo.initial");
            case 1:
                return translate("info.realTimeInfo.resolved");
            case 2:
                return translate("info.realTimeInfo.nresolved");
            case 3:
                return translate("info.realTimeInfo.timeout");
            default:
                return "";
        }
    };

    const handleOwnerReport = async (alertId: string) => {
        let res = await AlarmService.updateAlarmStatus(alertId, true);
        handleResult(res);
    };
    const handleResult = (res: any) => {
        if (res.success) {
            callback(true);
        } else {
            setError(res);
        }
    };
    const renderNotification = () => {
        return <MDNotification content={error} />;
    };
    const renderTable = () => {
        if (list?.length === 0) {
            return (
                <MDNoData
                    content={translate("general.notification.noData", {
                        type: `${translate("general.type.alert")}`,
                    })}
                />
            );
        }
        const cells: Cells = {
            columns: [],
            rows: [] as any[],
        };
        cells.columns = category.map((key) => {
            return {
                Header: translate(`info.realTimeInfo.${key}`),
                accessor: key,
            };
        });
        cells.rows = list?.map((alert) => {
            let isWarning = 0;
            return {
                resident: (
                    <MDBox
                        component="td"
                        p={1.5}
                        sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }: Theme) => ({
                            fontSize: size.sm,
                            width: "25%",
                        })}>
                        <MDTypography
                            variant="subtitle2"
                            textAlign={"center"}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                float: "left",
                            }}>
                            <MDBox
                                sx={{
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    backgroundColor:
                                        isWarning === 0 ? " transparent" : isWarning === 1 ? " red" : " yellow",
                                    display: "inline-block",
                                }}
                            />
                            <MDAvatar
                                sx={{
                                    margin: "0px 16px",
                                }}
                                src={
                                    alert.avatarUrl !== ""
                                        ? alert.avatarUrl
                                        : "https://firebasestorage.googleapis.com/v0/b/rel-dev-9037c.appspot.com/o/%E9%AB%98%E9%9B%84%E9%86%AB%E5%AD%B8%E5%A4%A7%E5%AD%B8%2F64717e0b8ee3a47becefc5a2%2Fresident%2Fc8bbbf2f-12b1-45d9-b947-29619dfe69e1?alt=media&token=044acbb8-b47e-43db-b59e-cb9d7c9ba8e5"
                                }
                            />
                            <MDBox sx={{ display: "flex", flexDirection: "column" }} alignItems={"flex-start"}>
                                <MDBox>{alert.roomName}</MDBox>
                                <MDTypography
                                    variant="subtitle2"
                                    textAlign={"center"}
                                    sx={{ textWrap: "nowrap", paddingTop: "4px" }}>
                                    {alert.residentName}
                                </MDTypography>
                            </MDBox>
                        </MDTypography>
                    </MDBox>
                ),
                room: (
                    <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                        <HomeIcon fontSize="inherit" sx={{ color: "#F4B8F5", marginRight: "4px" }} />
                        {alert.roomName}
                    </MDBox>
                ),
                hrrr: (
                    <MDBox>
                        <MDTypography
                            sx={{ color: !alert.hrrr?.isWarning ? "#344767" : "#CA1818", textAlign: "center" }}>
                            {alert.hrrr?.value ? alert.hrrr.value : "--"}
                        </MDTypography>
                    </MDBox>
                ),
                status: alert.status ? (
                    <MDBox
                        component="td"
                        p={1.5}
                        sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }: Theme) => ({
                            fontSize: size.sm,
                            textAlign: "center",
                        })}>
                        <MDTypography
                            fontSize="16px"
                            // padding="0px 4px"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "4px 10px",
                                borderRadius: "8px",
                                color: "white !important",
                                backgroundColor:
                                    alert.status === "fall"
                                        ? "#AD1818"
                                        : alert.status === "dead"
                                        ? "#484848"
                                        : alert.status === "tooLong"
                                        ? "#F2C94C"
                                        : (alert.status === "hrLow" ||
                                              alert.status === "hrHigh" ||
                                              alert.status === "rrLow" ||
                                              alert.status === "rrHigh") &&
                                          "#3B5AFF",
                            }}>
                            {translate(`info.realTimeInfo.${alert.status}`)}
                        </MDTypography>
                    </MDBox>
                ) : (
                    <></>
                ),
                bloodPressure: (
                    <MDBox>
                        <MDTypography
                            sx={{
                                color: !alert.bloodPressure?.isWarning ? "#344767" : "#CA1818",
                                textAlign: "center",
                            }}>
                            {alert.bloodPressure?.value !== "" ? alert.bloodPressure.value : "--"}
                        </MDTypography>
                    </MDBox>
                ),
                bloodSugar: (
                    <MDBox>
                        <MDTypography
                            sx={{ color: !alert.bloodSugar?.isWarning ? "#344767" : "#CA1818", textAlign: "center" }}>
                            {alert.bloodSugar?.value !== "" ? alert.bloodSugar.value : "--"}
                        </MDTypography>
                    </MDBox>
                ),
                textReport: (
                    <MDBox
                        component="td"
                        p={1.5}
                        sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }: Theme) => ({
                            fontSize: size.sm,
                        })}>
                        <MDTypography
                            fontSize="16px"
                            padding="0px 4px"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "4px 10px",
                                backgroundColor: alert.textReport === 1 ? "#5AAA3E" : "transparent",
                                color: "white ",
                                borderRadius: "5px",
                            }}>
                            {renderTextReport(alert.textReport)}
                        </MDTypography>
                    </MDBox>
                ),
                ownerReport: alert.ownerReport ? (
                    <MDBox>
                        <MDButton
                            variant="outlined"
                            color="dark"
                            size="small"
                            disabled
                            sx={{ textTransform: "none", borderRadius: "8px" }}>
                            {translate(`info.realTimeInfo.complete`)}
                        </MDButton>
                    </MDBox>
                ) : (
                    <MDBox>
                        <MDButton
                            variant="outlined"
                            color="dark"
                            size="small"
                            onClick={() => handleOwnerReport(alert.alertId)}
                            sx={{ textTransform: "none", borderRadius: "8px", borderColor: "black" }}>
                            {translate(`info.realTimeInfo.confirm`)}
                        </MDButton>
                    </MDBox>
                ),
                startTime: renderTime(alert.startTime),
                endTime: renderTime(alert.endTime),
            };
        });

        return <DataTable table={cells} />;
    };

    return (
        <MDBox>
            {error && renderNotification()}
            {renderTable()}
        </MDBox>
    );
};

export default AccountTable;
