import React, { useMemo, useCallback, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RoomTable } from "types/enums";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import { Theme } from "@mui/material/styles";
import MDAvatar from "components/MDAvatar";
import HomeIcon from "@mui/icons-material/Home";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { Room, RoomInfo, Andar } from "types/websocket-schema";
import { useNavigate } from "react-router-dom";
import defaultAvatar from "assets/images/defaultAvatar.svg";
import { CircularProgress, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

const category = [
    RoomTable.RESIDENT,
    RoomTable.ROOM,
    RoomTable.HRRR,
    RoomTable.STATUS,
    RoomTable.BLOODPRESSURE,
    RoomTable.BLOODSUGAR,
];

interface RealTimeInfoTableProps {
    filter?: string;
    data?: { [key: string]: Room };
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FFFBFB",
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: "0px 2px 6px 0px #00000026",
    //   fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        display:"none"
    }
  }));

const RealTimeInfoTable: React.FC<RealTimeInfoTableProps> = ({ filter, data }) => {
    const { t: translate } = useTranslation(["common"]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 10000);
    }, []);
    useEffect(() => {
        if(data){
            setLoading(false);
        }
    }, [data]);
    const filteredKeys = useMemo(() => {
        if (!data) return [];
        return Object.keys(data).filter((key) => !filter || key.toLowerCase().includes(filter.toLowerCase()));
    }, [data, filter]);

    if(loading) {
        return (
            <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                <CircularProgress size={50} />
            </MDBox>
        );
    }

    if (!data) {
        return (
            <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                <MDTypography variant="h5">{translate("info.realTimeInfo.noData")}</MDTypography>
            </MDBox>
        );
    }

    return (
        <TableContainer component={Paper} sx={{ backgroundColor: "white !important", borderRadius: 3 }}>
            <Table sx={{ minWidth: 900 }} aria-label="simple table">
                <MDBox component="thead">
                    {category.map((item, i) => (
                        <TableCell
                            key={i}
                            align={item != RoomTable.RESIDENT && item != RoomTable.ROOM ? "center" : "left"}>
                            <MDTypography
                                variant="subtitle2"
                                key={i}
                                sx={{
                                    display: "inline-block",
                                    fontWeight: "medium",
                                    mx: item !== RoomTable.ROOM ? 2 : 0,
                                    paddingTop: "20px",
                                }}>
                                {item ? translate(`info.realTimeInfo.${item}`) : ""}
                            </MDTypography>
                        </TableCell>
                    ))}
                </MDBox>
                <TableBody>
                    {filteredKeys.map((key) => (
                        <MemoizedTableRow key={key} roomKey={key} roomData={data[key]} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const CustomTableRow: React.FC<{ roomKey: string; roomData: Room }> = ({ roomKey, roomData }) => {
    const navigate = useNavigate();
    const { t: translate } = useTranslation(["common"]);

    const handleClick = useCallback(
        (destination: string) => {
            window.history.pushState({ prevUrl: "/dashboard" }, null);
            switch (destination) {
                case "resident":
                    navigate("/overview/profile", { state: { residentId: roomKey } });
                    break;
                case "unit":
                    navigate("/overview/overview-unit", { state: { roomId: roomKey } });
                    break;
                case "physiological":
                    navigate("/daily-measurement-and-survey/physiological-data", {
                        state: { residentId: roomKey, tag: "chart" },
                    });
                    break;
            }
        },
        [navigate, roomKey],
    );

    const getRoomStatus = useCallback(() => {
        const rooms = [
            { room: roomData.kitchen, name: "kitchen" },
            { room: roomData.bedRoom, name: "bedRoom" },
            { room: roomData.livingRoom, name: "livingRoom" },
            { room: roomData.bathRoom, name: "bathRoom" },
        ];

        const hasAllRooms = Boolean(
            roomData.bedRoom && roomData.livingRoom && roomData.kitchen && roomData.bathRoom && roomData.andar,
        );

        for (let { room, name } of rooms) {
            if (room?.people) {
                return (
                    <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                        <HomeIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                        {translate(`info.realTimeInfo.${name}`)}
                    </MDBox>
                );
            }
        }
        if (roomData.andar?.people) {
            return (
                <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <HomeIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                    {translate(`info.realTimeInfo.bedRoom`)}
                </MDBox>
            );
        }
        if (!roomData.kitchen && !roomData.bedRoom && !roomData.livingRoom && !roomData.bathRoom) {
            return (
                <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <MeetingRoomIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                    N/A
                </MDBox>
            );
        }
        if (hasAllRooms) {
            return (
                <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <MeetingRoomIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                    {translate(`info.realTimeInfo.notInRoom`)}
                </MDBox>
            );
        } else {
            return (
                <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <MeetingRoomIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                    N/A
                </MDBox>
            );
        }
    }, [roomData.kitchen, roomData.bedRoom, roomData.livingRoom, roomData.bathRoom, roomData.andar, translate]);

    const getResidentStatus = useCallback(() => {
        const rooms = [
            { room: roomData.kitchen, name: "kitchen" },
            { room: roomData.bedRoom, name: "bedRoom" },
            { room: roomData.livingRoom, name: "livingRoom" },
            { room: roomData.bathRoom, name: "bathRoom" },
        ];

        const content = (level: number, text: string) => (
            <MDTypography
                variant="subtitle2"
                fontSize="16px"
                padding="0px 4px"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px 6px",
                    backgroundColor: level === 1 ? "#AD1818" : level === 2 ? "#3B5AFF" : "#56CE0C",
                    color: "white",
                    borderRadius: "5px",
                }}>
                {text}
            </MDTypography>
        );

        if (!roomData.hasLiveData) {
            return content(0, translate(`info.realTimeInfo.offline`));
        }

        for (let { room } of rooms) {
            if (room?.fallDown) {
                return content(1, translate(`info.realTimeInfo.fall`));
            } else if (room?.toLong) {
                return content(1, translate(`info.realTimeInfo.toLong`));
            }
        }

        return content(3, translate(`info.realTimeInfo.safe`));
    }, [roomData.kitchen, roomData.bedRoom, roomData.livingRoom, roomData.bathRoom, roomData.hasLiveData, translate]);

    const isWarning = useMemo(() => {
        if (
            roomData.bedRoom?.fallDown ||
            roomData.kitchen?.fallDown ||
            roomData.livingRoom?.fallDown ||
            roomData.bathRoom?.fallDown
        ) {
            return 1;
        }
        if (roomData.andar?.alarm) {
            return 2;
        }
        return 0;
    }, [roomData.bedRoom, roomData.kitchen, roomData.livingRoom, roomData.bathRoom, roomData.andar]);

    const AlertTooltip = useCallback(() => {
        const alertMessages: string[] = [];

        const processAlarm = (device:any, roomName:string) => {
            if (device?.alarm) {
                const alarms = Array.isArray(device.alarm) ? device.alarm : [device.alarm];
                alarms.forEach((alarm: any) => {
                    if (alarm && (!Array.isArray(alarm) || alarm.length > 0)) {
                        let alertContent = '';
                        switch (alarm.attr) {
                            case 0:
                                alertContent = alarm.originalValue > alarm.value
                                    ? translate("alerts.heartRateHigh", { value: alarm.value })
                                    : translate("alerts.heartRateLow", { value: alarm.value });
                                break;
                            case 1:
                                alertContent = alarm.originalValue > alarm.value
                                    ? translate("info.realTimeInfo.tooltip.respiratoryRateHigh", { value: alarm.value })
                                    : translate("info.realTimeInfo.tooltip.respiratoryRateLow", { value: alarm.value });
                                break;
                            case 2:
                                alertContent = translate("info.realTimeInfo.tooltip.awayFromBed", { value: alarm.value / 60 });
                                break;
                            case 6:
                                alertContent = translate("info.realTimeInfo.tooltip.fallDetected");
                                break;
                            case 7:
                                alertContent = translate("info.realTimeInfo.tooltip.stayedTooLong", { room: roomName, value: alarm.period });
                                break;
                            case 8:
                                alertContent = translate("info.realTimeInfo.tooltip.outOfRoomTooLong", { value: alarm.period });
                                break;
                            default:
                                alertContent = alarm.statusStr;
                        }
                        alertMessages.push(`${roomName}: ${alertContent}`);
                    }
                });
            }
        };

        processAlarm(roomData.andar, translate("Ander"));
        processAlarm(roomData.kitchen, translate("info.realTimeInfo.kitchen"));
        processAlarm(roomData.bedRoom, translate("info.realTimeInfo.bedroom"));
        processAlarm(roomData.livingRoom, translate("info.realTimeInfo.livingRoom"));
        processAlarm(roomData.bathRoom, translate("info.realTimeInfo.bathroom"));

        return alertMessages.join('\n');
    }, [roomData, translate]);
    if (roomData.hasLiveData === false) {
        return null;
    }
    return (
        <LightTooltip placement="left" arrow title={AlertTooltip()} >
            <TableRow hover sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#FFFBFB !important" } }}>
                <MDBox
                    component="td"
                    p={1.5}
                    onClick={() => handleClick("resident")}
                    sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }: Theme) => ({
                        fontSize: size.sm,
                        width: "25%",
                    })}>
                    <MDTypography
                        variant="subtitle2"
                        textAlign={"center"}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            float: "left",
                        }}>
                        <MDBox
                            sx={{
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                backgroundColor:
                                    isWarning === 0 ? " transparent" : isWarning === 1 ? " red" : " #3B5AFF",
                                display: "inline-block",
                            }}
                        />
                        <MDAvatar sx={{ margin: "0px 16px" }} src={roomData.avatar || defaultAvatar} />
                        <MDBox sx={{ display: "flex", flexDirection: "column" }} alignItems={"flex-start"}>
                            <MDBox>{roomData.roomName}</MDBox>
                            <MDTypography
                                variant="subtitle2"
                                textAlign={"center"}
                                sx={{ textWrap: "nowrap", paddingTop: "4px" }}>
                                {roomData.name}
                            </MDTypography>
                        </MDBox>
                    </MDTypography>
                </MDBox>
                <MDBox component="td" p={1.5} onClick={() => handleClick("unit")} sx={{ width: "13%" }}>
                    <MDTypography variant="subtitle2" textAlign="center" fontSize="16px">
                        {getRoomStatus()}
                    </MDTypography>
                </MDBox>
                <MDBox component="td" p={1.5} onClick={() => handleClick("unit")}>
                    <MDTypography variant="subtitle2" textAlign="center" sx={{ color: isWarning === 2 ? "red" : "" }}>
                        {`${roomData.andar?.bpm || "--"}/${roomData.andar?.rpm || "--"}`}
                    </MDTypography>
                </MDBox>
                <MDBox component="td" p={1.5}>
                    <MDTypography
                        variant="subtitle2"
                        fontSize="16px"
                        padding="0px 4px"
                        sx={{ display: "flex", justifyContent: "center", padding: "4px" }}>
                        {getResidentStatus()}
                    </MDTypography>
                </MDBox>
                <MDBox component="td" p={1.5} onClick={() => handleClick("physiological")}>
                    <MDTypography variant="subtitle2" textAlign="center">
                        {roomData.bloodPressure || "--"}
                    </MDTypography>
                </MDBox>
                <MDBox component="td" p={1.5} onClick={() => handleClick("physiological")}>
                    <MDTypography variant="subtitle2" textAlign="center">
                        {roomData.bloodSugar || "--"}
                    </MDTypography>
                </MDBox>
            </TableRow>
        </LightTooltip>
    );
};

const MemoizedTableRow = React.memo(CustomTableRow, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.roomData) === JSON.stringify(nextProps.roomData);
});

export default RealTimeInfoTable;
