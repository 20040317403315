import { Response, default as http } from "http-common";
import { LoginInfo, Passwords } from "types/auth";
import Cookies from 'universal-cookie';
interface User {
    _id: string;
    name: string;
    email: string;
    role: string;
    menus: string[];

}

interface TokenResponse {
    success: boolean;
    token: string;
}

const cookies = new Cookies();

class AuthService {
    getAuthToken(): string | null {

        return cookies.get("login-token");
    }

    setAuthToken(token: string | null) {
        if (token == null) {
            cookies.remove('login-token', { path: '/' });
            return;
        }
        cookies.set("login-token", token, { path: '/', expires: new Date(Date.now() + 2592000000) });
    }

    getResetToken(): string | null {
        return cookies.get("reset-token");
    }

    setResetToken(token: string | null) {
        if (token == null) {
            cookies.remove("reset-token", { path: '/' });
            return;
        }
        cookies.set("reset-token", token, { path: '/' });
    }

    // login api, get token
    async login(info: LoginInfo): Promise<TokenResponse> {
        const response = await http.post<TokenResponse>("/api/v1/auth/login", info);

        if (response.data.success && response.data.token) {
            this.setAuthToken(response.data.token);
        }

        return response.data;
    }

    async logout() {
        this.setAuthToken(null);
        this.setResetToken(null);
        cookies.remove("user", { path: '/' });
    }

    // use token to retrieve user information
    async getUser(): Promise<User> {
        const response = await http.get<Response<User>>("/api/v1/auth/me");
        const user = response.data.data;

        cookies.set("user", JSON.stringify(user));

        return user;
    }

    // change user password
    async changePassword(pw: Passwords) {
        const response = await http.put("/api/v1/auth/updatepassword", pw);
        return response.data;
    }

    // forgot password, send code via inserted email
    async forgotPassword(email: object, lang: string) {
        const response = await http.post(`/api/v1/auth/forgotpassword?lang=${lang}`, email);
        return response.data;
    }

    // reset password
    async resetPassword(pw: string, lang: string) {
        const token = this.getResetToken();

        const response = await http.put(`/api/v1/auth/resetpassword/${token}?lang=${lang}`, { password: pw });

        if (response.status === 200 && response.data.success === true) {
            this.setResetToken(null);
        }
        return response.data;
    }

    // check if user is logged in
    isAuthenticated(): boolean {
        return this.getAuthToken() != null;
    }
}

export default new AuthService();
