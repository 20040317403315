// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useTranslation } from "react-i18next";

interface FuncProps {
    passwordChange?(arg: any): void;
}

const Change: React.FC<FuncProps> = ({ passwordChange }) => {
    const { t: translate } = useTranslation(["common"]);
    return (
        <>
            <MDBox mb={2}>
                <MDInput
                    type="password"
                    name="oldPw"
                    label={translate("auth.oldPassword")}
                    fullWidth
                    onChange={passwordChange}
                />
            </MDBox>
            <MDBox>
                <MDInput
                    type="password"
                    name="newPw"
                    label={translate("auth.newPassword")}
                    fullWidth
                    onChange={passwordChange}
                />
            </MDBox>
        </>
    );
};

const Reset: React.FC<FuncProps> = ({ passwordChange }) => {
    const { t: translate } = useTranslation(["common"]);
    return (
        <>
            <MDBox mb={2}>
                <MDInput
                    type="password"
                    name="newPw"
                    label={translate("auth.newPassword")}
                    fullWidth
                    onChange={passwordChange}
                />
            </MDBox>
            <MDBox>
                <MDInput
                    type="password"
                    name="repeatPw"
                    label={translate("auth.confirmPassword")}
                    fullWidth
                    onChange={passwordChange}
                />
            </MDBox>
        </>
    );
};

export { Change, Reset };
