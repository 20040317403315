import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import FormLayout from "examples/LayoutContainers/FormLayout";
import SubmitButtons from "components/MDButton/submitButton";
import MDBox from "components/MDBox";
import MDSkeleton from "components/MDSkeleton";
import MDErrorMsg from "components/MDErrorMsg";
import MDTypography from "components/MDTypography";
import { FormType } from "types/enums";
import { isNotNil, isNil } from "helpers/utils";
import { SurveyData, SurveyTemplate } from "types/measurement-schema";
import SurveyService from "services/survey.service";
// i18n
import { useTranslation } from "react-i18next";
import { Response, default as http } from "http-common";
interface Props {
    residentId: string;
    uncompletedSurveys: string[];
    callback: (updated: boolean, data: SurveyData) => void;
}

export const SurveyForm: React.FC<Props> = ({ residentId, uncompletedSurveys, callback }) => {
    const [surveyId, setSurveyId] = useState<string | null>(null);
    const [surveyTemplates, setSurveyTemplate] = useState<SurveyTemplate[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        const fetchSurveyTemplate = async () => {
            const res = await SurveyService.getSurveyTemplates(translate("lang"));
            if (res.success) {
                setSurveyTemplate(res.data);
            }
        };
        fetchSurveyTemplate();
    }, []);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setError(null);

        // If a type of survey remains uncompleted, user can't create it
        if (uncompletedSurveys.includes(surveyId)) {
            setError(translate("info.survey.uncompleted"));
            return;
        }

        const res = await handleCreateSurvey();
        handleResult(res);
    };

    const handleCreateSurvey = async () => {
        return SurveyService.createSurvey(surveyId, residentId);
    };

    const handleResult = (res: Response<SurveyData>) => {
        if (res.success) {
            if (isNotNil(callback)) {
                callback(true, res.data);
            } else {
                navigate("/dashboard");
            }
        } else {
            setError(res.error);
        }
    };

    if (isNil(surveyTemplates)) return <MDSkeleton />;

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <FormLayout
                        header={`${translate(`general.operation.create`)}${translate(`general.state.new`)}${translate(
                            `general.type.${FormType.SURVEY}`,
                        )}`}>
                        {isNotNil(surveyTemplates) &&
                            surveyTemplates.map((item, idx) => (
                                <MDBox mb={2} key={idx}>
                                    <MDTypography
                                        variant="body"
                                        fontWeight={surveyId === item._id ? "bold" : "regular"}
                                        color={surveyId === item._id ? "success" : "text"}
                                        onClick={() => setSurveyId(item._id)}
                                        sx={{ cursor: "pointer" }}>
                                        {idx + 1}. {item.name}
                                    </MDTypography>
                                </MDBox>
                            ))}
                        {isNotNil(error) && <MDErrorMsg errorMsg={error} />}
                        {isNotNil(surveyId) && <SubmitButtons disable={false} />}
                    </FormLayout>
                </form>
            </Grid>
        </Grid>
    );
};

export default SurveyForm;
