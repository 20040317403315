import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import SurveyService from "services/survey.service";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDCircularProgress from "components/MDCircularProgress";
import MDNotification from "components/MDNotification";
import AddButton from "components/MDButton/addButton";
import { SurveyData } from "types/measurement-schema";
import { isNil } from "helpers/utils";
import NewSurvey from "layouts/info/newSurvey";

// i18n
import { useTranslation } from "react-i18next";
import JotformEmbed from "layouts/info/newJotform";
import ViewQRCode from "layouts/info/viewQRCode";
import { FormOperation } from "types/enums";
import MDModal from "components/MDModal";
import ViewSurvey from "layouts/info/viewSurvey";

interface Cells {
    columns: { Header: string; accessor: string }[];
    rows: {
        id: string;
        category: string;
        createdAt: string;
        status: JSX.Element;
        action: JSX.Element;
    }[];
}

interface Prop {
    residentInfo: {
        id: string;
        gender: string;
        name: string;
        dateOfBirth: string;
        preferredLanguage: string;
    };
    callback: (updated: boolean) => void;
}

interface Info {
    formId: string;
    url: string;
    op: FormOperation;
}

export const Survey: React.FC<Prop> = ({ residentInfo, callback }) => {
    const [surveys, setSurveys] = useState<SurveyData[] | null>(null);
    const [uncompletedSurveys, setUncompletedSurveys] = useState<string[] | null>(null);
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [previewToggleModal, setPreviewToggleModal] = useState<boolean>(false);
    const [currentData, setCurrentData] = useState<{data:string,sid:string,id:string} | null>(null);
    const [formAlert, setFormAlert] = useState<boolean>(false);
    const [info, setInfo] = useState<Info | null>(null);
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        fetchSurvey();
    }, []);

    useEffect(() => {
        if (formAlert) {
            setTimeout(() => {
                setFormAlert(false);
            }, 3000);
        }
    }, [formAlert]);

    const fetchSurvey = async () => {
        const res = await SurveyService.getAllSurveys(residentInfo.id);
        if (res.success) {
            setSurveys(res.data.reverse());
            setUncompletedSurveys([
                ...new Set(res.data.filter((item) => !item.complete).map((data) => data.surveyTemplate)),
            ]);
        }
    };

    const handleOnClick = (id?: string, op: FormOperation = FormOperation.CREATE) => {
        if (op === FormOperation.UPDATE || op === FormOperation.VIEW) {
            const { url } = surveys.find((item) => item._id === id);
            setInfo({ formId: id, url: url, op: op });
        } else {
            setInfo({ ...info, op: op });
        }

        setToggleModal(true);
    };

    const handleDelete = async (id: string) => {
        const res = await SurveyService.deleteSurvey(id);
        if (res.success) {
            setSurveys(null);
            fetchSurvey();
            setFormAlert(true);
            callback(true);
        }
    };

    const handleDownload = (data: string,sid:string,id:string) => {
        setPreviewToggleModal(true);
        setCurrentData({data:data,sid:sid,id:id})
    };

    const handleCallback = (updated: boolean) => {
        if (updated) {
            setSurveys(null);
            fetchSurvey();
            setFormAlert(true);
            setToggleModal(false);
            callback(true);
        }
    };
    const handleCreateCallback = (created: boolean,data:any) => {
        if (created) {
            fetchSurvey();
            setInfo({ formId: data[0]._id, url: data[0].url, op: FormOperation.UPDATE });
            // setSurveys(null);
            // fetchSurvey();
            // setFormAlert(true);
            // setToggleModal(false);
            // callback(true);
        }
    };
    

    const renderForm = () => {
        switch (info.op) {
            case FormOperation.UPDATE:
                return (
                    <JotformEmbed
                        formId={info.formId}
                        url={info.url}
                        residentInfo={residentInfo}
                        callback={handleCallback}
                        setToggleModal={setToggleModal}
                    />
                );
            case FormOperation.CREATE:
                return (
                    <NewSurvey
                        residentId={residentInfo.id}
                        uncompletedSurveys={uncompletedSurveys}
                        callback={handleCreateCallback}
                        setToggleModal={setToggleModal}
                    />
                );
            case FormOperation.VIEW:
                return <ViewQRCode url={info.url} setToggleModal={setToggleModal} />;
            default:
                break;
        }
    };

    const renderTableSection = () => {
        return (
            <>
                <MDBox display="flex" justifyContent="end" m={3}>
                    <AddButton
                        content={translate("general.button.addSurvey")}
                        handleToggleModal={() => handleOnClick()}
                    />
                </MDBox>
                {renderTable()}
            </>
        );
    };
    const renderPreview = () => {
        return <ViewSurvey setToggleModal={setPreviewToggleModal} data={currentData.data} sid={currentData.sid} id={currentData.id} />;
    };
    const renderTable = () => {
        if (surveys.length === 0) return <></>;

        const cells: Cells = {
            columns: translate("info.survey.table", { returnObjects: true }),
            rows: [] as any[],
        };

        surveys.forEach((survey, idx) => {
            cells.rows.push({
                id: survey._id,
                category: `${idx + 1}. ${survey.name}`,
                createdAt: `${survey.createdAt.substring(0, 10)} ${translate("general.operation.create")}`,
                status: (
                    <MDBadge
                        variant="gradient"
                        size="md"
                        badgeContent={
                            survey.complete ? translate("general.state.finish") : translate("general.state.notStart")
                        }
                        color={survey.complete ? "primary" : "light"}
                        container
                        circular
                    />
                ),
                action: (
                    <MDBox display="flex" justifyContent="end">
                        {!survey.complete && (
                            <Icon
                                fontSize="medium"
                                sx={{ cursor: "pointer", mr: 1 }}
                                onClick={() => handleOnClick(survey._id, FormOperation.UPDATE)}>
                                create
                            </Icon>
                        )}
                        {survey.complete && (
                            <Icon
                                fontSize="medium"
                                sx={{ cursor: "pointer", mr: 1 }}
                                onClick={() => handleDelete(survey._id)}>
                                delete
                            </Icon>
                        )}
                        <Icon
                            fontSize="medium"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                if (survey.complete) handleDownload(survey.jotformPretty,survey.jotformSubmissionID,survey.jotformID);
                                else handleOnClick(survey._id, FormOperation.VIEW);
                            }}>
                            {survey.complete ? "download" : "share"}
                        </Icon>
                    </MDBox>
                ),
            });
        });
        return <DataTable table={cells} />;
    };
    return (
        <>
            {formAlert && (
                <MDBox mb={2}>
                    <MDNotification
                        content={translate("general.notification.message", {
                            type: `${translate("general.type.form")}`,
                            operation: `${translate(`general.operation.past.${info.op}`)}`,
                        })}
                    />
                </MDBox>
            )}
            <Card>
                {toggleModal && renderForm()}
                {previewToggleModal && renderPreview()}
                {isNil(surveys) ? <MDCircularProgress /> : renderTableSection()}
            </Card>
        </>
    );
};

export default Survey;
