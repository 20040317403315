import BasicLayout from "examples/LayoutContainers/BasicLayout";

import MDBox from "components/MDBox";
import MDTab from "components/MDTab";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import NewRoom from "./NewRoom";
import FloorSetting from "./FloorSetting";
export const RoomSettingPage: React.FC = () => {
    const { t: translate } = useTranslation(["common"]);
    const [tab, setTab] = useState<string>("newRoom");
    
    return (
        <BasicLayout>
            <MDBox mb={3}>
                <MDTab
                    list={[
                        { key: translate("setting.room.newRoom"), value: "newRoom" },
                        { key: translate("setting.room.floorSetting"), value: "floorSetting" },
                        // { key: "", value: "report", disabled: true },
                    ]}
                    active={tab}
                    scrollable={false}
                    setActive={setTab}
                />
            </MDBox>
            <MDBox>
                <MDBox
                    sx={{
                        background: "#FFFFFF",
                        // minHeight: "500px",
                        borderRadius: "12px",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                        position: "relative",
                    }}>
                    {tab === "newRoom" && <NewRoom />}
                    {tab === "floorSetting" && <FloorSetting />}
                    {/* {tab === "report" && <Report />} */}
                </MDBox>
            </MDBox>
        </BasicLayout>
    );
};

export default RoomSettingPage;
