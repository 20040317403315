import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Business, Center } from "types/setting-schema";
import { BoxProps } from "@mui/material";

// i18n
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
    data: Center[] | Business[];
    handleChange: (e: any) => void;
    mb?: number;
    px?: number;
    active?: string;
    disableAll?: boolean;
}

//testing for two data type

export const MDFilter: React.FC<Props> = ({ data, handleChange, mb, px, active,disableAll }) => {
    const { t: translate } = useTranslation(["common"]);
    return (
        <MDBox mb={mb} px={px}>
            <FormControl sx={{ minWidth: 320, position: "relative" }}>
                <Select
                    defaultValue={active ? active : "All"}
                    sx={{
                        py: 1,
                        background: "transparent",
                        border: "1px solid #344767",
                        position: "relative",
                        zIndex: 5,
                    }}
                    onChange={handleChange}>
                    <MenuItem value="All" disabled={disableAll}>
                        <MDTypography variant="h5" pl={1} pr={5}>
                            {translate("general.state.allCenters")}
                        </MDTypography>
                    </MenuItem>
                    {data?.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                            <MDTypography variant="h5" pl={1} pr={5}>
                                {item.name}
                            </MDTypography>
                        </MenuItem>
                    ))}
                </Select>
                <Icon sx={{ position: "absolute", right: "20px", transform: "translateY(50%)" }}>expand_more</Icon>
            </FormControl>
        </MDBox>
    );
};

MDFilter.defaultProps = {
    mb: 5,
    px: 2,
};

export default MDFilter;
