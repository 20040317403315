import * as Yup from "yup";
import checkout from "layouts/settings/schemas/form";
import { validatePhones, validateEmail } from "helpers/validations";
import { isNil } from "helpers/utils";
import { useTranslation } from "react-i18next";

const {
    formField: {
        business: { name, address, contact, phone, relManagerEmail, relAssistantEmail },
    },
} = checkout;

// Define the validation schema using react-i18next for translations
const useValidationSchema = () => {
    const { t } = useTranslation(["common"]);

    return {
        business: [
            Yup.object().shape({
                name: Yup.string().required(t(`error.${name.errorMsg}`)),
                address: Yup.string().required(t(`error.${address.errorMsg}`)),
                contact: Yup.string().required(t(`error.${contact.errorMsg}`)),
                description: Yup.string(),
                phone: Yup.object().shape({
                    number: Yup.string()
                        .required(t(`error.${phone.number.errorMsg}`))
                        .test("phone-validation", t(`error.${phone.number.invalidMsg}`), function (value) {
                            return validatePhones(value);
                        }),
                    countryCode: Yup.string().oneOf(["+1", "+886"], ""),
                }),
                relManagerEmail: Yup.string().test("email-validation", t(`error.${relManagerEmail.invalidMsg}`), function (value) {
                    return validateEmail(value) || isNil(value);
                }),
                relAssistantEmail: Yup.string().test("email-validation", t(`error.${relAssistantEmail.invalidMsg}`), function (value) {
                    return validateEmail(value) || isNil(value);
                }),
            }),
        ],
    };
};

export default useValidationSchema;
