import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { DeleteConfirmProp } from "types/state";

// i18n
import { useTranslation } from "react-i18next";

interface Props extends DeleteConfirmProp {
    title: string;
    note?: string;
    handleDelete: () => void;
}

export const DeleteConfirmPop: React.FC<Props> = ({ title, note, handleDelete, setDeleteConfirm }) => {
    const { t: translate } = useTranslation(["common"]);
    return (
        <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
                position: "fixed",
                left: 0,
                top: 0,
                zIndex: 10,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}>
            <MDBox textAlign="center" bgColor="white" p={3} sx={{ borderRadius: "10px", width: "350px" }}>
                <MDTypography variant="h2" mb={2}>
                    {title}
                </MDTypography>
                {note && (
                    <MDTypography variant="body2" fontSize="14px">
                        *Notice: {note}
                    </MDTypography>
                )}
                <MDBox mt={2} display="flex" justifyContent="space-around">
                    <MDButton variant="gradient" circular={false} onClick={handleDelete}>
                        {translate("general.popup.yes")}
                    </MDButton>
                    <MDButton
                        color="warning"
                        variant="gradient"
                        circular={false}
                        onClick={() => setDeleteConfirm(false)}>
                        {translate("general.popup.no")}
                    </MDButton>
                </MDBox>
            </MDBox>
        </MDBox>
    );
};

export default DeleteConfirmPop;
