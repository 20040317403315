import { Center } from "types/setting-schema";
import { FormOperation } from "types/enums";
// firebase
import { storage } from "firebase-config";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 } from "uuid";
import { isNil } from "./utils";
import { Device } from "types/setting-schema";

export const NOT_SELECTED = "Not Selected";

export const getCamelCase = (str: string) => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
};

export const phoneFormat = (value: any, countryCode: string) => {
    const res = value.replace(/\D/g, "");

    if (countryCode === "+1") {
        const part1 = res.substring(0, 3);
        const part2 = res.substring(3, 6).length > 0 ? `-${res.substring(3, 6)}` : "";
        const part3 = res.substring(6, 10).length > 0 ? `-${res.substring(6, 10)}` : "";
        return `${part1}${part2}${part3}${res.substring(10)}`;
    } else if (countryCode === "+886") {
        const part1 = res.substring(0, 4);
        const part2 = res.substring(4, 7).length > 0 ? `-${res.substring(4, 7)}` : "";
        const part3 = res.substring(7, 10).length > 0 ? `-${res.substring(7, 10)}` : "";
        return `${part1}${part2}${part3}${res.substring(10)}`;
    }
};

export const isEditDisabled = (operation: string): boolean => {
    return operation === FormOperation.VIEW ? true : false;
};

// get name, label and type
export const getFormKeys = (obj: any, translateName: string) => {
    // translate name is for label
    return { name: obj.name, type: obj.type, label: translateName };
};

// Get Center
export const getCenterByName = (centerData: Center[], centerName: string): Center => {
    return centerData.find((item) => item.name === centerName);
};

// Push to form array
export const handlePush = (ary: any[], obj: Object) => {
    return ary.push(obj);
};

// Remove from form array
export const handleRemove = (ary: any, index: number) => {
    return ary.remove(index);
};

export const getCountryCode = (lang: string) => {
    if (lang === "tw") return "+886";
    return "+1";
};

export const getBirthday = (dob: string) => {
    const birthday = dob.substring(0, 10).split(/-/);
    return {
        year: Number(birthday[0]),
        month: Number(birthday[1]),
        day: Number(birthday[2]),
    };
};

export const uploadFile = async (path: string, img: any) => {
    if (isNil(img)) return;
    const imageRef = ref(storage, `${path}/${v4()}`);
    await uploadBytes(imageRef, img);
    return await getDownloadURL(imageRef);
};

export const deleteFile = async (url: string) => {
    if (isNil(url)) return;
    const imageRef = ref(storage, url);
    await deleteObject(imageRef);
    return;
};

export const fileRead = (val: any) => {
    if (isNil(val)) return null;
    // If type is string, return url
    if (typeof val === "string") {
        return val;
    }
    // If type is object, return URL.createObjectURL
    return URL.createObjectURL(val);
};

export const checkStatus = (devices: Device[]) => {
    let isInstalled = true;
    devices.forEach((item) => {
        if (item.status === "not installed") isInstalled = false;
    });
    return isInstalled;
};
