/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, ReactNode } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav } from "context";

// i18n
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import { iRoute } from "routes";
import { isNotNil } from "helpers/utils";
import { useAuth } from "context/userContext";
import { CircularProgress, Theme, useMediaQuery } from "@mui/material";

// Declaring props types for Sidenav
interface Props {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
    brand?: string;
    brandName?: string;
    routes: iRoute[];
    [key: string]: any;
}

// Overlay Component
const Overlay = ({ onClick }: { onClick: () => void }) => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

    return isSmallScreen ? (
        <MDBox
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 999,
            }}
            onClick={onClick}
        />
    ) : null;
};

export const Sidenav: React.FC<Props> = ({ color, brand, routes, ...rest }) => {
    const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
    const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string>(false);
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav } = controller;
    const location = useLocation();
    const { currentUser } = useAuth();
    const { pathname } = location;
    const collapseName = pathname.split("/").slice(1)[0];
    const items = pathname.split("/").slice(1);
    const itemParentName = items[1];
    const itemName = items[items.length - 1];
    const language = ["en", "tw"];
    const { t: translate } = useTranslation(["common"]);
    const [overlayVisible, setOverlayVisible] = useState(false);
    useEffect(() => {
        setOpenCollapse(collapseName);
        setOpenNestedCollapse(itemParentName);
    }, []);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
        }

        /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);

    useEffect(() => {
        // revert 'eng' or 'english' to 'en'
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18next.changeLanguage("en");
        }
    });

    const handleSidenavToggle = () => {
        setOverlayVisible(!overlayVisible);
        setMiniSidenav(dispatch, !miniSidenav);
    };

    // Render all the nested collapse items from the routes.js
    const renderNestedCollapse = (collapse: any) => {
        const template = collapse.map(({ name, route, key, href }: any) =>
            href ? (
                <Link key={key} href={href} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                    <SidenavItem name={name} nested />
                </Link>
            ) : (
                <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
                    <SidenavItem name={name} active={route === pathname} nested />
                </NavLink>
            ),
        );

        return template;
    };
    // Render the all the collpases from the routes.js
    const renderCollapse = (collapses: any) =>
        collapses.map(({ name, collapse, remote, route, href, key }: any) => {
            const localizedName = `sidemenu.${name.toLowerCase()}`;
            let returnValue;

            // Server side controlled menu
            if (remote && isNotNil(currentUser) && !currentUser.menus.includes(key)) {
                return returnValue;
            }

            if (collapse) {
                returnValue = (
                    <SidenavItem
                        key={key}
                        color={color}
                        name={name}
                        active={key === itemParentName ? "isParent" : false}
                        open={openNestedCollapse === key}
                        onClick={({ currentTarget }: any) =>
                            openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                                ? setOpenNestedCollapse(false)
                                : setOpenNestedCollapse(key)
                        }>
                        {renderNestedCollapse(collapse)}
                    </SidenavItem>
                );
            } else {
                returnValue = href ? (
                    <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                        <SidenavItem color={color} name={name} active={key === itemName} />
                    </Link>
                ) : (
                    <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
                        <SidenavItem color={color} name={translate(localizedName)} active={key === itemName} />
                    </NavLink>
                );
            }
            return <SidenavList key={key}>{returnValue}</SidenavList>;
        });

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(
        ({ type, name, icon, title, collapse, noCollapse, key, href, route, remote }: iRoute) => {
            let returnValue;

            // Server side controlled menu
            if (remote && isNotNil(currentUser) && !currentUser.menus.includes(key)) {
                return returnValue;
            }

            if (type === "collapse") {
                const localizedName = `sidemenu.${name.toLowerCase()}`;
                if (href) {
                    returnValue = (
                        <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                            <SidenavCollapse
                                name={translate(localizedName)}
                                icon={icon}
                                active={key === collapseName}
                                noCollapse={noCollapse}
                            />
                        </Link>
                    );
                } else if (noCollapse && route) {
                    returnValue = (
                        <NavLink to={route} key={key}>
                            <SidenavCollapse
                                name={translate(localizedName)}
                                icon={icon}
                                noCollapse={noCollapse}
                                active={key === collapseName}>
                                {collapse ? renderCollapse(collapse) : null}
                            </SidenavCollapse>
                        </NavLink>
                    );
                } else {
                    returnValue = (
                        <SidenavCollapse
                            key={key}
                            name={translate(localizedName)}
                            icon={icon}
                            active={key === collapseName}
                            open={openCollapse === key}
                            onClick={() => {
                                setOpenCollapse(openCollapse === key ? false : key);
                            }}>
                            {collapse ? renderCollapse(collapse) : null}
                        </SidenavCollapse>
                    );
                }
            } else if (type === "title") {
                const localizedName = `sidemenu.title.${title.toLowerCase()}`;
                returnValue = (
                    <MDTypography
                        key={key}
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        pl={3}
                        mt={3}
                        mb={1}
                        ml={1}>
                        {translate(localizedName)}
                    </MDTypography>
                );
            } else if (type === "divider") {
                returnValue = <Divider key={key} />;
            }

            return returnValue;
        },
    );

    const handleChange = (event: SelectChangeEvent) => {
        i18n.changeLanguage(event.target.value);
    };

    const renderLngSelect = language.map((lng, idx) => {
        const country = lng === "en" ? "CA" : "TW";
        return (
            <MenuItem key={idx} value={lng} sx={{ minWidth: 50 }}>
                <MDBox display="flex" alignItems="center">
                    <img src={require(`assets/images/icons/flags/${country}.png`)} alt={lng} />
                    <MDTypography variant="body2" ml={0.5}>
                        {lng}
                    </MDTypography>
                </MDBox>
            </MenuItem>
        );
    });

    //add loading spinner

    return (
        <>
            <SidenavRoot {...rest} variant="permanent" ownerState={{ miniSidenav }}>
                {!currentUser ? (
                    <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                    </MDBox>
                ) : (
                    <>
                        <MDBox pt={3} mb={5} px={4} textAlign="center" sx={{ paddingLeft: "6px" }}>
                            <MDBox component={NavLink} to="/" display="flex" flexDirection="column" alignItems="center">
                                {brand && (
                                    <MDBox
                                        component="img"
                                        src={brand}
                                        alt="Brand"
                                        width={"9rem"}
                                        sx={{
                                            transition: "0.2s linear",
                                        }}
                                    />
                                )}
                                <MDBox
                                    sx={{
                                        ml: "68px",
                                        transition: "0.2s linear",
                                    }}
                                    textAlign="start"
                                    opacity={miniSidenav ? 0 : 100}>
                                    <MDTypography fontSize="10px" fontWeight="regular" color="dark">
                                        Business version
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                        <List>{renderRoutes}</List>
                        <MDBox mx={4} my={2} sx={{ width: 80 }}>
                            <FormControl>
                                <Select
                                    sx={{ p: 0.5 }}
                                    value={localStorage.getItem("i18nextLng")}
                                    onChange={handleChange}>
                                    {renderLngSelect}
                                </Select>
                            </FormControl>
                        </MDBox>
                        <MDBox alignSelf="flex-end" sx={{ mx: 4 }}>
                            <MDTypography fontSize="10px" fontWeight="regular" color="dark">
                                version {process.env.REACT_APP_VERSION}
                            </MDTypography>
                        </MDBox>
                    </>
                )}
            </SidenavRoot>
            {!miniSidenav && <Overlay onClick={handleSidenavToggle} />}
        </>
    );
};

// Declaring default props for Sidenav
Sidenav.defaultProps = {
    color: "info",
    brand: "",
};

export default Sidenav;
