import MDButton from "components/MDButton";
import { handlePush } from "helpers/formUtil";

interface Props {
    content: string;
    ary: any[];
    obj: Object;
}

// Add Room, Add Emergency Contact
export const AddArrayButton: React.FC<Props> = ({ content, ary, obj }) => {
    return (
        <MDButton variant="outlined" type="button" onClick={() => handlePush(ary, obj)}>
            + {content}
        </MDButton>
    );
};

export default AddArrayButton;
