import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/userContext";

// @mui material components
import Grid from "@mui/material/Grid";

// Components
import MDBox from "components/MDBox";
import MDSkeleton from "components/MDSkeleton";
import MDErrorMsg from "components/MDErrorMsg";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { FormField, FormSelect } from "components/MDFormField";
import { PhoneField } from "components/MDFormField/phoneField";
import SubmitButtons from "components/MDButton/submitButton";
import EditButton from "components/MDButton/editButton";
import DeleteConfirmPop from "components/MDFormField/deleteConfirm";
import FormLayout from "examples/LayoutContainers/FormLayout";
import { UploadableImage } from "components/MDFormField/uploadableImage";

// formik components
import { Formik, Form } from "formik";

// New settings layout schemas for form and form fields
import form from "layouts/account/schemas/form";
import useValidationSchema from "layouts/account/schemas/validation";

// Types
import { FormOperation, FormType } from "types/enums";
import { Center } from "types/setting-schema";
import { User, isRelAdmin } from "types/user-schema";

// Service
import CenterService from "services/center.service";
import UserService from "services/user.service";

// Helpers
import { isNotNil, isNil } from "helpers/utils";
import {
    isEditDisabled,
    getFormKeys,
    getCountryCode,
    NOT_SELECTED,
    uploadFile,
    deleteFile,
    fileRead,
} from "helpers/formUtil";
import { validateEmail } from "helpers/validations";

// i18n
import { useTranslation } from "react-i18next";
import { ROLES_ARRAY, Roles } from "types/roles";

import defaultAvatar from "assets/images/defaultAvatar.svg";

interface Props {
    operation: FormOperation;
    businessId: string;
    account?: User;
    callback: (updated: boolean, isDelete?: boolean) => void;
}

export const AccountForm: React.FC<Props> = ({ operation: ops, businessId, account: initAccount, callback }) => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [operation, setOperation] = useState<FormOperation>(ops);
    const [error, setError] = useState<string | null>(null);
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const [centerData, setCenterData] = useState<Center[] | null>(null);
    const { formId, formField } = form;
    const { name, avatarUrl, role, center, phone, email } = formField.account;
    const { t: translate } = useTranslation(["common"]);
    const notSelectedOption = { option: translate(`general.state.notSelected`), value: NOT_SELECTED };
    const validation = useValidationSchema();
    const defaultAccount: User = {
        name: "",
        avatarUrl: null,
        center: {
            name: NOT_SELECTED,
        },
        role: NOT_SELECTED,
        phone: {
            countryCode: getCountryCode(translate("lang")),
            number: "",
        },
        email: "",
    };

    useEffect(() => {
        const fetchCenters = async () => {
            const res = await CenterService.getAllCenters(businessId);
            if (res.success && res.data.length > 0) {
                setCenterData(res.data);
            }
        };

        if (isNotNil(businessId) && !isEditDisabled(operation)) {
            fetchCenters();
        }
    }, [businessId, operation]);

    const handleResult = (res: any, isDelete?: boolean) => {
        if (res.success) {
            if (isNotNil(callback)) {
                callback(true, isDelete);
            } else {
                navigate("/dashboard");
            }
        } else {
            if (res.error === "Duplicate field value entered") {
                setError(translate(`error.duplicate.email`));
            }
            setError(translate(`error.${res.error}`));
        }
    };

    const submitForm = async (values: any, actions: any) => {
        setError(null);
        const { avatarUrl, center, ...updatedValue } = values;
        let info = updatedValue;

        if (avatarUrl !== defaultAvatar && avatarUrl !== initAccount?.avatarUrl) {
            const path = `${centerData[0].business.name}/${businessId}/${FormType.ACCOUNT}`;

            await deleteFile(initAccount?.avatarUrl);
            const uploadRes = await uploadFile(path, avatarUrl);
            if (isNotNil(uploadRes)) {
                info.avatarUrl = uploadRes;
            }
        }

        if (values.center.name !== NOT_SELECTED) {
            // Store center info
            const { _id } = centerData.find((center) => center.name === values.center.name);
            info.center = _id;
            info.business = businessId;
        }

        let res;
        if (operation === FormOperation.CREATE) {
            res = await handleCreateAccount(info);
        } else {
            res = await handleUpdateAccount(info);
        }

        handleResult(res);

        actions.setSubmitting(false);
    };

    const handleCreateAccount = async (user: User) => {
        return UserService.createUser(user, translate("lang"));
    };

    const handleUpdateAccount = async (user: User) => {
        return UserService.updateSingleUserBusiness(initAccount._id, user);
    };

    const handleDeleteCenter = async () => {
        await deleteFile(initAccount?.avatarUrl);
        const res = await UserService.deleteUser(initAccount._id);
        handleResult(res, true);
    };

    const availableRoles = (): string[] => {
        if (isRelAdmin(currentUser)) {
            return Object.values(Roles);
        } else {
            const values = Object.values(Roles);
            return values.filter((role) => role !== Roles.relAdmin);
        }
    };

    const defaultAvatarComponent = (name?: string) => {
        if (isNotNil(name) && name.length > 0) {
            return (
                <MDTypography fontSize="40px" color="white">
                    {name[0]}
                </MDTypography>
            );
        } else {
            return <MDAvatar src={defaultAvatar} alt="Default Svg" size="xl" shadow="sm" />;
        }
    };

    if (!isEditDisabled(operation) && isNil(centerData)) return <MDSkeleton />;

    return (
        <>
            {deleteConfirm && (
                <DeleteConfirmPop
                    title={`${translate("general.popup.delete")}`}
                    handleDelete={handleDeleteCenter}
                    setDeleteConfirm={setDeleteConfirm}
                />
            )}
            <Grid container height="100%">
                <Grid item xs={12}>
                    <Formik
                        initialValues={{ ...defaultAccount, ...initAccount }}
                        validationSchema={validation.account[0]}
                        onSubmit={submitForm}>
                        {({ values, errors, touched, dirty, setFieldValue, handleChange, isSubmitting }) => (
                            <Form id={formId} autoComplete="off">
                                <FormLayout
                                    header={`${translate(`general.operation.${operation}`)}${
                                        operation === FormOperation.CREATE ? translate(`general.state.new`) : ""
                                    }${translate(`general.type.${FormType.ACCOUNT}`)}`}
                                    name={operation !== FormOperation.CREATE ? initAccount?.name : ""}>
                                    {operation === FormOperation.VIEW && <EditButton setOperation={setOperation} />}
                                    <MDBox mt={1.625}>
                                        <Grid container spacing={3} mb={3} alignItems="center">
                                            <Grid item xs={6}>
                                                <FormField
                                                    {...getFormKeys(name, translate(`account.form.name`))}
                                                    value={values.name}
                                                    error={errors.name && touched.name}
                                                    success={values.name.length > 0 && !errors.name}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <UploadableImage
                                                    imageUrl={fileRead(values.avatarUrl)}
                                                    defaultElement={defaultAvatarComponent(values.name)}
                                                    {...getFormKeys(avatarUrl, "")}
                                                    onChange={(e: any) => {
                                                        setFieldValue("avatarUrl", e.target.files[0]);
                                                    }}
                                                    bgColor={values.name.length === 0 ? "white" : "text"}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} mb={3}>
                                            <Grid item xs={12} sm={6}>
                                                <FormSelect
                                                    options={[
                                                        notSelectedOption,
                                                        ...availableRoles().map((role: string) => ({
                                                            option: translate(`account.role.${role}`),
                                                            value: role,
                                                        })),
                                                    ]}
                                                    {...getFormKeys(role, translate(`account.form.role`))}
                                                    error={values.role === NOT_SELECTED && touched.role}
                                                    value={values.role}
                                                    required={true}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormSelect
                                                    options={
                                                        centerData
                                                            ? [
                                                                  notSelectedOption,
                                                                  ...centerData?.map((center) => ({
                                                                      option: center.name,
                                                                      value: center.name,
                                                                  })),
                                                              ]
                                                            : []
                                                    }
                                                    {...getFormKeys(center.name, translate(`account.form.center`))}
                                                    error={values.center.name === NOT_SELECTED && touched.center}
                                                    value={values.center.name}
                                                    required={true}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} mb={3}>
                                            <PhoneField
                                                {...{
                                                    phoneValue: values.phone,
                                                    errors,
                                                    touched,
                                                    field: phone,
                                                    handleChange,
                                                    label: translate(`account.form.phone`),
                                                    isEditDisabled: isEditDisabled(operation),
                                                }}
                                            />
                                            <Grid item xs={12} sm={6}>
                                                <FormField
                                                    {...getFormKeys(email, translate(`account.form.email`))}
                                                    value={values.email}
                                                    error={!validateEmail(values.email) && touched.email}
                                                    success={values.email.length > 0 && validateEmail(values.email)}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                    {isNotNil(error) && <MDErrorMsg errorMsg={error} />}
                                    <SubmitButtons
                                        disable={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                                        operation={operation}
                                        setDeleteConfirm={setDeleteConfirm}
                                    />
                                </FormLayout>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    );
};
