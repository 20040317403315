import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

export const More: React.FC = () => {
    return (
        <Grid item xs={0.5} alignSelf="center" display="flex" alignItems="center" justifyContent="end">
            <Icon fontSize="medium">more_vert</Icon>
        </Grid>
    );
};

export default More;
