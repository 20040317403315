// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { errorStyle } from "layouts/authentications/styles/auth.styles";
import { globalStyle } from "styles/global.styles";
import { Icon } from "@mui/material";

export const MDErrorMsg: React.FC<{ errorMsg: string }> = ({ errorMsg }) => {
    return (
        <MDBox
            my={1}
            sx={[errorStyle.container, globalStyle.flex.vertical, { backgroundColor: "rgba(244, 67, 53, 0.3)" }]}>
            <Icon color="error" sx={{ mb: 0.5, mr: 1 }}>
                warning_amber
            </Icon>
            <MDTypography variant="subtitle2" color="error">
                {errorMsg}
            </MDTypography>
        </MDBox>
    );
};

export default MDErrorMsg;
