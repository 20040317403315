// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCvc6d7LbchDpOHeTo-WVbl58XRTR2dIwE",
    authDomain: "rel-dev-9037c.firebaseapp.com",
    projectId: "rel-dev-9037c",
    storageBucket: "rel-dev-9037c.appspot.com",
    messagingSenderId: "307450885102",
    appId: "1:307450885102:web:02e18efcf2cee4f21114ff",
    measurementId: "G-XCJHJEZBQJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);
