import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/userContext";

// @mui material components
import Grid from "@mui/material/Grid";

// Components
import MDBox from "components/MDBox";
import MDErrorMsg from "components/MDErrorMsg";
import { FormField } from "components/MDFormField";
import { PhoneField } from "components/MDFormField/phoneField";
import SubmitButtons from "components/MDButton/submitButton";
import EditButton from "components/MDButton/editButton";
import DeleteConfirmPop from "components/MDFormField/deleteConfirm";
import FormLayout from "examples/LayoutContainers/FormLayout";

// formik components
import { Formik, Form } from "formik";

// New settings layout schemas for form and form fields
import form from "layouts/settings/schemas/form";
import useValidationSchema from "layouts/settings/schemas/validations/business";

// Types
import { FormOperation, FormType } from "types/enums";
import { Business } from "types/setting-schema";

// Service
import BusinessService from "services/business.service";

// Helpers
import { isNotNil } from "helpers/utils";
import { isEditDisabled, getFormKeys, getCountryCode } from "helpers/formUtil";

import { isRelAdmin } from "types/user-schema";

// i18n
import { useTranslation } from "react-i18next";

interface Props {
    operation: FormOperation;
    business?: Business;
    callback: (updated: boolean, type?: FormType) => void;
}

export const BusinessForm: React.FC<Props> = ({ operation: ops, business: initBusiness, callback }) => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [operation, setOperation] = useState<FormOperation>(ops);
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { formId, formField } = form;
    const { name, address, contact, phone, description, relManagerEmail, relAssistantEmail, dashboardUrl } =
        formField.business;
    const { t: translate } = useTranslation(["common"]);
    const validations = useValidationSchema();
    const defaultBusiness: Business = {
        name: "",
        description: "",
        phone: {
            countryCode: getCountryCode(translate("lang")),
            number: "",
        },
        contact: "",
        address: "",
        relManagerEmail: "",
        relAssistantEmail: "",
        dashboardUrl: "",
    };

    const handleResult = (res: any) => {
        if (res.success) {
            if (isNotNil(callback)) {
                if (operation === FormOperation.CREATE) {
                    callback(true, FormType.BUSINESS);
                } else {
                    callback(true);
                }
            } else {
                navigate("/dashboard");
            }
        } else {
            setError(res.error);
        }
    };

    const submitForm = async (values: any, actions: any) => {
        setError(null);
        let res;
        if (operation === FormOperation.CREATE) {
            res = await handleCreateBusiness(values);
        } else {
            res = await handleUpdateBusiness(values);
        }

        handleResult(res);

        actions.setSubmitting(false);
    };

    const handleCreateBusiness = async (business: Business) => {
        return BusinessService.createBusiness(business);
    };

    const handleUpdateBusiness = async (business: Business) => {
        return BusinessService.updateSingleBusiness(business._id, business);
    };

    const handleDeleteBusiness = async () => {
        const res = await BusinessService.deleteSingleBusiness(initBusiness._id);
        handleResult(res);
    };

    return (
        <>
            {deleteConfirm && (
                <DeleteConfirmPop
                    title={`${translate("general.popup.delete")}`}
                    handleDelete={handleDeleteBusiness}
                    setDeleteConfirm={setDeleteConfirm}
                />
            )}
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{ ...defaultBusiness, ...initBusiness }}
                        validationSchema={validations.business[0]}
                        onSubmit={submitForm}>
                        {({ values, errors, touched, dirty, handleChange, isSubmitting }) => (
                            <Form id={formId} autoComplete="off" noValidate>
                                <FormLayout
                                    header={`${translate(`general.operation.${operation}`)}${
                                        operation === FormOperation.CREATE ? translate(`general.state.new`) : ""
                                    }${translate(`general.type.${FormType.BUSINESS}`)}`}
                                    name={operation !== FormOperation.CREATE ? initBusiness?.name : ""}>
                                    {operation === FormOperation.VIEW && <EditButton setOperation={setOperation} />}
                                    <MDBox mt={1.625}>
                                        <Grid container spacing={3} mb={3}>
                                            <Grid item xs={12} sm={6}>
                                                <FormField
                                                    {...getFormKeys(name, translate(`setting.business.name`))}
                                                    value={values.name}
                                                    error={errors.name && touched.name}
                                                    success={values.name.length > 0 && !errors.name}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} mb={3}>
                                            <Grid item xs={12}>
                                                <FormField
                                                    {...getFormKeys(address, translate(`setting.business.address`))}
                                                    value={values.address}
                                                    error={errors.address && touched.address}
                                                    success={values.address.length > 0 && !errors.address}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} mb={3}>
                                            <Grid item xs={12} sm={6}>
                                                <FormField
                                                    {...getFormKeys(contact, translate(`setting.business.contact`))}
                                                    value={values.contact}
                                                    error={errors.contact && touched.contact}
                                                    success={values.contact.length > 0 && !errors.contact}
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                            <PhoneField
                                                {...{
                                                    phoneValue: values.phone,
                                                    errors,
                                                    touched,
                                                    field: phone,
                                                    handleChange,
                                                    label: translate(`setting.business.phone`),
                                                    isEditDisabled: isEditDisabled(operation),
                                                }}
                                            />
                                        </Grid>
                                        {isRelAdmin(currentUser) && (
                                            <>
                                                <Grid container spacing={3} mb={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormField
                                                            {...getFormKeys(
                                                                relManagerEmail,
                                                                translate(`setting.business.relManagerEmail`),
                                                            )}
                                                            value={values.relManagerEmail}
                                                            error={errors.relManagerEmail && touched.relManagerEmail}
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormField
                                                            {...getFormKeys(
                                                                relAssistantEmail,
                                                                translate(`setting.business.relAssistantEmail`),
                                                            )}
                                                            value={values.relAssistantEmail}
                                                            error={
                                                                errors.relAssistantEmail && touched.relAssistantEmail
                                                            }
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} mb={3}>
                                                    <Grid item xs={12}>
                                                        <FormField
                                                            {...getFormKeys(
                                                                dashboardUrl,
                                                                translate(`setting.business.dashboardUrl`),
                                                            )}
                                                            value={values.dashboardUrl}
                                                            isDisplayMode={isEditDisabled(operation)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid container spacing={3} mb={3}>
                                            <Grid item xs={12}>
                                                <FormField
                                                    {...getFormKeys(
                                                        description,
                                                        translate(`setting.business.description`),
                                                    )}
                                                    value={
                                                        isEditDisabled(operation) && values.description.length === 0
                                                            ? "None"
                                                            : values.description
                                                    }
                                                    isDisplayMode={isEditDisabled(operation)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                    {isNotNil(error) && <MDErrorMsg errorMsg={error} />}
                                    <SubmitButtons
                                        disable={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                                        operation={isRelAdmin(currentUser) ? operation : FormOperation.CREATE}
                                        setDeleteConfirm={setDeleteConfirm}
                                    />
                                </FormLayout>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    );
};
