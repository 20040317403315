// Setting layout components
import MDModal from "components/MDModal";
import Jotform from "layouts/info/components/Form/JotfromEmbed";

// Type
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    formId: string;
    url: string;
    residentInfo: {
        id: string;
        gender: string;
        name: string;
        dateOfBirth: string;
        preferredLanguage: string;
    };
    callback: (updated: boolean) => void;
}

export const JotformEmbed: React.FC<Props> = ({ setToggleModal, formId, url, residentInfo, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <Jotform formId={formId} url={url} residentInfo={residentInfo} callback={callback} />
        </MDModal>
    );
};

export default JotformEmbed;
