// Setting layout components
import MDModal from "components/MDModal";
import { AccountForm } from "layouts/account/components/Form";

// Type
import { FormOperation } from "types/enums";
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    businessId: string;
    callback: (updated: boolean) => void;
}

export const NewAccount: React.FC<Props> = ({ setToggleModal, businessId, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <AccountForm operation={FormOperation.CREATE} businessId={businessId} callback={callback} />
        </MDModal>
    );
};

export default NewAccount;
