import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    sevenDaysData: number[];
    title: string;
    circle?: number;
    tip?: any;
}
interface StyledBoxProps {
    type: string;
}
interface MDDailyScoreBoxProps {
    value: number;
}
interface StyledCircleProps {
    level: string;
    title: string;
}

interface StyledTooltipProps {
    title: string;
    data: any;
    level: string;
}
const StyledBox = (props: StyledBoxProps) => {
    const { type } = props;
    return (
        <MDBox
            sx={{
                width: "15px",
                height: "10px",
                border: "1px solid #1f1d1d23",
                zoom: "0.7",
                background:
                    type === "normal"
                        ? "#1ac340"
                        : type === "mild"
                        ? "#f6de00"
                        : type === "moderate"
                        ? "#f68e14"
                        : type === "severe"
                        ? "#da0a0a"
                        : "grey",
            }}
        />
    );
};

const StyledCircle = (props: StyledCircleProps) => {
    const { level, title } = props;
    return (
        <MDBox
            sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                marginTop: "5px",
                border: "1px solid #1f1d1d23",
                zoom: "0.7",
                background:
                    level === "normal"
                        ? "#1ac340"
                        : level === "mild"
                        ? "#f6de00"
                        : level === "moderate"
                        ? "#f68e14"
                        : level === "severe"
                        ? "#da0a0a"
                        : "grey",
            }}
        />
    );
};
const MDDailyScoreBox = (props: MDDailyScoreBoxProps) => {
    const { value } = props;
    switch (true) {
        case value === 0:
            return <StyledBox type="empty" />;
        case value === 1:
            return <StyledBox type="normal" />;
        case value === 2:
            return <StyledBox type="mild" />;
        case value === 3:
            return <StyledBox type="moderate" />;
        case value === 4:
            return <StyledBox type="severe" />;
    }
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFFFF",
        color: "#5D5A88",
        maxWidth: 150,
        minWidth: 150,
        // fontSize: theme.typography.pxToRem(12),
        border: "1px solid #9B9BFF",
        fontSize: "12px",
    },
}));
const MDTooltip = (Props: StyledTooltipProps) => {
    const { title, data, level } = Props;
    const { t: translate } = useTranslation(["common"]);
    return (
        <MDBox sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <MDBox sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                <MDBox>{translate(`report.${title}`)}</MDBox>
                <MDBox>
                    <MDBox
                        sx={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            border: "1px solid #1f1d1d23",
                            zoom: "0.7",
                            background:
                                level === "normal"
                                    ? "#1ac340"
                                    : level === "mild"
                                    ? "#f6de00"
                                    : level === "moderate"
                                    ? "#f68e14"
                                    : level === "severe"
                                    ? "#da0a0a"
                                    : "grey",
                        }}
                    />
                </MDBox>
            </MDBox>
            <hr style={{ margin: "5px 0", border: "1px solid #CCCCCC" }} />
            <MDBox sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                {Object.entries(data).map(([key, value], i) => (
                    <MDBox
                        key={`${key}_${i}`}
                        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <MDBox>{`${translate(`report.tooltip.${key}`)}`}</MDBox>
                        <MDBox
                            sx={{
                                color:
                                    level === "normal"
                                        ? "#1ac340"
                                        : level === "mild"
                                        ? "#f6de00"
                                        : level === "moderate"
                                        ? "#f68e14"
                                        : level === "severe"
                                        ? "#da0a0a"
                                        : "grey",
                            }}>{`${value}`}</MDBox>
                    </MDBox>
                ))}
            </MDBox>
        </MDBox>
    );
};
const MDDailyScore: React.FC<Props> = (props: Props) => {
    const { sevenDaysData, title, circle, tip } = props;
    let level = "normal";
    let mild = 0;
    let moderate = 0;
    let severe = 0;
    let equalOne = sevenDaysData.every((v) => v === 1);

    sevenDaysData.map((v) => {
        if (v === 2) {
            mild++;
        } else if (v === 3) {
            moderate++;
        } else if (v === 4) {
            severe++;
        }
    });
    if (mild >= 1) {
        level = "mild";
    }
    if (moderate >= 1 || mild >= 2) {
        level = "moderate";
    }
    if (severe >= 1 || moderate >= 2) {
        level = "severe";
    }
    if (mild === 0 && moderate === 0 && severe === 0) {
        level = "empty";
    }
    if (equalOne) level = "normal";
    return (
        <HtmlTooltip
            describeChild={!!circle}
            placement="top"
            title={tip ? <MDTooltip title={title} data={tip} level={level} /> : ""}>
            <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <MDBox sx={{ display: "flex" }}>
                    {sevenDaysData.map((value, index) => {
                        return <MDDailyScoreBox value={value} key={index} />;
                    })}
                </MDBox>
                <StyledCircle level={level} title={title} />
            </MDBox>
        </HtmlTooltip>
    );
};

export default MDDailyScore;
