import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { dialogHeaderStyle } from "layouts/authentications/styles/auth.styles";

export interface DialogHeaderProps {
    title: string;
}

const DialogHeader: React.FC<DialogHeaderProps> = ({ title }) => {
    return (
        <MDBox textAlign="center" mb={3}>
            <MDTypography variant="h1" sx={dialogHeaderStyle.title}>
                {title}
            </MDTypography>
        </MDBox>
    );
};

export default DialogHeader;
