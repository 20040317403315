import { Role, Roles } from "./roles";
import { Phone } from "./setting-schema";

interface Center {
    readonly _id?: string;
    name: string;
    description?: string;
}

export interface User {
    readonly _id?: string;
    name: string;
    avatarUrl?: string;
    email: string;
    role: string;
    phone?: Phone;
    business?: string;
    center?: Center;
    createdAt?: string;
    __v?: number;
    resetPasswordExpire?: string;
    resetPasswordToken?: string;
    centerId?: string;
}

export interface UserActivity extends User {
    ipAddress: string;
    lastLogin: string;
    lastActivity: string;
}


export function isRelAdmin(user: User): boolean {
    return user.role === Roles.relAdmin;
}
