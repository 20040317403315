// Setting layout components
import MDModal from "components/MDModal";
import { BusinessForm } from "layouts/settings/components/Forms/BusinessForm";

// Type
import { FormOperation } from "types/enums";
import { ToggleProp } from "types/state";
import { Business } from "types/setting-schema";

interface Props extends ToggleProp {
    business: Business;
    callback: (updated: boolean) => void;
}

export const ViewBusiness: React.FC<Props> = ({ setToggleModal, business, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <BusinessForm operation={FormOperation.VIEW} callback={callback} business={business} />
        </MDModal>
    );
};

export default ViewBusiness;
