import { ReactNode } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Declaring props types for BasicLayout
interface Props {
    children: ReactNode;
}

export const BasicLayout: React.FC<Props> = ({ children }) => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={7}>{children}</MDBox>
            <Footer />
        </DashboardLayout>
    );
};

export default BasicLayout;
