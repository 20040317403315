import * as Yup from "yup";
import checkout from "layouts/settings/schemas/form";
import { validatePhones } from "helpers/validations";
import { NOT_SELECTED } from "helpers/formUtil";
import { useTranslation } from "react-i18next";

const {
    formField: {
        resident: { name, dateOfBirth, address, phone, emergency, floor, unit },
    },
} = checkout;

const useValidationSchema = () => {
    const { t } = useTranslation(["common"]);

    return {
        resident: [
            Yup.object().shape({
                name: Yup.string().required(t(`error.${name.errorMsg}`)),
                dateOfBirth: Yup.date().required(t(`error.${dateOfBirth.errorMsg}`)).max(new Date(), t(`error.${dateOfBirth.invalidMsg}`)),
                address: Yup.string().required(t(`error.${address.errorMsg}`)),
                phone: Yup.object().shape({
                    number: Yup.string()
                        .required(t(`error.${phone.number.errorMsg}`))
                        .test("phone-validation", t(`error.${phone.number.invalidMsg}`), function (value) {
                            return validatePhones(value);
                        }),
                    countryCode: Yup.string().oneOf(["+1", "+886"], ""),
                }),
                emergency: Yup.array().of(
                    Yup.object().shape({
                        name: Yup.string().required(t(`error.${emergency.name.errorMsg}`)),
                        phone: Yup.object().shape({
                            number: Yup.string()
                                .required(t(`error.${emergency.phone.number.errorMsg}`))
                                .test("phone-validation", t(`error.${emergency.phone.number.invalidMsg}`), function (value) {
                                    return validatePhones(value);
                                }),
                            countryCode: Yup.string().oneOf(["+1", "+886"], ""),
                        }),
                        relation: Yup.string(),
                        notification: Yup.boolean(),
                    }),
                ),
                floor: Yup.string().when(["center", "unit"], {
                    is: (centerName:any, unit:string) => centerName.name !== NOT_SELECTED && unit === NOT_SELECTED,
                    then: Yup.string()
                        .required(t(`error.${floor.errorMsg}`))
                        .test("floor-check", t(`error.${floor.errorMsg}`), function (value) {
                            return value !== NOT_SELECTED;
                        }),
                }),
                unit: Yup.string().when("center", {
                    is: (centerName:any) => centerName.name !== NOT_SELECTED,
                    then: Yup.string()
                        .required(t(`error.${unit.errorMsg}`))
                        .test("unit-check", t(`error.${unit.errorMsg}`), function (value) {
                            return value !== NOT_SELECTED;
                        }),
                }),
            }),
        ],
    };
};

export default useValidationSchema;
