import * as Yup from "yup";

const validations = {
    measurement: [
        Yup.object().shape({
            systolicBloodPressure: Yup.number().min(0),
            diastolicBloodPressure: Yup.number().min(0),
            pulse: Yup.number().min(0),
            bloodOxygen: Yup.number().min(0),
            bloodSugar: Yup.object().shape({
                level: Yup.number().min(0),
            }),
            height: Yup.number().min(0),
            weight: Yup.number().min(0),
            bmi: Yup.number().min(0),
            fat: Yup.number().min(0),
            waistline: Yup.number().min(0),
            temperature: Yup.number().min(0),
        }),
    ],
};

export default validations;
