// Setting layout components
import MDBox from "components/MDBox";
import MDModal from "components/MDModal";
import { AccountForm } from "layouts/account/components/Form";

// Type
import { FormOperation } from "types/enums";
import { ResidentNote } from "types/setting-schema";
import { ToggleProp } from "types/state";
import { renderNoteComment } from "..";
import { Grid, Paper } from "@mui/material";
import MDTypography from "components/MDTypography";
import { convertTimestampToReadableDate } from "helpers/utils";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MDTab from "components/MDTab";
import { useTranslation } from "react-i18next";
interface Props extends ToggleProp {
    report: any;
}

function converTIme(utx: number) {
    const date = new Date(utx * 1000); // Convert to milliseconds by multiplying by 1000
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
}
function calculateDuration(st: number, et: number) {
    const durationInSeconds = et - st;

    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds - hours * 3600) / 60);
    const seconds = durationInSeconds - hours * 3600 - minutes * 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const TodayReportList: React.FC<Props> = ({ setToggleModal, report }) => {
    const { t: translate } = useTranslation(["common"]);

    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <MDBox sx={{ background: "white", height: "70vh", overflowY: "auto", margin: "16px", borderRadius: "6px" }}>
                <Grid container spacing={2} sx={{ padding: "28px" }}>
                    <Grid item xs={12}>
                        <MDBox sx={{ paddingTop: "20px" }}>
                            <MDTypography variant="h2"> {translate("report.todayReport")}</MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>

                <Grid container spacing={2} rowGap={1} sx={{ padding: "28px" }}>
                    <Grid item xs={12}>
                        {report.length > 0 ? (
                            <TableContainer sx={{ backgroundColor: "white !important", borderRadius: 3 }}>
                                <Table sx={{ minWidth: 900 }} aria-label="simple table">
                                    <MDBox component="thead">
                                        <TableCell align={"left"}>
                                            <MDTypography variant="subtitle2">
                                                {translate("report.eventDate")}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell align={"left"}>
                                            <MDTypography variant="subtitle2">
                                                {translate("report.eventStatus")}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell align={"left"}>
                                            <MDTypography variant="subtitle2">
                                                {translate("report.startTime")}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell align={"left"}>
                                            <MDTypography variant="subtitle2">
                                                {translate("report.endTime")}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell align={"left"}>
                                            <MDTypography variant="subtitle2">
                                                {translate("report.duration")}
                                            </MDTypography>
                                        </TableCell>
                                    </MDBox>

                                    <TableBody>
                                        {report?.map((item: any) => (
                                            <TableRow
                                                key={item.id}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {convertTimestampToReadableDate(item?.createdAt)}
                                                </TableCell>
                                                <TableCell>{`${translate(`report.alert.${item?.attr}`)} (${translate(
                                                    `report.${item?.room_type}`,
                                                )})`}</TableCell>
                                                <TableCell>{converTIme(item.st)}</TableCell>
                                                <TableCell>{converTIme(item.et)}</TableCell>
                                                <TableCell>{calculateDuration(item.st, item.et)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <>{translate("dashboard.noData")}</>
                        )}
                    </Grid>
                </Grid>
            </MDBox>
        </MDModal>
    );
};

export default TodayReportList;
