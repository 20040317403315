import React from "react";
import { User } from "types/user-schema";
import Icon from "@mui/material/Icon";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDNoData from "components/MDNoData";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { isNotNil } from "helpers/utils";
import { useTranslation } from "react-i18next";
import ResidentService from "services/resident.service";
interface Cells {
    columns: { Header: string; accessor: string; disable?: boolean }[];
    rows: {
        id: string;
        eventDate: string;
        bedNo: string;
        name: string;
        eventRecord: string;
        startTime: string;
        endTime: string;
        duration: string;
    }[];
}

interface Props {
    list: any[];
    room: any[];
    count: number;
    page: number;
    setPage: (page: number) => void;
}
const convertTimestampToTime = (timestamp: number) => {
    const timezoneOffset = -6; // UTC+8
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const hours = (date.getUTCHours() + timezoneOffset) % 24;
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
};

const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getUTCDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
};

const Table: React.FC<Props> = ({ list, room, count, page, setPage }) => {
    const { t: translate } = useTranslation(["common"]);

    if (list?.length === 0) {
        return (
            <MDNoData
                content={translate("general.notification.noData", {
                    type: `${translate("general.type.report")}`,
                })}
            />
        );
    }
    const cells: Cells = {
        columns: translate("report.table", { returnObjects: true }),
        rows: [] as any[],
    };
    const getResidentBedInfo = (id: string) => {
        const resident = room.find((r) => r.wistron_id === id);
        return resident?.unitRecords?.[0]?.unit.floor + "F" + "-" + resident?.unitRecords?.[0]?.unit.unitNumber;
    };
    const getResidentName = (id: string) => {
        const resident = room.find((r) => r.wistron_id === id);
        return resident?.name;
    };
    cells.rows = list?.map((user) => {
        let _value = user.attr == "2" ? parseInt(user.value) / 60 : user.value;
        return {
            id: user.bedInfoId,
            eventDate: formatDate(user.createdAt),
            bedNo: getResidentBedInfo(user.bedInfoId),
            name: getResidentName(user.bedInfoId),
            eventRecord: `${translate(`report.ander.attr.${user.attr}`)}${translate(
                `report.ander.type.${user.attr}.${user.type}`,
                { value: _value },
            )}`,
            startTime: convertTimestampToTime(user.st),
            endTime: convertTimestampToTime(user.et),
            duration: convertTimestampToTime(user.dt),
        };
    });

    return <DataTable table={cells} count={count} CustomPage={page} handlePageChange={setPage} />;
};

export const ReportTable: React.FC<Props> = (props) => {
    return (
        <MDBox>
            <Table {...props} />
        </MDBox>
    );
};

export default ReportTable;
