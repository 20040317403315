import { Response, default as http } from "http-common";
import { HealthData } from "types/measurement-schema";
import { isNotNil } from "helpers/utils";

class BiometricsService {
    async getAllBiometricsByCenter(
        centerId: string,
        startDate?: string,
        endDate?: string,
    ): Promise<Response<HealthData[]>> {
        const response = await http.get<Response<HealthData[]>>(
            `/api/v1/centers/${centerId}/biometrics?inputDate[gte]=${startDate}&inputDate[lte]=${endDate}`,
        );
        return response.data;
    }

    async getAllBiometricsByCenterWithField(
        centerId: string,
        startDate: string,
        endDate: string,
        select: string,
    ): Promise<Response<HealthData[]>> {
        const response = await http.get<Response<HealthData[]>>(
            `/api/v1/centers/${centerId}/biometrics?inputDate[gte]=${startDate}&inputDate[lte]=${endDate}&select=${select}`,
        );
        return response.data;
    }

    async getAllBiometricsByResident(
        residentId: string,
        startDate?: string,
        endDate?: string,
    ): Promise<Response<HealthData[]>> {
        const currentDate = `${new Date().toJSON().slice(0, 10)}T23:59:59`;
        // If no start date, don't pass param inputDate[gt]
        // end date default is today
        const response = await http.get<Response<HealthData[]>>(
            `/api/v1/residents/${residentId}/biometrics?${
                isNotNil(startDate) ? `inputDate[gte]=${startDate}&` : ""
            }inputDate[lte]=${isNotNil(endDate) ? endDate : currentDate}&sort=-inputDate`,
        );
        return response.data;
    }

    async getAllBiometricsByBusiness(
        businessId: string,
        startDate?: string,
        endDate?: string,
    ): Promise<Response<HealthData[]>> {
        const response = await http.get<Response<HealthData[]>>(
            `/api/v1/businesses/${businessId}/biometrics?inputDate[gte]=${startDate}&inputDate[lte]=${endDate}`,
        );
        return response.data;
    }

    async createBiometrics(residentId: string, biometricsInfo: HealthData): Promise<Response<HealthData>> {
        const response = await http.post<Response<HealthData>>(
            `/api/v1/residents/${residentId}/biometrics`,
            biometricsInfo,
        );
        return response.data;
    }

    async updateBiometrics(biometricsId: string, biometricsInfo: HealthData): Promise<Response<HealthData>> {
        const response = await http.put<Response<HealthData>>(`/api/v1/biometrics/${biometricsId}`, biometricsInfo);
        return response.data;
    }

    async deleteBiometrics(biometricsId: string): Promise<Response<any>> {
        const response = await http.delete<Response<any>>(`/api/v1/biometrics/${biometricsId}`);
        return response.data;
    }
}

export default new BiometricsService();
