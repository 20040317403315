// Setting layout components
import MDModal from "components/MDModal";
import { ResidentForm } from "layouts/settings/components/Forms/ResidentForm";
import { Resident } from "types/setting-schema";

// Type
import { FormOperation } from "types/enums";
import { ToggleProp } from "types/state";

interface Props extends ToggleProp {
    businessId: string;
    resident: Resident;
    callback: (updated: boolean, isDelete?: boolean) => void;
}

export const ViewResident: React.FC<Props> = ({ setToggleModal, businessId, resident, callback }) => {
    return (
        <MDModal type="form" setToggleModal={setToggleModal}>
            <ResidentForm
                operation={FormOperation.VIEW}
                businessId={businessId}
                resident={resident}
                callback={callback}
            />
        </MDModal>
    );
};

export default ViewResident;
